import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageComponent } from 'src/app/message/message.component';
import { CommonService } from 'src/app/service';
import { PaymentService } from 'src/app/service/payment.service';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss']
})
export class PaymentDetailComponent implements OnInit {
  editAmount: boolean = false;
  selectedValue: number;
  allStudentsList: any[];
  getFeeDetails: any[] = [];
  grandtotal: number = 0;
  index: any;
  steps: number[] = []
  finalValue: number;
  stepIndex: any = 0;
  defaultValue: any = 0;
  paymentTypeID: any;
  dialogOpen: any;
  PaymentRedirectURL: string | URL;
  PaymentRefNo: any;
  currency: string;
  ipAddress: string;
  langTrans: any;
  constructor(private router: Router, private paymentService: PaymentService, private dialog: MatDialog, private common: CommonService, private translate: TranslateService) { }

  ngOnInit(): void {
    let data: any[] = [];
    data = JSON.parse(localStorage.getItem('allChildren'));
    this.common.getIPAddress().subscribe(
      (res: any) => {
        localStorage.setItem('IPAddress', res?.ip)
      }
    )
    this.currency = localStorage.getItem('bsu_currency')
    this.allStudentsList = data;
    this.getStudentFee();
    this.getFeeType()

    this.translate.stream('payments').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data.messages;
      }
    });
  }
  formatLabel(value: number | null) {
    this.selectedValue = value
    if (!value) {
      return 0;
    }

    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }
  editFeeAmount(data) {
    // this.editAmount = !this.editAmount;
    //data.feedetails.feeDetail[0].advanceDetails[2].feeAmount= 2000;

    let defaultrow = data.feedetails.feeDetail[0].advanceDetails.filter(a => a.Source == "DEFAULT");
    this.allStudentsList.forEach(ele => {

      if (ele.studentId == data.studentId) {
        ele.isAmountChecked = !data.isAmountChecked;
        defaultrow = data.feedetails.feeDetail[0].advanceDetails.filter(a => a.Source == "DEFAULT");

      } else {
        ele.isAmountChecked = ele.isAmountChecked;

      }
    })

    this.steps = data.feedetails.feeDetail[0].advanceDetails.map(x => x.feeAmount);
    let sum = 0;
    let sumdata: any;

    if (defaultrow.length > 0) {
      this.defaultValue = defaultrow[1].id;
      for (let i = 0; i <= this.defaultValue; i++) {
        sum += this.steps[i];
      }
      this.defaultValue = defaultrow[1].id + 1;
      this.finalValue = sum;
    }
    else {
      let currentrow=data.feedetails.feeDetail[0].advanceDetails.filter(a => a.id == 0);
      this.defaultValue=currentrow.id;
      for (let i = 0; i <= this.defaultValue; i++) {
        sum += this.steps[i];
      }
      this.defaultValue=currentrow.id+1;
      this.finalValue = sum;
      // if (data.stepnumber == 0) {
      //   this.steps.forEach(num => {
      //     sum += num;
      //   });
      //   this.finalValue = sum;
      //   this.defaultValue = this.steps.length;
      // } else {
      //   this.steps.forEach((num, index) => {
      //     if (index <= data.stepnumber - 1) {
      //       sum += num;
      //     }
      //   });
      //   this.finalValue = sum;
      //   this.defaultValue = data.stepnumber;
      // }

    }
  }
  onInputChange($event: any, data) {
    this.stepIndex = $event.value - 1;
    data.stepnumber = $event.value;
    // this.defaultValue = this.steps[this.stepIndex]
    let array = this.steps.slice(0, $event.value);
    this.finalValue = 0;
    this.finalValue = array.reduce(function (acc, obj) { return acc + obj; }, 0);
    console.log(this.defaultValue);

  }
  navigatetolink(value, data) {
    if (value == 'history') {
      this.router.navigate(['/payment-history'], { queryParams: { id: data.studentId } });
    } else {
      this.router.navigate(['/statement-of-accounts'], { queryParams: { id: data.studentId } });
    }
  }
  apply(data, fee) {
    data.isDueAmountShown = true;
    data.finalamount = this.finalValue;
    this.grandtotal = 0;
    this.allStudentsList.forEach(ele => {
     // this.grandtotal += ele.finalamount
      this.grandtotal += ele.finalamount==undefined?0:ele.finalamount;
    })
    data.isAmountChecked = false;
    data.isDueAmountShown = true;
    fee.payAmount = this.finalValue
    // this.editAmount = !this.editAmount;
  }


  getStudentFee() {
    let data: any;
    data = {
      stuid: localStorage.selectedstudentid,//this.allStudentsList[1].studentId,
      isPaySibling: '1',
      providerTypeID: '144'
    }
    this.paymentService.getFeeDetails(data).subscribe(
      
      res => {
        
        if (res?.data == null) {
          this.getFeeDetails = [];
        } else {
          this.getFeeDetails = res?.data;
          let count = 0
          this.getFeeDetails.forEach(ele => {
            ele.feeDetail.forEach(ele1 => {

              count += ele1.dueAmount,
                ele.isAmountChecked = false;
            })
          })
          
          this.allStudentsList.forEach(ele => {
            this.getFeeDetails.forEach(ele1 => {
              
              if (ele.studentId == ele1.stU_NO) {
                ele.feedetails = ele1;
                ele.checked = true;
                ele.isAmountChecked = false;
                ele.isDueAmountShown = false;
                ele.finalamount = 0;
                ele.subtotal = 0;
                ele.stepnumber = 0;
              }
              else if(this.allStudentsList.length !=this.getFeeDetails.length) {
                ele.feedetails = null;
                ele.checked = false;
                ele.isAmountChecked = false;
                ele.isDueAmountShown = false;
                ele.finalamount = 0;
                ele.subtotal = 0;
                ele.stepnumber = 0;
              }
            })
          })
          this.grandtotal = count
        }
      }
    )
  }
  showOptions(event: MatCheckboxChange, data): void {
    if (event.checked == false) {
      data.checked = false;
      this.grandtotal = this.grandtotal - data.finalamount
    } else {
      data.checked = true;
      this.grandtotal = this.grandtotal + data.finalamount
    }
  }

  getFeeType() {
    this.paymentService.getPaymentType(this.allStudentsList[0].studentId).subscribe(
      res => {
        this.paymentTypeID = res?.data?.filter(x => x.providerTypeID == '2')[0].paymentTypeID;

      }
    )
  }

  postFeeDetails(template) {
    let data: any[] = [];
    let data1: any[] = [];
    data1 = this.allStudentsList.filter(x => x.checked == true);

    data1.forEach(ele1 => {
      let feedetail: any = [];
      ele1.feedetails.feeDetail.forEach(ele => {

        ele1.subtotal = 0
      })
      ele1.feedetails.feeDetail.forEach(ele => {

        ele1.subtotal += ele.payAmount
      })
      ele1.feedetails.feeDetail.forEach(ele => {
        feedetail.push({ "blockPayNow": ele.blockPayNow, "advancePaymentAvailable": ele.advancePaymentAvailable, "advanceDetails": ele.advanceDetails, "discAmount": 0, "dueAmount": ele.dueAmount, "payAmount": ele.payAmount, "originalAmount": ele.originalAmount, "feeDescription": ele.feeDescription, "feeID": ele.feeID, "activityRefID": ele.activityRefID, "activityFeeCollType": ele.activityFeeCollType })
      })

      data.push({ "stU_NO": ele1.studentId, "onlinePaymentAllowed": ele1.feedetails.onlinePaymentAllowed, "userMessageforOnlinePaymentBlock": ele1.feedetails.userMessageforOnlinePaymentBlock, "ipAddress": this.ipAddress ? this.ipAddress : this.getIpAddress(), "paymentTypeID": this.paymentTypeID, "paymentProcessingCharge": ele1.feedetails.paymentProcessingCharge, "payingAmount": ele1.subtotal, "payMode": ele1.feedetails.payMode, "feeDetail": feedetail, "discountDetails": [] })
    })

    this.paymentService.postFeeDetails(data).subscribe(
      (res: any) => {
        this.PaymentRefNo = res?.PaymentRefNo;
        localStorage.setItem("postPaymentRefNo", res.PaymentRefNo);

        this.PaymentRedirectURL = res.PaymentRedirectURL;
      }
    )


  }

  getIpAddress() {
    this.ipAddress = localStorage.getItem('IPAddress');
    if (!this.ipAddress)
      this.ipAddress = localStorage.getItem('IPAddress')
    return this.ipAddress;
  }
  openConfirm(template: TemplateRef<any>, template1: TemplateRef<any>) {

    //this.save()
    let data: any[] = [];
    let data1: any[] = [];
    data1 = this.allStudentsList.filter(x => x.checked == true);

    data1.forEach(ele1 => {
      let feedetail: any = [];
      ele1.feedetails.feeDetail.forEach(ele => {

        ele1.subtotal += ele.payAmount
      })
      ele1.feedetails.feeDetail.forEach(ele => {
        feedetail.push({ "blockPayNow": ele.blockPayNow, "advancePaymentAvailable": ele.advancePaymentAvailable, "advanceDetails": ele.advanceDetails, "discAmount": 0, "dueAmount": ele.dueAmount, "payAmount": ele.payAmount, "originalAmount": ele.originalAmount, "feeDescription": ele.feeDescription, "feeID": ele.feeID, "activityRefID": ele.activityRefID, "activityFeeCollType": ele.activityFeeCollType })
      })

      data.push({ "stU_NO": ele1.studentId, "onlinePaymentAllowed": ele1.feedetails.onlinePaymentAllowed, "userMessageforOnlinePaymentBlock": ele1.feedetails.userMessageforOnlinePaymentBlock, "ipAddress": localStorage.getItem('IPAddress'), "paymentTypeID": this.paymentTypeID, "paymentProcessingCharge": ele1.feedetails.paymentProcessingCharge, "payingAmount": ele1.subtotal, "payMode": ele1.feedetails.payMode, "feeDetail": feedetail, "discountDetails": [] })
    })
    let postdata: any[] = [];
    postdata = data.filter(x => x.payingAmount == 0);
    if (postdata.length > 0) {
      this.openMessageDialog('', true, this.langTrans?.invalidAmount)
    } else {
      this.dialogOpen = this.dialog.open(template, {
        width: '600px',
        panelClass: 'custom-modalbox'
      });
      this.postFeeDetails(template1)
    }
  }
  gotoPayment(template: TemplateRef<any>) {
    window.open(this.PaymentRedirectURL, '_self')
  }

  cancel() {
    this.router.navigate(['/home'])
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      minWidth: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()

    });
  }
}
