import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ElementRef, EventEmitter, NgZone, ViewChild, Output } from '@angular/core';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, ServiceinfoService } from 'src/app/service';
import { TokenService } from 'src/app/service/token.service';
import { MapsAPILoader } from '@agm/core'
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControl } from 'ngx-typesafe-forms';
import { RegistrationService } from 'src/app/service/registration.service';
import { MessageComponent } from 'src/app/message/message.component';
import { ReregistrationService } from 'src/app/service/reregistration.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { PdfserviceService } from 'src/app/service/pdfservice.service';
import { ConfigService } from 'src/app/service/config.service';
import { GoogleMapsAutocompleteService } from 'src/app/service/google-maps-autocomplete-service.service';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatSelectChange } from '@angular/material/select';
import { termsAgreed } from 'src/app/models/registration.model';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-re-registration',
  templateUrl: './re-registration.component.html',
  styleUrls: ['./re-registration.component.scss']
})
export class ReRegistrationComponent implements OnInit {

  digopen: any;
  digopenParent: any;
  currentUser: any;
  date: Date = new Date();
  addressValue: any = 1;
  bsuId: any
  aceId: any;
  siblingsList: any[] = [];
  public siblingList = []
  isAgree: boolean = false;
  currentUserAmount: any = 0;
  addressvalue2: boolean = false;
  addressvalue1: boolean = true;
  pdfs: any[];
  tandcfilteredDatapdf: any;
  BusBehaviourCodedatapdf: any;
  parentUndertakingPdf: any;
  totalSiblingsValue: any=0;
  siblingPDF: any;
  siblingPDF_s:any;
  pdfName: string="Parent Undertaking";
  parentUndertakingFile: any;
  dialogOpen: MatDialogRef<any, any>;
  isConfirmProceed: boolean=false;
  PaymentRefNo: any;
  PaymentRedirectURL: any;
  feedetailsData: any;
  isparentUndertakingChecked: boolean=false;isPaymentEnable: any;
  paymentTypeID: any;
  reT_BLOCKSSDATE:any;
  selectedpickuppoint: any;
  selecteddropoffpoint: any;
  ConfirmStudentlist: any[];
  ErrordialogOpen: MatDialogRef<any, any>;
  showError: boolean;
  errorMessage: string;
  geoCoder: google.maps.Geocoder;
  pickuplocation: any='';
  dropofflocation: any='';
  markers1: any;
  searchInput: string = '';   predictions: google.maps.places.AutocompletePrediction[] = [];
  pdfContent: any;
  pdfHtml: string;
  contentTitle: string;
  contentViewer: MatDialogRef<unknown, any>;
  isTerms:boolean=false;
  isParentTaking:boolean=false;
  isBusBehavior:boolean=false;
  studentDetails: any;
  isGrade: boolean;
  isParentUdertakingBox: boolean=false;
  isHybrid: any;
  disableSave: boolean=true;
  city: any;
  currency: string;
  referenceNo: void;
  showParentUndertaking: boolean=false;
  transportData: any[]=[];
  isReregisterEnable: boolean=false;
  dropoffAreaErrMsg:  boolean=false;
  medicalConditionForm: { [key: string]: FormGroup } = {};
  medicalConditionDetails: any[] = [];
  medicalFormInValid: boolean = false;
  termsAgreedDetails: termsAgreed = new termsAgreed();
  hasdownloadurl:boolean=false;
  uaePasscontentViewer:any
  @ViewChild('owlElement') owlElement:any;
  constructor(private dialog: MatDialog, private router: Router, private datePipe: DatePipe, private tokenService: TokenService, private serviceinfoService: ServiceinfoService,
     private registrationService: RegistrationService, private reregistrationService: ReregistrationService,
     private activatedRoute: ActivatedRoute, private common:CommonService, private sanitizer: DomSanitizer,
     private pdfservice:PdfserviceService, private configService:ConfigService,private autocompleteService:GoogleMapsAutocompleteService, private formBuilder: FormBuilder) { }




  radioChange(event: any) {
    this.addressValue = event.source.value;
    if (this.addressValue == 1) {
      this.addressvalue1 = true;
      this.addressvalue2 = false;

    } else {
      this.addressvalue2 = true;
      this.addressvalue1 = false;
      this.isPickupOnly = false;
      this.isDropOnly=false;

    }
    this.residentialsDetails.reset()
  }
  minDate: Date;
  maxDate: Date;
  pickuppoints: any[] = [];
  pickupareapoints: any[] = [];
  dropoffareapints: any[] = [];
  selectedpickuparea1: any = '';
  selecteddropofarea2: any = '';
  tptFee: any[] = [];
  selectedpickuparea: string = "";
  selecteddropoffarea: string = "";
  transportTypes: any[] = [];
  emirates: any[] = [];
  isPickupOnly: boolean = false;
  isDropOnly: boolean = false;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  docdownloadurl:any;
  mapZoom = 12;
  mapCenter: google.maps.LatLng;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
  };

  markerInfoContent = '';
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,
  };
  residentialsDetails!: FormGroup;
  registerationdetails: any = {};
  token: any = '';
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();
  mapOpenedFrom: string = '';
  tptType: any;
  IsCloseFee: boolean = false;
  pickupAreaList: any[] = [];
  pickupPointList: any[] = [];
  dropoffpoints: any[] = [];
  dropoffareapoints: any[] = [];
  fromDt: any
  fromDtadd: any;
  termsForm: FormGroup
  dropoffPointData: any[] = [];
  siblingParentUndertakings: any[] = [];
  putAgreed: boolean = false;
  medicalConditionExists: boolean = false;
  carouselVisible: boolean = true;
  isUaepassEnabled:boolean=false;

  ngOnInit(): void {
    debugger;
    let oluname=localStorage.getItem("loginuser");
this.reregistrationService.getUaepassEnableByUser(oluname,"Reregistration").subscribe(
  res=>{
    if(res["data"]=="true")
    {
      this.isUaepassEnabled=true;
    }
    else{
      this.isUaepassEnabled=false;
    }
  }
)

    this.docdownloadurl =localStorage.getItem("docdownloadurl");

   if(this.docdownloadurl?.length>0)
   {
     this.hasdownloadurl=true;
   }
   else
   {
     this.hasdownloadurl=false;
   }
  
    localStorage.removeItem("selecteddropoffarea");
    let leasemodelschool = JSON.parse(localStorage.getItem('transportChildInfo'))[0].leasemodelschool;
    this.city = JSON.parse(localStorage.getItem("schoolDetails"))?.city;
    this.currency= localStorage.getItem('bsu_currency')
    this.transportData=JSON.parse(localStorage.getItem("transportChildInfo"));
    let childData= JSON.parse(localStorage.getItem("parentChildrens"))[0];
    this.studentDetails= this.transportData.find(x=>x.stU_ID == childData.stuId);
    // this.isGrade = (this.studentDetails?.grade.includes('01') ) || (this.studentDetails?.grade.includes('02') ) || this.studentDetails?.grade.includes('03') || this.studentDetails?.grade.includes('KG') ? true : false
    // this.showParentUndertaking=(this.studentDetails?.grade.includes('1') && !this.studentDetails?.grade.includes('10') && !this.studentDetails?.grade.includes('11') && !this.studentDetails?.grade.includes('12') ) || (this.studentDetails?.grade.includes('2') && !this.studentDetails?.grade.includes('12')) || this.studentDetails?.grade.includes('3') || this.studentDetails?.grade.includes('4')|| this.studentDetails?.grade.includes('5')||this.studentDetails?.grade.includes('6')|| this.studentDetails?.grade.includes('Year 7')|| this.studentDetails?.grade.includes('KG')? true : false
    console.log(leasemodelschool);
    this.isPaymentEnable = leasemodelschool;
    this.isHybrid=JSON.parse(localStorage.getItem('transportChildInfo'))[0].ishybrid
    let bsuId=localStorage.getItem('bsU_ID');
    let schoolList = JSON.parse(localStorage.getItem('schoolList'));
    this.isReregisterEnable=schoolList.find(x=>x.bsU_ID == bsuId)?.isReregisterEnable;

    this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
    console.log(this.minDate);

    this.maxDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 90);
    let data: any;
    data = JSON.parse(localStorage.getItem('residentialInfo'));
    if(data != null){
    this.registerationdetails = data;

      if(this.registerationdetails?.EMIRATE != ''){
        this.addressValue =2;
        this.addressvalue2 = true;
        this.addressvalue1 = false;

      }else{
        this.addressValue =1;
        this.addressvalue1 = true;
        this.addressvalue2 = false

      }
    }else{
      this.addressValue =1;
        this.addressvalue1 = true;
    }


    let busid: any;
    busid = JSON.parse(localStorage.getItem('parentChildrens'));
    this.bsuId = busid[0].bsuId;
    this.aceId = busid[0].acdId
    this.residentialsDetails = new FormGroup({
      EMIRATE: new FormControl('', Validators.required),
      HOUSE_NO: new FormControl('', Validators.required),
      ADDRESS: new FormControl('', Validators.required),
      TPT_TYPE: new FormControl(null, Validators.required),
      PICKUPAREA: new FormControl(null),
      DROPOFFAREA: new FormControl(null),
      PICKUPPOINT: new FormControl(null),
      DROPOFFPOINT: new FormControl(null),
      PICKUPLOCATION: new FormControl(''),
      DROPOFFLOCATION: new FormControl(''),
      PICKUPPOINTLANDMARK: new FormControl(''),
      DROPOFFPOINTLANDMARK: new FormControl(''),
      SERVICEDATE:new FormControl('', Validators.required),
    });
    this.termsForm = new FormGroup({
      checkedOne: new FormControl('', Validators.required),
      checkedTwo: new FormControl('', Validators.required),
      checkedThree: new FormControl('', Validators.required)
    })
    this.getMasers();
    this.getSiblingDetails();
    this.getSchoolsList();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params?.uaesignoutr == "true") {
        this.router.navigate(['/re-registration'], {
          queryParams: { isAgreed: true, checkTerms: true }
        });
        this.termsAgreedDetails.checkboxOne=true;
        this.termsAgreedDetails.checkboxThree=true;
        this.termsAgreedDetails.checkboxTwo=true;
        localStorage.setItem('termsForm', JSON.stringify(this.termsAgreedDetails));
      }

      if (params?.isAgreed) {
        this.isAgree = params.isAgreed == "false" ? false : true;
        this.termsAgreedDetails.checkboxOne = this.isAgree;
        this.termsForm.patchValue({
          checkedOne: this.isAgree
        });
      }
      if (params?.checkTerms) {
        if(params?.checkTerms == "true"){
          let termsAgreed: any;
          termsAgreed = JSON.parse(localStorage.getItem('termsAgreed'));
          this.isAgree = termsAgreed;

          let termsAgreedData: any;
          termsAgreedData = JSON.parse(localStorage.getItem('termsForm'));
          if (termsAgreedData != null) {
            this.termsForm.patchValue({
              checkedOne: this.isAgree,
              checkedTwo: termsAgreedData?.checkboxTwo,
              checkedThree: termsAgreedData?.checkboxThree
            });
            this.termsAgreedDetails.checkboxTwo = termsAgreedData?.checkboxTwo;
            this.termsAgreedDetails.checkboxThree = termsAgreedData?.checkboxThree;
          }
          
          let undertakingData: any;
          undertakingData = JSON.parse(localStorage.getItem('PUTAgreed'));
          this.putAgreed = undertakingData;
        }
      }
      if (params?.parentUndertaking) {
        this.putAgreed = params?.parentUndertaking == "false" ? false : true;
        this.currentUser.parentUndertaking = params?.parentUndertaking == "false" ? false : true;
      }
    })
    this.geoCoder = new google.maps.Geocoder;
    if(this.hasdownloadurl==true)
    {
      
      this.termsForm.controls['checkedOne'].setValue('true'); 
    this.termsForm.controls['checkedTwo'].setValue('true'); 
    this.termsForm.controls['checkedThree'].setValue('true');
    }
  }
  getPickupPoints() {

    if(this.selectedpickuparea1 != null && this.selectedpickuparea1 != undefined && this.selectedpickuparea1 != ''){
      let obj3: any = {
        headers: {
          "master": "PICKUP_TPT_RR",
          "bsuid": this.bsuId,
          "param1": "172",
          "param2": this.selectedpickuparea1.toString(),
          "Authorization": "Bearer " + this.token
        }
      }
      this.serviceinfoService.getMaster(obj3).subscribe(res => {
        this.pickupareapoints = res["data"];
        this.dropoffareapoints = res["data"];
        this.pickupAreaList = res["data"];
        //alert(this.pickupareapoints);
      })
    }
  }
  getDropOffPoints() {

    if (this.selecteddropofarea2 != null && this.selecteddropofarea2 != undefined && this.selecteddropofarea2 != '') {
      let obj3: any = {
        headers: {
          "master": "DROP_TPT_RR",
          "bsuid": this.bsuId,
          "param1": "172",
          "param2": this.selecteddropofarea2.toString(),
          "Authorization": "Bearer " + this.token
        }
      }
      this.serviceinfoService.getMaster(obj3).subscribe(res => {
        this.dropoffareapints = res["data"];
        this.dropoffPointData = res["data"];
        //alert(this.pickupareapoints);
      })
    }
  }

  getMasers() {
    let tkn = "";
    this.tokenService.GetToken("").subscribe(tknresult => {
      this.token = tknresult.access_token;
      tkn = tknresult.access_token;
      if (this.registerationdetails?.EMIRATE != '') {

        this.selecteddropofarea2=this.registerationdetails?.DROPOFFAREA
          this.selectedpickuparea1=this.registerationdetails?.PICKUPAREA
        }

      let obj1: any = {
        headers: {
          "master": "TPT_TYPE",
          "bsuid": this.bsuId,
          "Authorization": "Bearer " + tkn
        }
      }
      let obj2: any = {
        headers: {
          "master": "TPT_CITY_M",
          "param1": "172",
          "bsuid": this.bsuId,
          "Authorization": "Bearer " + tkn
        }
      }









        this.serviceinfoService.getMaster(obj1).subscribe(res => {

          this.transportTypes = res["data"]?.filter(x => x.id != -1).map(x => ({ descr: x.descr, id: x.id.toString() }));
          this.transportTypes.unshift({
            descr: 'Select',
            id: null
          })


          this.serviceinfoService.getMaster(obj2).subscribe(res => {

            this.emirates = res["data"];
            this.emirates.unshift({
              descr: 'Select',
              id: null
            })

            if (this.registerationdetails?.EMIRATE != '') {

              this.getAreaBasedOnCity(this.registerationdetails?.EMIRATE);

              this.residentialsDetails.patchValue({
                EMIRATE: this.registerationdetails?.EMIRATE,
                HOUSE_NO: this.registerationdetails?.HOUSE_NO,
                ADDRESS: this.registerationdetails?.ADDRESS,
                TPT_TYPE: this.registerationdetails?.TPT_TYPE ,
                PICKUPAREA: this.registerationdetails?.PICKUPAREA == "0"? 0:this.registerationdetails?.PICKUPAREA,
                DROPOFFAREA: this.registerationdetails?.DROPOFFAREA== "0"? 0:this.registerationdetails?.DROPOFFAREA,
                PICKUPPOINT: this.registerationdetails?.PICKUPPOINT,
                DROPOFFPOINT: this.registerationdetails?.DROPOFFPOINT,
                PICKUPLOCATION: this.registerationdetails?.PICKUPLOCATION,
                DROPOFFLOCATION: this.registerationdetails?.DROPOFFLOCATION,
                PICKUPPOINTLANDMARK: this.registerationdetails?.PICKUPPOINTLANDMARK,
                DROPOFFPOINTLANDMARK: this.registerationdetails?.DROPOFFPOINTLANDMARK,
                SERVICEDATE:this.registerationdetails?.SERVICEDATE
              });

              this.latitude=this.registerationdetails?.picklat
              this.longitude=this.registerationdetails?.picklog
              this.latitudeDrop=this.registerationdetails?.droplat
              this.longitudeDrop=this.registerationdetails?.droplog

              this.selectedpickuppoint=this.registerationdetails?.PICKUPPOINT;
              this.selecteddropoffpoint=this.registerationdetails?.DROPOFFPOINT;

              this.pickuplocation=this.registerationdetails?.pickuplocation;
              this.dropofflocation=this.registerationdetails?.dropofflocation;

              let tptdata =[];
              tptdata = this.transportTypes.filter(x=>x.id == this.registerationdetails?.TPT_TYPE);

              if(this.registerationdetails?.TPT_TYPE == '0'){
                this.isPickupOnly = true;
                this.isDropOnly = true;
              }
              if(this.registerationdetails?.TPT_TYPE == '1'){
                this.isPickupOnly = true;
                this.isDropOnly = false;
              }
              if(this.registerationdetails?.TPT_TYPE == '2'){
                this.isPickupOnly = false;
                this.isDropOnly = true;
              }

            //this.getOthersDetails();
            if(this.selectedpickuparea1!=null ||this.selectedpickuparea1!=''){
              this.getPickupPoints();
            }
            if(this.selecteddropofarea2!=null ||this.selecteddropofarea2!=''){
              this.getDropOffPoints();
            }
            } else {
              this.residentialsDetails = new FormGroup({
                EMIRATE: new FormControl('', Validators.required),
                HOUSE_NO: new FormControl('', Validators.required),
                ADDRESS: new FormControl('', Validators.required),
                TPT_TYPE: new FormControl(null, Validators.required),
                PICKUPAREA: new FormControl(null),
                DROPOFFAREA: new FormControl(null),
                PICKUPPOINT: new FormControl(null),
                DROPOFFPOINT: new FormControl(null),
                PICKUPLOCATION: new FormControl(''),
                DROPOFFLOCATION: new FormControl(''),
                PICKUPPOINTLANDMARK: new FormControl(''),
                DROPOFFPOINTLANDMARK: new FormControl(''),
                SERVICEDATE:new FormControl('', Validators.required),

              });
            }

            let medData: any;
            medData = JSON.parse(localStorage.getItem('medicalInfo'));
            if (medData != null) {
              this.medicalConditionDetails = medData;
              let medicalData = this.medicalConditionDetails?.find((x: any) => x.STU_ID == this.currentUser.stU_NO);
              if (medicalData?.medicalRadiobtn == '1') {
                this.medicalConditionExists = true;
                this.medicalConditionForm[this.currentUser.stU_NO] = this.createMedicalFormGroup();
                this.medicalConditionForm[this.currentUser.stU_NO].patchValue({
                  medicalRadiobtn: medicalData.medicalRadiobtn,
                  medicalDesc: medicalData.medicalDesc,
                  count: medicalData.medicalDesc.length,
                  medicalRadiobtn2: medicalData.medicalRadiobtn2,
                  medicalDesc2: medicalData.medicalDesc2,
                  count2: medicalData.medicalDesc2.length,
                  promptEnable: medicalData.medicalDesc2.length >= 15
                });
              }
            }
          });
        });
    });
  }

  checkTPTType(value) {
    if (value == "1") {
      this.isParentTaking = false;
    }
    else {
      this.isParentTaking = true;
    }

    let data: any[];
    data = this.transportTypes.filter(x => x.id == value);
    this.tptType = data[0].id
    if (data[0].id == 0 ) {
      this.isPickupOnly = true;
      this.isDropOnly = true;
      this.selectedpickuppoint='';
      this.selecteddropoffpoint=''
      this.residentialsDetails.controls['PICKUPAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPPOINT'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFPOINT'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].setValidators([Validators.required]);

    }
    if (data[0].id == 1) {
      this.isPickupOnly = true;
      this.isDropOnly = false;
      this.selectedpickuppoint=''
      this.residentialsDetails.controls['PICKUPAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPPOINT'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFAREA'].clearValidators();
      this.residentialsDetails.controls['DROPOFFPOINT'].clearValidators();
      this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].clearValidators();
    }
    if (data[0].id == 2) {
      this.isPickupOnly = false;
      this.isDropOnly = true;
      this.selecteddropoffpoint =''
      this.residentialsDetails.controls['DROPOFFAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFPOINT'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPAREA'].clearValidators();
      this.residentialsDetails.controls['PICKUPPOINT'].clearValidators();
      this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].clearValidators();
    }
    this.residentialsDetails.controls['DROPOFFAREA'].markAsUntouched();
    this.residentialsDetails.controls['DROPOFFAREA'].markAsPristine();
    this.residentialsDetails.controls['DROPOFFPOINT'].markAsUntouched();
    this.residentialsDetails.controls['DROPOFFPOINT'].markAsPristine();
    this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].markAsUntouched();
    this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].markAsPristine();
    this.residentialsDetails.controls['PICKUPAREA'].markAsUntouched();
    this.residentialsDetails.controls['PICKUPAREA'].markAsPristine();
    this.residentialsDetails.controls['PICKUPPOINT'].markAsUntouched();
    this.residentialsDetails.controls['PICKUPPOINT'].markAsPristine();
    this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].markAsUntouched();
    this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].markAsPristine();
    this.residentialsDetails.patchValue({
      PICKUPPOINT: '',
      PICKUPAREA: '',
      PICKUPPOINTLANDMARK: '',
      DROPOFFAREA: '',
      DROPOFFPOINTLANDMARK: '',
      DROPOFFPOINT: ''
    })
    this.selectedpickuparea1 = ''
    this.selecteddropofarea2 = ''
    this.getOthersDetails()

  }
  openInfoWindow(marker: MapMarker) {
    // this is called when the marker is clicked.
    this.infoWindow.open(marker);
  }

  getCurrentLocation(value) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        console.log(position);


        const point: google.maps.LatLngLiteral = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        if(value == 'pick'){
          this.center1 = point;
          // this.dropofflocation= data[0].descr
            this.markers = [];
            // Add a marker for the selected location
            this.markers.push({position: this.center1,label: '',title: '',options: { animation: google.maps.Animation.DROP
           }
           });
           this.latitude = position.coords.latitude;
           this.longitude = position.coords.longitude;
           this.PickupgetAddress(position.coords.latitude,position.coords.longitude)
        }else{
          this.center2 = point;
          // this.dropofflocation= data[0].descr
            this.markers1 = [];
            // Add a marker for the selected location
            this.markers1.push({position: this.center2,label: '',title: '',options: { animation: google.maps.Animation.DROP
           }
           });
           this.latitudeDrop = position.coords.latitude;
           this.longitudeDrop = position.coords.longitude;
           this.DropoffgetAddress(position.coords.latitude,position.coords.longitude)
        }
        console.log(point);

        this.mapCenter = new google.maps.LatLng(point);
        this.map.panTo(point);

        this.markerInfoContent = "I'm here!";

        this.markerOptions = {
          draggable: false,
          animation: google.maps.Animation.DROP,
        };
      },
      (error) => {


        if (error.PERMISSION_DENIED) {
          // alert("Couldn't get your location Permission denied");
        } else if (error.POSITION_UNAVAILABLE) {
          // alert(
          //   "Couldn't get your location Position unavailable"
          // );
        } else if (error.TIMEOUT) {
          // alert("Couldn't get your location 'Timed out'");
        } else {
          // alert(error.message);
        }
      },
      { enableHighAccuracy: true }
    );
  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()
    });
  }
  previous() {
    this.onTabChange.emit(1);
  }

  eventHandler(event: any, name: string) {

    // Add marker on double click event
    if (name === 'mapDblclick' || name === 'mapClick') {
      this.dropMarker(event)
    }
  }
  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;
  zoom = 20;
  maxZoom = 20;
  minZoom = 3;
  latitude: number = 0;
  longitude: number = 0;
  latitudeDrop: number = 0;
  longitudeDrop: number = 0;
  center!: google.maps.LatLngLiteral;
  center1!: google.maps.LatLngLiteral;
  center2!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'hybrid',
    maxZoom: this.maxZoom,
    minZoom: this.minZoom,
  }
  markers = [] as any;
  infoContent = ''
  dropMarker(event: any) {
    if(this.mapOpenedFrom == 'pick'){

      this.latitude = event.latLng.lat();
      this.longitude = event.latLng.lng();
      this.PickupgetAddress(this.latitude,this.longitude)
      if(this.tptType ==0){
        this.latitudeDrop = this.latitude;
        this.longitudeDrop = this.longitude;

      }
      this.markers = [];
    this.markers.push({
      position: {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      },
      label: {
        color: 'blue',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      info: 'Marker info ' + (this.markers.length + 1),
      options: {
        animation: google.maps.Animation.DROP,
      },
    })
    }else{
      this.latitudeDrop = event.latLng.lat();
      this.longitudeDrop = event.latLng.lng();
      this.markers1 = [];
    this.markers1.push({
      position: {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      },
      label: {
        color: 'blue',
        text: 'Marker label ' + (this.markers1.length + 1),
      },
      title: 'Marker title ' + (this.markers1.length + 1),
      info: 'Marker info ' + (this.markers1.length + 1),
      options: {
        animation: google.maps.Animation.DROP,
      },
    })
      this.DropoffgetAddress(this.latitudeDrop,this.longitudeDrop)
    }




  }
  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }
  zoomIn() {
    if (this.zoom < this.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.minZoom) this.zoom--;
  }

  getGeoLocation(value, name, event) {
    console.log(value, event);
    let data: any = [];
    data.push({
      SBLID: this.residentialsDetails.value.PICKUPAREA,
    });

    // if(this.tptType == "Round Trip"){

    //   this.residentialsDetails.patchValue({
    //     'DROPOFFAREA':this.residentialsDetails.controls['PICKUPAREA'].value
    //   })
    // }

    // else{
    //   this.residentialsDetails.patchValue({
    //     'DROPOFFAREA':''
    //   })
    // }
    this.currentUser.feedetails=[];
    this.currentUser.currentfeedetails=0;
    this.totalSiblingsValue=0;


    if (name == 'parea') {

      this.selectedpickuparea = "Pickup Area: " + event.source.triggerValue;
      this.selectedpickuparea1 = value;
      let data :any[]=[];
      data = this.pickuppoints.filter(x=>x.id == value);
      console.log(data);
      if ((data[0].lat == 0 && data[0].long == 0) || (data[0].lat == null && data[0].long == null)) {
        this.getCurrentLocation('pick');
      } else {
        this.latitude = Number(data[0].lat);
        this.longitude = Number(data[0].long);
        this.center1 = { lat: Number(data[0].lat), lng: Number(data[0].long) };

        this.pickuplocation = data[0].descr
        this.markers = [];
        // Add a marker for the selected location
        this.markers.push({
          position: this.center1, label: '', title: data[0].descr, options: {
            animation: google.maps.Animation.DROP
          }
        });
      }
      this.getOthersDetails()
      this.getPickupPoints();
    }
    if (name == 'darea') {
      if(this.tptType == 0)
      {
        if(this.residentialsDetails.controls['PICKUPAREA'].value == this.residentialsDetails.controls['DROPOFFAREA'].value)
        this.dropoffAreaErrMsg=false
        else this.dropoffAreaErrMsg=true
      }
      this.selecteddropoffarea = "Drop off Area: " + event.source.triggerValue;

      this.selecteddropofarea2 = value;
      let data :any[]=[];
      data = this.pickuppoints.filter(x=>x.id == value);
      console.log(data);
      if(data[0].lat == 0 && data[0].long == 0){
        this.getCurrentLocation('drop');
      }else{
        this.latitudeDrop = Number(data[0].lat);
        this.longitudeDrop = Number(data[0].long);
      this.center2 = { lat: Number(data[0].lat), lng: Number(data[0].long) };
      this.dropofflocation= data[0].descr
            this.markers1 = [];
            // Add a marker for the selected location
            this.markers1.push({position: this.center2,label: '',title: data[0].descr,options: { animation: google.maps.Animation.DROP
           }
           });
      }

      this.getOthersDetails()
      this.getDropOffPoints();
    }
    if (value != null && value != -1)
      this.registrationService.getGeoLocation(this.token, data).subscribe(res => {
        if (name == 'parea') {

          this.residentialsDetails.controls['PICKUPLOCATION'].setValue(`${res?.data?.sbL_LAT} , ${res?.data?.sbL_LONG}`)
        }
        else {
          this.selecteddropofarea2 = value;
          this.residentialsDetails.controls['DROPOFFLOCATION'].setValue(`${res?.data?.sbL_LAT} , ${res?.data?.sbL_LONG}`)

        }
      })
  }
  getFeeStructure() {

    let data: any = [];
    data.push({
      stU_CURR_ACD_ID: this.currentUser.stU_NEXT_ACD_ID ?? 0,
      stU_BSU_ID: this.currentUser.stU_BSU_ID ?? 0,
      TRIPTYPE: this.residentialsDetails.value.TPT_TYPE ?? 0,
      SBLID: Number(this.residentialsDetails.value.PICKUPAREA ?? 0),
      DSBLID: Number(this.residentialsDetails.value.DROPOFFAREA ?? 0),
      STU_ID:this.currentUser.stU_NO,
      PROVIDER_BSU_ID:this.currentUser.provideR_BSU_ID,
      TRM_STARTDT:this.currentUser.reT_TERM_START_DATE,
      SSV_STARTDT:this.currentUser.reT_SSDATE
    })
    this.getFeeDetailsCurrentUser(data, '')
  }
  getFeeDetailsSiblings(data, value?: any) {

    this.reregistrationService.getFeeAmount(data).subscribe(res => {
      if (res.data > 0) {
        this.IsCloseFee = true
      } else {
        this.IsCloseFee = false

      }


        value.feedetails = res.data;
        console.log(value.currentfeedetails
          );
          value.feedetails?.forEach(ele=>{
          value.currentfeedetails=  value.currentfeedetails +
         ele.amount
        })
        console.log(this.siblingList);
        this.totalSiblingsValue = 0;

    this.siblingsList = this.siblingsList.filter(x => x.checked == true);
    console.log(this.siblingsList);
    console.log(this.currentUser);

    this.siblingsList.forEach(ele=>{

      this.totalSiblingsValue = this.totalSiblingsValue + ele.currentfeedetails
    })




    })
  }
  getFeeDetailsCurrentUser(data, value?: any) {
    if(this.isPaymentEnable != 1 && this.isHybrid != 1){
      this.reregistrationService.getFeeAmount(data).subscribe(res => {
        if (res.data > 0) {
          this.IsCloseFee = true
        } else {
          this.IsCloseFee = false

        }


          this.tptFee = res.data;
          let splitFee=this.tptFee;
          let mergeFee=[];
          let acdYr=JSON.parse(localStorage.getItem("transportChildInfo"))[0]?.currentAcademicYear?.split(" ");
          let feeTptDesc="Transport Fee (Bus fees for the academic year "+this.currentUser.newterM_DESCR +")"
          let feeOutDesc="Fee outstanding for previous term"
          let amt:any=0
          let outstandingAmt:any=0;
          let outCount=0;
          let tptcount=0;
          splitFee.forEach(x=>{
            mergeFee.push({ amount: x.amount, feE_DESCR: x.feE_DESCR })
          })
          this.currentUser.feedetails= mergeFee;
          this.tptFee.forEach(ele=>{
            this.currentUser.currentfeedetails= this.currentUser.currentfeedetails +
           ele.amount
          })

      })
    }

  }
  openMap(template: TemplateRef<any>, value: any) {
    this.mapOpenedFrom = value;

    this.dialog.open(template, {
      disableClose: true,
      width: '1040px',
      panelClass: ['custom-modalbox', 'mapPopup']
    });
  }

  close() {
    this.dialog.closeAll();
    this.searchInput = '',
    this.predictions=[];
  }
  checkPickupArea(name,event)
  {
    if (this.tptType == 0) {
      if(this.residentialsDetails.controls['PICKUPAREA'].value !=''){
        this.residentialsDetails.patchValue({
          'DROPOFFAREA': this.residentialsDetails.controls['PICKUPAREA'].value
        })
        this.getGeoLocation(this.residentialsDetails.controls['PICKUPAREA'].value,name,event);
      }
      else {
        this.residentialsDetails.patchValue({
          'DROPOFFAREA': ''
        })
      }
    }
  }
  checkPickupPoint() {
    if (this.tptType == 0) {
      if(this.residentialsDetails.controls['DROPOFFAREA'].value !=''){
        this.residentialsDetails.patchValue({
          'DROPOFFPOINT': this.residentialsDetails.controls['PICKUPPOINT'].value
        })
        this.isDropOnly=true;
        this.selecteddropoffpoint= this.residentialsDetails.controls['PICKUPPOINT'].value
      }

    } else {
      this.residentialsDetails.patchValue({
        'DROPOFFPOINT': ''
      })
      this.residentialsDetails.controls['SERVICEDATE'].setValue(this.datePipe.transform(this.currentUser.reT_SSDATE, 'yyyy-MM-dd'))
      this.residentialsDetails.controls['SERVICEDATE'].markAsDirty();
      this.residentialsDetails.controls['SERVICEDATE'].markAsTouched();
      this.residentialsDetails.controls['SERVICEDATE'].markAsPristine();
    }
    console.log(this.residentialsDetails.value);
  }
  closeFee() {
    this.IsCloseFee = false
  }

  clearAreaSearch(type: any){
    if(type == 'pickupArea'){
      this.pickuppoints = this.pickupPointList;
    }else if(type == 'dropoffArea'){
      this.dropoffpoints = this.pickupPointList;
    }
  }

  clearPointSearch(type:any){
    if (type == 'pickupPoint') {
      this.pickupareapoints = this.pickupAreaList;
    } else if (type == 'dropoffPoint') {
      this.dropoffareapints = this.dropoffPointData;
    }
  }

  clearSearchValue(event:any){
    event.target.value = '';
  }

  onKey(value) {
    this.pickuppoints = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.pickuppoints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.pickuppoints = this.pickupPointList
    }


  }
  onKeyPoint(value) {
    this.dropoffpoints = this.searchPoint(value);
  }
  searchPoint(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.dropoffpoints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.dropoffpoints = this.pickupPointList
    }


  }
  onkeypickarea(value) {
    this.pickupareapoints = this.searchpickarea(value);
  }
  searchpickarea(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.pickupareapoints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.pickupareapoints = this.pickupAreaList
    }


  }
  onKeyArea(value) {
    this.dropoffpoints = this.searchArea(value);
  }
  searchArea(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.dropoffpoints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.dropoffpoints = this.pickupAreaList
    }


  }

  onkeyDropOffPoint(value) {
    this.dropoffareapints = this.searchDropOffPoints(value);
  }
  searchDropOffPoints(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.dropoffareapints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.dropoffareapints = this.pickupAreaList
    }
  }

  getOthersDetails() {
    if (this.selectedpickuppoint == -1) {
      this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].setValidators([Validators.required]);
      // this.latitude = 0;
      // this.longitude = 0;

    } else {
      this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].clearValidators();
      this.residentialsDetails.patchValue({
        PICKUPPOINTLANDMARK: ''
      })
      this.latitude = 0;
      this.longitude = 0;

    }

    if (this.selecteddropoffpoint == -1) {
      this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].setValidators([Validators.required]);
      // this.latitudeDrop = 0;
      // this.longitudeDrop = 0;

    } else {
      this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].clearValidators();
      this.residentialsDetails.patchValue({
        DROPOFFPOINTLANDMARK: ''
      })
      this.latitudeDrop = 0;
      this.longitudeDrop = 0;

    }
  }

  getSiblings(event, data, prepoulate: boolean) {

    let checked = event?.checked;
    if(prepoulate){
      checked = true;

      if(data.stS_MED){
        this.medicalConditionExists = true;
      }
    }
    data.checked = checked;
    this.siblingsList = this.siblingsList.filter(x => x.checked == true);

    if(checked == false){
      data.feedetails=[];
      data.currentfeedetails =0;
      this.totalSiblingsValue = 0;

      console.log(this.siblingsList);
      console.log(this.currentUser);

      this.siblingsList.forEach(ele=>{

        this.totalSiblingsValue = this.totalSiblingsValue + ele.currentfeedetails
      })
    }else{
      let feedata: any[] = [];
      feedata.push({
        stU_CURR_ACD_ID: data.stU_NEXT_ACD_ID ?? 0,
        stU_BSU_ID: data.stU_BSU_ID ?? 0,
        TRIPTYPE: data.reT_ROUNDTRIP ?? 0,
        SBLID: data.pickuP_SBL_ID ?? 0,
        DSBLID: data.dropofF_SBL_ID ?? 0,
        STU_ID:data.stU_NO,
        PROVIDER_BSU_ID:data.provideR_BSU_ID,
        TRM_STARTDT:data.reT_TERM_START_DATE,
        SSV_STARTDT:data.reT_SSDATE
      })
      this.getFeeDetailsSiblings(feedata, data)

      this.medicalConditionForm[data.stU_NO]=this.createMedicalFormGroup();
      if(data.stS_MED){
        this.medicalConditionExists = true;
      }
      this.medicalConditionForm[data.stU_NO].patchValue({
        medicalRadiobtn: data.stS_MED ? '1' : '2',
        medicalDesc:data.stS_MEDICAL_CONDITION,
        count: data.stS_MEDICAL_CONDITION?.length ?? 0,
        medicalRadiobtn2: data.stS_MED_STAFF_REQUIRED ? '1' : '2',
        medicalDesc2: data.stS_MED_STAFF_REQUIRED_DESC,
        count2: data.stS_MED_STAFF_REQUIRED_DESC?.length ?? 0,
        promptEnable: data.stS_MED_STAFF_REQUIRED_DESC?.length >= 15
      });

      let medData: any;
      medData = JSON.parse(localStorage.getItem('medicalInfo'));
      let formIndex = medData?.findIndex((x: any) => x.STU_ID == data.stU_NO);

      if (formIndex != null && formIndex != undefined && formIndex != -1) {
        medData.forEach((x: any) => {
          if (x.STU_ID == data.stU_NO) {
            this.medicalConditionExists = x.medicalRadiobtn =='1' ? true : false;
            this.medicalConditionForm[data.stU_NO].patchValue({
              medicalRadiobtn: x.medicalRadiobtn,
              medicalDesc:x.medicalDesc,
              count: x.medicalDesc?.length ?? 0,
              medicalRadiobtn2: x.medicalRadiobtn2,
              medicalDesc2: x.medicalDesc2,
              count2: x.medicalDesc2?.length ?? 0,
              promptEnable: x.medicalDesc2?.length >= 15
            });
          }
        });
      } else {
        this.medicalConditionDetails.push({
          medicalRadiobtn: data.stS_MED ? '1' : '2',
          medicalDesc: data.stS_MEDICAL_CONDITION,
          medicalRadiobtn2: data.stS_MED_STAFF_REQUIRED ? '1' : '2',
          medicalDesc2: data.stS_MED_STAFF_REQUIRED_DESC,
          STU_ID: data.stU_NO
        });
      }


      this.reregistrationService.getTermsAndConditionsByGrade(data.stU_NEXT_GRADE, data.stU_BSU_ID).subscribe((res: any) => {

        let putPdfs = res?.data;
        this.siblingParentUndertakings = putPdfs;
        var parentUndertaking = putPdfs.filter(item => item.ttcuL_DOC_TYPE === "PUT");
        if (parentUndertaking.length > 0) {
          data.isParentTaking = true;
          // let grd = data.stU_NEXT_GRADE;
          // const bArray: string[] = parentUndertaking[0].ttcuL_GRD_IDS.split(",");
          // const containsA: boolean = bArray.includes(grd);
          // this.isGrade = containsA;
          // data.showParentUndertaking = containsA;
          data.showParentUndertaking = true;
        }
        else {
          data.isParentTaking = false;
        data.showParentUndertaking = false;
        }

        let putAgreed = JSON.parse(localStorage.getItem('siblingPUTAgreed'));
        if (putAgreed) {
          let siblingIds = JSON.parse(localStorage.getItem('selectedSiblings'));
          siblingIds?.forEach((y: any) => {
            if (data.stU_NO == y) {
              data.parentUndertaking = true;
            }
          });
        }
      });

      this.siblingsList.push(data);


      if(!prepoulate){
        let selectedSiblings = this.siblingsList.map((x:any) => x.stU_NO);

        localStorage.setItem('selectedSiblings', JSON.stringify(selectedSiblings));
      }
    }
    console.log(this.siblingsList);


    console.log(this.totalSiblingsValue);

  }
  onDateInputed(event, type) {
    console.log(event, type)
  }
  addSiblingDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '500px',
      panelClass: 'custom-modalbox'
    });
    // this.siblingList =
  }
  successDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '500px',
      panelClass: 'custom-modalbox'
    });
   // this.save(templateRef)
  }
  parentUndertakingDialog(templateRef: TemplateRef<any>, data) {

    this.siblingPDF = data.termS_UNDERTAKING_URL;
    this.siblingPDF_s=this.siblingPDF.replace("http://","https://");
    // data.parentUndertaking
    if(data.parentUndertaking == true){
      this.digopenParent = this.dialog.open(templateRef, {
        width: '1400px',
        panelClass: 'custom-modalbox'
      });
    }
    this.checkvalidation()
  }
  navigatetolink(lnk) {
    if (this.isAgree != true)
      this.router.navigate([lnk]);
  }
  getSiblingDetails() {
    this.reregistrationService.getSiblingDetails().subscribe(
      res => {
        let data = [];
        res?.data.forEach(x=>{
          x.busNo = "Pick-up: "+ (x.onwardbus ?? "NA")+" / "+"Drop-off: "+ (x.returnbus ?? "NA")
        })

        let studentId = localStorage.getItem('selectedstudentid');

        this.currentUser =res?.data.find((x:any) => x.stU_NO == studentId);

        localStorage.setItem('nextAcademicYearId', this.currentUser.stU_NEXT_ACD_ID);

        this.parentUndertakingFile = this.currentUser?.termS_UNDERTAKING_URL


        if(this.currentUser.reT_BLOCKSSDATE ==1){
          let date :any;
          date = new Date(this.currentUser.reT_TERM_START_DATE)
          this.minDate = new Date(date.getFullYear(), new Date(date).getMonth(), new Date(date).getDate());
          console.log(this.minDate);
          this.maxDate = new Date(date.getFullYear(), new Date(date).getMonth(), new Date(date).getDate() + 90);
          console.log(this.minDate);

        }
        res.data.forEach(x=>{
          let value  = this.transportData.find(y=>x.stU_NO == y.studentNo)?.reregistered;
          if(value)
          {
            x.childDisable = value == 0 ? false : true
          }
        })
        this.siblingList = res?.data;
        let status :any;
        status = this.siblingList.filter(x=>x.reT_STATUS_CLOSED_MESSAGE != "")
        if(this.siblingList.length == 1 && status.length == 1 ){
          this.isConfirmProceed = true;
        }else{
          this.isConfirmProceed =false;

        }
        if(this.siblingList.length > 1 ){
          this.isConfirmProceed =false;
        }
        this.currentUser.pickuparea = this.pickupdropdata(this.currentUser.stU_PICKUP_DROPOFF_LOCATION, 'pickuparea')
        this.currentUser.dropoffarea = this.pickupdropdata(this.currentUser.stU_PICKUP_DROPOFF_LOCATION, 'dropoffarea')
        this.currentUser.pickuppoint = this.pickupdropdata(this.currentUser.stU_PICKUP_DROPOFF_POINT, 'pickuppoint')
        this.currentUser.dropoffpoint = this.pickupdropdata(this.currentUser.stU_PICKUP_DROPOFF_POINT, 'dropoffpoint'),
        localStorage.setItem("selecteddropoffarea",this.currentUser.dropoffpoint );
        this.currentUser.triptype = this.currentUser.reT_ROUNDTRIP == 0 ? this.transportTypes.find(x=>x.id == 0)?.descr : this.currentUser.reT_ROUNDTRIP == 1 ? this.transportTypes.find(x=>x.id == 1)?.descr : this.transportTypes.find(x=>x.id == 2)?.descr,
        this.currentUser.feedetails = [];
        this.currentUser.currentfeedetails = 0;
        this.currentUser.parentUndertaking = false;

        if (this.currentUser.reT_ROUNDTRIP == "1") {
          this.isParentTaking = false;
        }
        else {
          this.isParentTaking = true;
        }

        let feedata: any[] = [];
        feedata.push({
          stU_CURR_ACD_ID: this.currentUser.stU_NEXT_ACD_ID ?? 0,
          stU_BSU_ID: this.currentUser.stU_BSU_ID ?? 0,
          TRIPTYPE: this.currentUser.reT_ROUNDTRIP ?? 0,
          SBLID: this.currentUser.pickuP_SBL_ID ?? 0,
          DSBLID: this.currentUser.dropofF_SBL_ID ?? 0,
          STU_ID:this.currentUser.stU_NO ?? 0,
      PROVIDER_BSU_ID:this.currentUser.provideR_BSU_ID?? 0,
      TRM_STARTDT:this.currentUser.reT_TERM_START_DATE?? '',
      SSV_STARTDT:this.currentUser.reT_SSDATE?? ''
        })
        console.log(this.siblingList);

        this.siblingList.forEach(ele => {

          ele.checked = false;
          ele.pickuparea = this.pickupdropdata(ele.stU_PICKUP_DROPOFF_LOCATION, 'pickuparea')
          ele.dropoffarea = this.pickupdropdata(ele.stU_PICKUP_DROPOFF_LOCATION, 'dropoffarea')
          ele.pickuppoint = this.pickupdropdata(ele.stU_PICKUP_DROPOFF_POINT, 'pickuppoint')
          ele.dropoffpoint = this.pickupdropdata(ele.stU_PICKUP_DROPOFF_POINT, 'dropoffpoint'),
            ele.triptype = ele.reT_ROUNDTRIP == 0 ? 'Two-way' : ele.reT_ROUNDTRIP == 1 ? 'One-way, Home to School' : 'One-way, School to Home',
            ele.feedetails = [],
            ele.currentfeedetails = 0,
            ele.parentUndertaking = false
        })

        this.siblingList.forEach((x:any) => {
          if(x.stU_NO == studentId){
            x.checked = true;
          }
        });

        // let users:any;
        // users = JSON.parse(localStorage.getItem('reregisterUsers'));
        // if(users!=null){
        //   this.currentUser.parentUndertaking = users[0].parentUndertaking;
        //   let siblinglist=[];

        //   siblinglist = users?.shift();
        //   if(siblinglist.length > 0){
        //    this.siblingList.forEach(ele=>{

        //      siblinglist.forEach(ele1=>{
        //        if(ele.stU_NO == ele1.stU_NO){
        //          ele.parentUndertaking = ele1.parentUndertaking;
        //        }
        //       })
        //     })
        //   }
        // }

        if(this.putAgreed){
          this.currentUser.parentUndertaking = this.putAgreed;
        }

        this.getFeeDetailsCurrentUser(feedata, '');
        console.log(this.siblingList);
        this.reT_BLOCKSSDATE =this.datePipe.transform(this.currentUser.reT_SSDATE, 'yyyy-MM-dd');
        this.residentialsDetails.controls['SERVICEDATE'].setValue(this.datePipe.transform(this.currentUser.reT_SSDATE, 'yyyy-MM-dd'))
        this.residentialsDetails.controls['SERVICEDATE'].markAsDirty();
        this.residentialsDetails.controls['SERVICEDATE'].markAsTouched();
        this.residentialsDetails.controls['SERVICEDATE'].markAsPristine();
        console.log(this.residentialsDetails.value);

        this.medicalConditionForm[this.currentUser.stU_NO]=this.createMedicalFormGroup();

        if(this.currentUser.stS_MED){
          this.medicalConditionExists = true;
        }

        this.medicalConditionForm[this.currentUser.stU_NO].patchValue({
          medicalRadiobtn: this.currentUser.stS_MED ? '1' : '2',
          medicalDesc:this.currentUser.stS_MEDICAL_CONDITION,
          count: this.currentUser.stS_MEDICAL_CONDITION?.length ?? 0,
          medicalRadiobtn2: this.currentUser.stS_MED_STAFF_REQUIRED ? '1' : '2',
          medicalDesc2: this.currentUser.stS_MED_STAFF_REQUIRED_DESC,
          count2: this.currentUser.stS_MED_STAFF_REQUIRED_DESC?.length ?? 0,
          promptEnable: this.currentUser.stS_MED_STAFF_REQUIRED_DESC?.length >= 15
        });

        let formIndex = this.medicalConditionDetails.findIndex((x: any) => x.STU_ID == this.currentUser.stU_NO);

        if (formIndex != -1) {
          this.medicalConditionDetails.forEach((x: any) => {
            if (x.STU_ID == this.currentUser.stU_NO) {
              x.medicalRadiobtn = this.currentUser.stS_MED ? '1' : '2',
                x.medicalDesc = this.currentUser.stS_MEDICAL_CONDITION,
                x.medicalRadiobtn2 = this.currentUser.stS_MED_STAFF_REQUIRED ? '1' : '2',
                x.medicalDesc2 = this.currentUser.stS_MED_STAFF_REQUIRED_DESC
            }
          });
        } else {
          this.medicalConditionDetails.push({
            medicalRadiobtn: this.currentUser.stS_MED ? '1' : '2',
            medicalDesc: this.currentUser.stS_MEDICAL_CONDITION,
            medicalRadiobtn2: this.currentUser.stS_MED_STAFF_REQUIRED ? '1' : '2',
            medicalDesc2: this.currentUser.stS_MED_STAFF_REQUIRED_DESC,
            STU_ID: this.currentUser.stU_NO
          });
        }


        this.getTermsConditions(this.currentUser.stU_NEXT_GRADE, this.currentUser.stU_BSU_ID);

        let preselectedSibling = JSON.parse(localStorage.getItem('selectedSiblings'));

        if(preselectedSibling?.length > 0){
          preselectedSibling.forEach((x:any) => {
            this.getSiblings(null, res?.data.find((y:any) => y.stU_NO == x), true);
          });
        }
        this.checkvalidation();
      }
    )
  }

  createMedicalFormGroup(): FormGroup {
    return this.formBuilder.group({
      medicalRadiobtn: new FormControl<any>(''),
      medicalDesc: new FormControl<any>(''),
      medicalRadiobtn2: new FormControl<any>(''),
      medicalDesc2: new FormControl<any>(''),
      count: new FormControl<any>(0),
      count2: new FormControl<any>(0),
      minDescErr: new FormControl<any>(false),
      minDescErr2: new FormControl<any>(false),
      promptEnable: new FormControl<any>(false)
    })
  }

  getTermsConditions(nextGradeId: any, stuBsuId: any) {

    this.reregistrationService.getTermsAndConditionsByGrade(nextGradeId, stuBsuId).subscribe((res: any) => {

      this.pdfs = res?.data
      this.pdfs.forEach(x => x.isAgree = false);
      // Specify the value to filter by


      // Filter the array based on the specified property and value
      var vtermsac = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "TNC");
      if(vtermsac.length > 0)
     {
      this.tandcfilteredDatapdf = vtermsac.map(item => item.ttcul_Url_Path);
      this.isTerms=true;
     }else this.isTerms=false;

      var vbusb = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "BBC");
      if(vbusb.length > 0)
      {
      this.BusBehaviourCodedatapdf = vbusb.map(item => item.ttcul_Url_Path);
      this.isBusBehavior=true;
      }else this.isBusBehavior=false;

      var parentUndertaking = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "PUT");
    if(parentUndertaking.length>0)
    {
      this.parentUndertakingPdf = parentUndertaking?.map(item => item?.ttcul_Url_Path);
      this.isParentTaking=true;
      // let grd = this.siblingList.find(a => a.stU_NO == localStorage.selectedstudentid).stU_NEXT_GRADE;
      // const bArray: string[] = parentUndertaking[0].ttcuL_GRD_IDS.split(",");
      // const containsA: boolean = bArray.includes(grd);
      // this.isGrade = containsA;
      // this.showParentUndertaking = containsA;
      this.showParentUndertaking = true;
    }else {
      this.isParentTaking=false;
      this.showParentUndertaking = false;
    }

    })
  }

  agree()
  {
    this.digopenParent.close();
  }

  get sanitizedPdfHtml(): SafeHtml {
    this.pdfContent=this.sanitizer.bypassSecurityTrustHtml(this.pdfHtml ?? '');
    return this.pdfContent }
  openContent(type:any,template:any)
  {
    let data:any;
    data = {
      EMIRATE: this.residentialsDetails.value?.EMIRATE,
                  HOUSE_NO: this.residentialsDetails.value?.HOUSE_NO,
                  ADDRESS: this.residentialsDetails.value?.ADDRESS,
                  TPT_TYPE: this.residentialsDetails.value?.TPT_TYPE ,
                  PICKUPAREA: this.residentialsDetails.value?.PICKUPAREA == "0"? 0:this.residentialsDetails.value?.PICKUPAREA,
                  DROPOFFAREA: this.residentialsDetails.value?.DROPOFFAREA== "0"? 0:this.residentialsDetails.value?.DROPOFFAREA,
                  PICKUPPOINT: this.residentialsDetails.value?.PICKUPPOINT,
                  DROPOFFPOINT: this.residentialsDetails.value?.DROPOFFPOINT,
                  PICKUPLOCATION: this.residentialsDetails.value?.PICKUPLOCATION,
                  DROPOFFLOCATION: this.residentialsDetails.value?.DROPOFFLOCATION,
                  PICKUPPOINTLANDMARK: this.residentialsDetails.value?.PICKUPPOINTLANDMARK,
                  DROPOFFPOINTLANDMARK: this.residentialsDetails.value?.DROPOFFPOINTLANDMARK,
                  SERVICEDATE:this.residentialsDetails.value?.SERVICEDATE,
                  picklat:this.latitude,
                  picklog:this.longitude,
                  droplat:this.latitudeDrop,
                  droplog:this.longitudeDrop,
                  pickuplocation:this.pickuplocation,
                  dropofflocation:this.dropofflocation
    }
    localStorage.setItem('residentialInfo', JSON.stringify(data));
    localStorage.setItem('medicalInfo', JSON.stringify(this.medicalConditionDetails));
    localStorage.setItem('termsForm', JSON.stringify(this.termsAgreedDetails));

    if(type == 't&c')
    {
      this.pdfHtml=this.pdfs.find(item => item.ttcuL_DOC_TYPE === "TNC").ttcuL_PDF_HTML;
      this.contentTitle= "Terms and Conditions"
    }
    else if(type == 'busbehaviour')
    {
    this.pdfHtml=this.pdfs.find(item => item.ttcuL_DOC_TYPE === "BBC").ttcuL_PDF_HTML;
    this.contentTitle= "Bus Behavior Code"
    }
    else if(type == 'parentUndertaking')
    {
      let parentUndertaking = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "PUT")
      let parentpdf:any
      if(parentUndertaking.length>1)
      {
      if(this.isGrade)
      {
       parentpdf=parentUndertaking?.find(x=> { return (x?.ttcuL_TYPE?.includes('1') && !x?.ttcuL_TYPE?.includes('10') && !x?.ttcuL_TYPE?.includes('11') && !x?.ttcuL_TYPE?.includes('12')) || (x?.ttcuL_TYPE?.includes('2') && !x?.ttcuL_TYPE?.includes('12')) || x?.ttcuL_TYPE?.includes('3') || x?.ttcuL_TYPE?.includes('KG')})
      }
      else{
        parentpdf=parentUndertaking.find(x=> { return !((x?.ttcuL_TYPE?.includes('1') && !x?.ttcuL_TYPE?.includes('10') && !x?.ttcuL_TYPE?.includes('11') && !x?.ttcuL_TYPE?.includes('12')) || (x?.ttcuL_TYPE?.includes('2') && !x?.ttcuL_TYPE?.includes('12')) || x?.ttcuL_TYPE?.includes('3') || x?.ttcuL_TYPE?.includes('KG'))})
      }
    }else parentpdf=parentUndertaking[0];
    //this.pdfHtml=this.pdfs.find(item => item.ttcul_Url_Text === "Parent Undertaking").ttcuL_PDF_HTML;
    this.pdfHtml = parentpdf?.ttcuL_PDF_HTML;
    this.contentTitle= "Parent Undertaking"
    }
    this.contentViewer = this.dialog.open(template, {
      disableClose: true,
      width: '850px',
      panelClass: ['custom-modalbox', 'rm-padding']
    });
  }
  closeContent()
  {
    this.contentViewer?.close();
  }

  checkTerms(value) {
   
    if(value =='1'){
      if (this.termsForm.value.checkedOne == false) {
        this.termsForm.controls['checkedOne'].setValue('');
        this.isAgree=false;
        localStorage.removeItem('termsAgreed');
      }
      if(this.termsForm.value.checkedOne == true ){
        this.isParentUdertakingBox=false;
        this.gotoTC(null, false)
      }
    }
   else{
    if (this.termsForm.value.checkedTwo == false) {
      this.termsForm.controls['checkedTwo'].setValue('')
    }
    if (this.termsForm.value.checkedThree == false) {
      this.termsForm.controls['checkedThree'].setValue('')
    }
    this.termsAgreedDetails.checkboxTwo = this.termsForm.value?.checkedTwo;
    this.termsAgreedDetails.checkboxThree = this.termsForm.value?.checkedThree;
   }


    this.checkvalidation()
  }

  pickupdropdata(value, type) {
    value = value.split('/');
    if (type == 'pickuparea') {
      return value[0]
    }
    if (type == 'dropoffarea') {
      return value[1]
    }
    if (type == 'pickuppoint') {
      return value[0]
    }
    if (type == 'dropoffpoint') {
      return value[1]
    }
  }
  ok()
  {
    this.digopen.close();
    this.router.navigate(['/transport-information']);
  }
  save(template){
    let data =[]
   let data1:any[]=[];
   data1 = [...this.siblingsList, this.currentUser];
   if(this.addressValue == 2){

    data1.forEach(ele=>{
      data.push(
        {
          "RET_PROVIDER_BSU_ID":ele.provideR_BSU_ID,
          "RET_STU_BSU_ID":ele.stU_BSU_ID,
          "RET_STU_ID":ele.stU_NO,
          "RET_ACD_ID":ele.stU_NEXT_ACD_ID,
          "RET_IS_REENROLL":true,
          "RET_STATUS":ele.reT_STATUS,
          "RET_NARRATION":"",
          "RET_SOURCE":"WEB",
          "RET_OUTSTANDING_AMT":"0",
          "RET_REENROLL_AMT":ele.currentfeedetails,
          "RET_CHANGE_PA_ID":this.residentialsDetails.value.PICKUPAREA,
          "RET_CHANGE_PA_PNT_ID":this.residentialsDetails.value.PICKUPPOINT,
          "RET_CHANGE_DA_ID":this.residentialsDetails.value.DROPOFFAREA,
          "RET_CHANGE_DA_PNT_ID":this.residentialsDetails.value.DROPOFFPOINT,
          "RET_CHANGE_TPT_TYPE_ID":this.residentialsDetails.value.TPT_TYPE,
          "RET_CHANGE_LAT":this.latitude +','+this.latitudeDrop,
          "RET_CHANGE_LONG":this.longitude +','+this.longitudeDrop,
          "RET_ADD_INFO":this.residentialsDetails.value.HOUSE_NO+', '+this.residentialsDetails.value.ADDRESS,
          "RET_MAKANI":"",
          "RET_REASON_REMARKS":"",
          "RET_SERVICE_DATE":this.residentialsDetails.value.SERVICEDATE,
          "RFT_MED": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalRadiobtn?.value == '1',
          "RFT_MEDICAL_CONDITION": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalDesc?.value,
          "RFT_MED_STAFF_REQUIRED": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalRadiobtn2?.value == '1',
          "RFT_MED_STAFF_REQUIRED_DESC": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalDesc2?.value,
          "RFT_DA_LANDMARK": this.residentialsDetails.value.DROPOFFPOINTLANDMARK,
          "RFT_PA_LANDMARK": this.residentialsDetails.value.PICKUPPOINTLANDMARK
      }
      )

    })
   }else{
    data1.forEach(ele=>{
      data.push(
        {
          "RET_PROVIDER_BSU_ID":ele.provideR_BSU_ID,
          "RET_STU_BSU_ID":ele.stU_BSU_ID,
          "RET_STU_ID":ele.stU_NO,
          "RET_ACD_ID":ele.stU_NEXT_ACD_ID,
          "RET_IS_REENROLL":true,
          "RET_STATUS":ele.reT_STATUS,
          "RET_NARRATION":"",
          "RET_SOURCE":"WEB",
          "RET_OUTSTANDING_AMT":"0",
          "RET_REENROLL_AMT":ele.currentfeedetails,
          "RET_CHANGE_PA_ID":ele.reT_CHANGE_PA_ID,
          "RET_CHANGE_PA_PNT_ID":0,
          "RET_CHANGE_DA_ID":ele.reT_CHANGE_DA_ID,
          "RET_CHANGE_DA_PNT_ID":0,
          "RET_CHANGE_TPT_TYPE_ID":ele.reT_ROUNDTRIP,
          "RET_CHANGE_LAT":ele.reT_LAT,
          "RET_CHANGE_LONG":ele.reT_LONG,
          "RET_ADD_INFO":"",
          "RET_MAKANI":"",
          "RET_REASON_REMARKS":"",
          "RET_SERVICE_DATE":this.reT_BLOCKSSDATE,
          "RFT_MED": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalRadiobtn?.value == '1',
          "RFT_MEDICAL_CONDITION": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalDesc?.value,
          "RFT_MED_STAFF_REQUIRED": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalRadiobtn2?.value == '1',
          "RFT_MED_STAFF_REQUIRED_DESC": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalDesc2?.value
      }
      )
    })
   }
    this.reregistrationService.saveReregistration(data).subscribe(
      (res:any)=>{
        if(res?.success == "true")
        {
        this.referenceNo=res?.message
         this.successDialog(template)
         let payload={
          RET_PARENT_ID:res?.message,
          RET_STATUS:'SUCCESS',
          SOURCE: this.addressValue == 2 ? 'DIFFAREA_WEB' : 'WEB',
          RET_PAYMENT_REF:this.PaymentRefNo ? this.PaymentRefNo : ''
         }
         this.reregistrationService.updateStatus(payload).subscribe(res=>{
         })
        }else{
          this.openMessageDialog(res?.message,true,'');
          this.router.navigate(['/transport-information']);
        }
      }

    )
  }

  // checkpostdetails(data,template:TemplateRef<any>,template1:TemplateRef<any>, template2:TemplateRef<any>){
  //   if(this.currentUser.enableprocesszero == 0 && this.currentUser.currentfeedetails <= 0){
  //     //this.showError = true;
  //     this.openErrorConfirm(template2)
  //     this.errorMessage='Please Contact the service provider for the Registration.'
  //   }else{
  //     this.showError = false
  //     this.openConfirm(template, template1)
  //   }
  // }

  openConfirm(template:TemplateRef<any>,template1:TemplateRef<any>){
    // this.dialogOpen = this.dialog.open(template, {
    //   width: '600px',
    //   panelClass: 'custom-modalbox'
    // });
    //this.save()

    this.PostFeeDetails(template1)


  }
  // openErrorConfirm(template:TemplateRef<any>,){
  //   this.ErrordialogOpen = this.dialog.open(template, {
  //     width: '600px',
  //     panelClass: 'custom-modalbox'
  //   });
  //   //this.save()
  // }
  changeServiceDate(event){
    let serviceDate = event.value._d;
   this.residentialsDetails.controls['SERVICEDATE'].setValue(this.datePipe.transform(serviceDate, 'yyyy-MM-dd'));
  }
  checkParentUndertaking(templateRef: TemplateRef<any>, event,value, isSibling){

    value.parentUndertaking = event.checked;
    this.parentUndertakingFile = value.termS_UNDERTAKING_URL

    if(event.checked == false){
      if(isSibling){
        localStorage.removeItem('siblingPUTAgreed');
      }else{
        localStorage.removeItem('PUTAgreed');
      }
    }

    if(event.checked == true){
     // this.parentUndertakingDialog(templateRef, value)
     this.isParentUdertakingBox=true;
     this.gotoTC(value, isSibling);
    }else{
      this.checkvalidation()
    }
    let data :any=[];
    data = [this.currentUser,...this.siblingsList ];
    localStorage.setItem('reregisterUsers', JSON.stringify(data))

  }
  downloadPdf()
  {
    //this.registrationService.DownloadPDF(this.token,this.siblingPDF)
    this.pdfservice.downloadPdf(this.token,this.siblingPDF,this.pdfName);
   //this.http.get(this.pdfSrc, { responseType: 'blob' }).subscribe((blob: Blob) => {
    //  const blob = new Blob([this.siblingPDF], { type: 'application/pdf' });
    //  const link = document.createElement('a');
    //  link.href = window.URL.createObjectURL(blob);
    //  link.download = this.pdfName;
    //  link.click();
  // });

 }

//  pdfDownload()
//  {
//    var html = htmlToPdfmake(this.pdf.nativeElement.innerHTML);
//    const documentDefinition = { content: html };
//    pdfMake.createPdf(documentDefinition).download(this.pdfName);
//  }

 gotoTC(studentData: any, isSibling: any){
  if(this.addressValue == 2){
    let data:any;
    data = {
      EMIRATE: this.residentialsDetails.value?.EMIRATE,
                  HOUSE_NO: this.residentialsDetails.value?.HOUSE_NO,
                  ADDRESS: this.residentialsDetails.value?.ADDRESS,
                  TPT_TYPE: this.residentialsDetails.value?.TPT_TYPE ,
                  PICKUPAREA: this.residentialsDetails.value?.PICKUPAREA == "0"? 0:this.residentialsDetails.value?.PICKUPAREA,
                  DROPOFFAREA: this.residentialsDetails.value?.DROPOFFAREA== "0"? 0:this.residentialsDetails.value?.DROPOFFAREA,
                  PICKUPPOINT: this.residentialsDetails.value?.PICKUPPOINT,
                  DROPOFFPOINT: this.residentialsDetails.value?.DROPOFFPOINT,
                  PICKUPLOCATION: this.residentialsDetails.value?.PICKUPLOCATION,
                  DROPOFFLOCATION: this.residentialsDetails.value?.DROPOFFLOCATION,
                  PICKUPPOINTLANDMARK: this.residentialsDetails.value?.PICKUPPOINTLANDMARK,
                  DROPOFFPOINTLANDMARK: this.residentialsDetails.value?.DROPOFFPOINTLANDMARK,
                  SERVICEDATE:this.residentialsDetails.value?.SERVICEDATE,
                  picklat:this.latitude,
                  picklog:this.longitude,
                  droplat:this.latitudeDrop,
                  droplog:this.longitudeDrop,
                  pickuplocation:this.pickuplocation,
                  dropofflocation:this.dropofflocation
    }
    localStorage.setItem('residentialInfo', JSON.stringify(data));
  }
  localStorage.setItem('medicalInfo', JSON.stringify(this.medicalConditionDetails));
  localStorage.setItem('termsForm', JSON.stringify(this.termsAgreedDetails));
  if(!this.isParentUdertakingBox)
    this.router.navigate(['/re-register-terms-and-conditions'],{queryParams:{type:"All"}})
 else{
  this.router.navigate(['/re-register-terms-and-conditions'],{queryParams:{type:"PUT", gradeId:studentData?.stU_NEXT_GRADE, studentId: studentData?.stU_NO, isSibling: isSibling}})
 }
   }
   getSchoolsList(){
    this.reregistrationService.getSchoolList().subscribe(
      res=>{

        let schooldetails:any;
        let currentUser:any;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));

schooldetails= res?.filter(x=>x.bsU_ID == currentUser.schooId)
this.common.getIPAddress().subscribe(
  (res: any) => {localStorage.setItem('IPAddress', res?.ip)

}
)
        localStorage.setItem('schoolList',JSON.stringify(schooldetails));

      }
    )
   }

   PostFeeDetails(template:TemplateRef<any>){
    let data :any[]=[];
    this.ConfirmStudentlist=[]
    this.ConfirmStudentlist = [this.currentUser,...this.siblingsList, ]
    this.reregistrationService.getFeeDetails(this.currentUser.stU_NO).subscribe(
      res=>{
        this.feedetailsData = res?.data[0];
        let obj = {
          headers: {
            "STUID": this.currentUser.stU_NO,
            "SOURCE": this.configService.source,
            "paymentOrigin": this.configService.paymentreenrollOrigin

          }
        }
        this.reregistrationService.getPaymentType(obj).subscribe((res:any)=>{
          this.paymentTypeID = res?.data?.filter(x=>x.providerTypeID == '2')[0].paymentTypeID;
          let data1:any[]=[];
          data1 = [...this.siblingsList, this.currentUser];
          data1.forEach(ele=>{
            let feedetail:any[]=[];
            this.feedetailsData.feeDetail.forEach(ele1 => {
              feedetail.push({ "blockPayNow": ele1.blockPayNow, "advancePaymentAvailable": ele1.advancePaymentAvailable, "advanceDetails": ele1.advanceDetails, "discAmount": 0, "dueAmount": ele.currentfeedetails, "payAmount": ele.currentfeedetails, "originalAmount": ele.currentfeedetails, "feeDescription": ele1.feeDescription, "feeID": ele1.feeID, "activityRefID": ele1.activityRefID, "activityFeeCollType": ele1.activityFeeCollType })
            })
           data.push({"stU_NO":ele.stU_NO,"onlinePaymentAllowed":this.feedetailsData.onlinePaymentAllowed,"userMessageforOnlinePaymentBlock":this.feedetailsData.userMessageforOnlinePaymentBlock,"ipAddress":localStorage.getItem('IPAddress'),"paymentTypeID":this.paymentTypeID,"paymentProcessingCharge":this.feedetailsData.paymentProcessingCharge,"payingAmount":ele.currentfeedetails,"payMode":"","feeDetail":feedetail,"discountDetails":[]})
          })



               this.reregistrationService.postFeeDetails(data).subscribe(
                 (res: any) => {
                   if (res.Response.Success == "true") {
                     this.PaymentRefNo = res?.PaymentRefNo;
                     localStorage.setItem("postPaymentRefNo", res.PaymentRefNo);



                     this.PaymentRedirectURL = res.PaymentRedirectURL;

                     this.gotoPayment(template);
                   } else {
                     this.openMessageDialog(res.Response.Message, true, '');
                   }

                 }
           )
        })

      }
    )

   }

   gotoPayment(template:TemplateRef<any>){

    if(this.PaymentRefNo == 0 || this.addressValue == 2){
      this.save(template)
     }else{
        this.saveMulti(template);
        setTimeout(() => {
      window.open(this.PaymentRedirectURL, '_self')
    }, 1000);
     }

   }
   saveMulti(template){
    let data =[]
   let data1:any[]=[];
   data1 = [...this.siblingsList, this.currentUser];
   if(this.addressValue == 2){

    data1.forEach(ele=>{
      data.push(
        {
          "RET_PROVIDER_BSU_ID":ele.provideR_BSU_ID,
          "RET_STU_BSU_ID":ele.stU_BSU_ID,
          "RET_STU_ID":ele.stU_NO,
          "RET_ACD_ID":ele.stU_NEXT_ACD_ID,
          "RET_IS_REENROLL":true,
          "RET_STATUS":ele.reT_STATUS,
          "RET_NARRATION":"",
          "RET_SOURCE":"WEB",
          "RET_OUTSTANDING_AMT":"0",
          "RET_REENROLL_AMT":ele.currentfeedetails,
          "RET_CHANGE_PA_ID":this.residentialsDetails.value.PICKUPAREA,
          "RET_CHANGE_PA_PNT_ID":this.residentialsDetails.value.PICKUPPOINT,
          "RET_CHANGE_DA_ID":this.residentialsDetails.value.DROPOFFAREA,
          "RET_CHANGE_DA_PNT_ID":this.residentialsDetails.value.DROPOFFPOINT,
          "RET_CHANGE_TPT_TYPE_ID":this.residentialsDetails.value.TPT_TYPE,
          "RET_CHANGE_LAT":this.latitude +','+this.latitudeDrop,
          "RET_CHANGE_LONG":this.longitude +','+this.longitudeDrop,
          "RET_ADD_INFO":this.residentialsDetails.value.HOUSE_NO+', '+this.residentialsDetails.value.ADDRESS,
          "RET_MAKANI":"",
          "RET_REASON_REMARKS":"",
          "RET_SERVICE_DATE":this.residentialsDetails.value.SERVICEDATE,
          "RFT_MED": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalRadiobtn?.value == '1',
          "RFT_MEDICAL_CONDITION": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalDesc?.value,
          "RFT_MED_STAFF_REQUIRED": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalRadiobtn2?.value == '1',
          "RFT_MED_STAFF_REQUIRED_DESC": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalDesc2?.value,
          "RFT_DA_LANDMARK": this.residentialsDetails.value.DROPOFFPOINTLANDMARK,
          "RFT_PA_LANDMARK": this.residentialsDetails.value.PICKUPPOINTLANDMARK

      }
      )

    })
   }else{
    data1.forEach(ele=>{
      data.push(
        {
          "RET_PROVIDER_BSU_ID":ele.provideR_BSU_ID,
          "RET_STU_BSU_ID":ele.stU_BSU_ID,
          "RET_STU_ID":ele.stU_NO,
          "RET_ACD_ID":ele.stU_NEXT_ACD_ID,
          "RET_IS_REENROLL":true,
          "RET_STATUS":ele.reT_STATUS,
          "RET_NARRATION":"",
          "RET_SOURCE":"WEB",
          "RET_OUTSTANDING_AMT":"0",
          "RET_REENROLL_AMT":ele.currentfeedetails,
          "RET_CHANGE_PA_ID":ele.reT_CHANGE_PA_ID,
          "RET_CHANGE_PA_PNT_ID":0,
          "RET_CHANGE_DA_ID":ele.reT_CHANGE_DA_ID,
          "RET_CHANGE_DA_PNT_ID":0,
          "RET_CHANGE_TPT_TYPE_ID":ele.reT_ROUNDTRIP,
          "RET_CHANGE_LAT":ele.reT_LAT,
          "RET_CHANGE_LONG":ele.reT_LONG,
          "RET_ADD_INFO":"",
          "RET_MAKANI":"",
          "RET_REASON_REMARKS":"",
          "RET_SERVICE_DATE":this.reT_BLOCKSSDATE,
          "RFT_MED": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalRadiobtn?.value == '1',
          "RFT_MEDICAL_CONDITION": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalDesc?.value,
          "RFT_MED_STAFF_REQUIRED": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalRadiobtn2?.value == '1',
          "RFT_MED_STAFF_REQUIRED_DESC": this.medicalConditionForm[ele.stU_NO]?.controls?.medicalDesc2?.value

      }
      )
    })
   }
    this.reregistrationService.saveReregistration(data).subscribe(
      (res:any)=>{
        if(res?.success == "true")
        {
        this.referenceNo=res?.message

         let payload={
          RET_PARENT_ID:res?.message,
          RET_STATUS:'SUCCESS',
          SOURCE: this.addressValue == 2 ? 'DIFFAREA_WEB' : 'WEB',
          RET_PAYMENT_REF:this.PaymentRefNo ? this.PaymentRefNo : ''
         }
        //  this.reregistrationService.updateStatus(payload).subscribe(res=>{
        //  })
      }else{
        this.openMessageDialog(res?.message,true,'');
        this.router.navigate(['/transport-information']);
      }
    }

    )
  }
  checkvalidation() {
    let data = [];
    data = [this.currentUser, ...this.siblingsList];
    let isparentundertaking: any = [];
    isparentundertaking = data.filter(x => x.parentUndertaking == false);
    if (isparentundertaking.length > 0) {
      this.isparentUndertakingChecked = true
    } else {
      this.isparentUndertakingChecked = false
    }

    if ((this.termsForm.value.checkedOne && this.termsForm.value.checkedTwo && this.termsForm.value.checkedThree) || (!this.termsForm.value.checkedOne && !this.isTerms && !this.isBusBehavior && this.termsForm.value.checkedTwo && this.termsForm.value.checkedThree)) {
      if (this.isparentUndertakingChecked && this.city == 'Abu Dhabi' && this.isParentTaking && this.showParentUndertaking)
        this.disableSave = true;
      else this.disableSave = false;
    }
    else this.disableSave = true;
  }
checkSelectedPoint(value,event: MatSelectChange){
  if(this.addressValue==2)
  {
   localStorage.setItem("selecteddropoffarea",event.source.triggerValue);
  }
  if(value == 'pickuppoint'){
    this.selectedpickuppoint = this.residentialsDetails.value.PICKUPPOINT
  }else{
    this.selecteddropoffpoint = this.residentialsDetails.value.DROPOFFPOINT

  }
  console.log(this.selectedpickuppoint,this.selecteddropoffpoint);
  this.residentialsDetails.controls['SERVICEDATE'].setValue(this.datePipe.transform(this.currentUser.reT_SSDATE, 'yyyy-MM-dd'))
  this.residentialsDetails.controls['SERVICEDATE'].markAsDirty();
  this.residentialsDetails.controls['SERVICEDATE'].markAsTouched();
  this.residentialsDetails.controls['SERVICEDATE'].markAsPristine();
  this.getOthersDetails()
}
PickupgetAddress(latitude, longitude) {
  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        this.zoom = 20;

          this.pickuplocation = results[0].formatted_address

       // window.alert(results[0].formatted_address);
      } else {
      //  window.alert('No results found');
      }
    } else {
     // window.alert('Geocoder failed due to: ' + status);
    }

  });

}
DropoffgetAddress(latitude, longitude) {
  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        this.zoom = 20;

          this.dropofflocation = results[0].formatted_address

      //  window.alert(results[0].formatted_address);
      } else {
        //window.alert('No results found');
      }
    } else {
      //window.alert('Geocoder failed due to: ' + status);
    }

  });

}
onInputChange() {
  if (this.searchInput.length >= 3) {
    this.autocompleteService.getPlacePredictions(this.searchInput).subscribe(predictions => {
      this.predictions = predictions;
    });
  } else {
    this.predictions = [];
  }
}
onPredictionClick(prediction: google.maps.places.AutocompletePrediction) {
 const geocoder = new google.maps.Geocoder();
 if(this.mapOpenedFrom=='pick'){
  this.pickuplocation = prediction.description
 } else{
  this.dropofflocation = prediction.description
 }
 geocoder.geocode({ placeId: prediction.place_id }, (results, status) => {
         if (status === google.maps.GeocoderStatus.OK) {
          // Update the map center and clear existing markers
          if(this.mapOpenedFrom == 'pick'){
            this.center1 = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
            this.markers = [];
            // Add a marker for the selected location
            this.markers.push({position: this.center1,label: '',title: prediction.description,options: { animation: google.maps.Animation.DROP
           }
           });

           this.predictions=[];
          } else{
            this.center2 = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
            this.markers1 = [];
            // Add a marker for the selected location
            this.markers1.push({position: this.center2,label: '',title: prediction.description,options: { animation: google.maps.Animation.DROP
           }
           });
           this.predictions=[];

          }

      }
    });
  }

  onEmiratesChange() {
    let cityId = this.residentialsDetails.value.EMIRATE;

    this.latitudeDrop = 0;
    this.longitudeDrop = 0;
    this.residentialsDetails.patchValue({
      PICKUPAREA: '',
      DROPOFFAREA: '',
      PICKUPPOINT: '',
      DROPOFFPOINT: '',
      PICKUPLOCATION: '',
      DROPOFFLOCATION: '',
      PICKUPPOINTLANDMARK: '',
      DROPOFFPOINTLANDMARK: ''
    });
    this.selectedpickuparea1 = null;
    this.selecteddropofarea2 = null;
    this.latitude = 0;
    this.longitude = 0;
    this.latitudeDrop = 0;
    this.longitudeDrop = 0;
    this.getAreaBasedOnCity(cityId);
  }

  getAreaBasedOnCity(cityId:any) {
    if(cityId != null && cityId != undefined && cityId != ''){
      let obj: any = {
        headers: {
          "master": "AREA_M_TPT_RR",
          "param1": cityId.toString(),
          "bsuid": this.bsuId,
          "acdid": this.aceId.toString() ?? '0',
          "Authorization": "Bearer " + this.token
        }
      }

      this.serviceinfoService.getMaster(obj).subscribe(res => {

        this.pickuppoints = res["data"];
        this.dropoffpoints = res["data"];
        this.pickupPointList = res["data"];
        this.pickuppoints?.unshift({
          descr: 'Select',
          id: null
        })
      });
    }
  }

  onChangeRadio(formId: any)
  {
    this.medicalConditionExists = false;
    this.medicalConditionForm[formId].patchValue({
      medicalDesc:"",
      minDescErr: false,
      medicalRadiobtn2: '2',
      medicalDesc2: "",
      minDescErr2: false,
      promptEnable: false
    });

    if (this.medicalConditionForm[formId].controls.medicalRadiobtn.value == '1') {
      this.medicalConditionExists = true;
      this.medicalConditionForm[formId].controls.medicalDesc.clearValidators();
      this.medicalConditionForm[formId].controls.medicalDesc.setValidators([Validators.required]);
      this.medicalConditionForm[formId].controls.medicalDesc.markAsUntouched();
      this.medicalConditionForm[formId].controls.medicalDesc.updateValueAndValidity();
      this.setMedicalForm(formId);
    } else {
      this.medicalConditionForm[formId].controls.medicalDesc.clearValidators();
      let Index = this.medicalConditionDetails.findIndex(x => x.STU_ID == formId);
      this.medicalConditionDetails.splice(Index, 1);
    }
    this.ismedicalFormInValid(formId);
  }

  onChangeRadio2(formId: any)
  {
    this.medicalConditionForm[formId].patchValue({
      medicalDesc2: "",
      minDescErr2: false,
      promptEnable: false
    });

    if (this.medicalConditionForm[formId].controls.medicalRadiobtn2.value == '1') {
      this.medicalConditionForm[formId].controls.medicalDesc2.clearValidators();
      this.medicalConditionForm[formId].controls.medicalDesc2.setValidators([Validators.required]);
      this.medicalConditionForm[formId].controls.medicalDesc2.markAsUntouched();
      this.medicalConditionForm[formId].controls.medicalDesc2.updateValueAndValidity();
    } else {
      this.medicalConditionForm[formId].controls.medicalDesc2.clearValidators();
    }
    this.ismedicalFormInValid(formId);
  }

  onDescChange(formId: any) {
    if (this.medicalConditionForm[formId].controls.medicalDesc.value && this.medicalConditionForm[formId].controls.medicalDesc.value.trim() != "") {
      this.medicalConditionForm[formId].patchValue({
        count: this.medicalConditionForm[formId].controls.medicalDesc.value.length
      });

      if (this.medicalConditionForm[formId].controls.count.value <= 14) {
        this.medicalConditionForm[formId].patchValue({
          minDescErr: true
        });
      } else {
        this.medicalConditionForm[formId].patchValue({
          minDescErr: false
        });

        this.setMedicalForm(formId);
      }
    }
    else {
      this.medicalConditionForm[formId].patchValue({
        count: 0,
        minDescErr: false,
        medicalDesc: this.medicalConditionForm[formId].controls.medicalDesc.value?.trim() == "" ? "" : this.medicalConditionForm[formId].controls.medicalDesc.value
      });
    }
    this.ismedicalFormInValid(formId);
  }

  onDescChange2(formId: any) {
    if (this.medicalConditionForm[formId].controls.medicalDesc2.value && this.medicalConditionForm[formId].controls.medicalDesc2.value.trim() != "") {
      this.medicalConditionForm[formId].patchValue({
        count2: this.medicalConditionForm[formId].controls.medicalDesc2.value.length
      });

      if (this.medicalConditionForm[formId].controls.count2.value <= 14) {
        this.medicalConditionForm[formId].patchValue({
          minDescErr2: true,
          promptEnable: false
        });
      } else {
        this.medicalConditionForm[formId].patchValue({
          minDescErr2: false,
          promptEnable: true
        });

        this.setMedicalForm(formId);
      }
    }
    else {
      this.medicalConditionForm[formId].patchValue({
        count2: 0,
        minDescErr2: false,
        promptEnable: false,
        medicalDesc2: this.medicalConditionForm[formId].controls.medicalDesc2.value?.trim() == "" ? "" : this.medicalConditionForm[formId].controls.medicalDesc2.value
      });
    }
    this.ismedicalFormInValid(formId);
  }

  setMedicalForm(formId: any) {
    let Index = this.medicalConditionDetails.findIndex(x => x.STU_ID == formId)
    if (Index != -1) {
      this.medicalConditionDetails.forEach((x: any) => {
        if (x.STU_ID == formId) {
          x.medicalRadiobtn = this.medicalConditionForm[formId].controls.medicalRadiobtn.value,
            x.medicalDesc = this.medicalConditionForm[formId].controls.medicalDesc.value,
            x.medicalRadiobtn2 = this.medicalConditionForm[formId].controls.medicalRadiobtn2.value,
            x.medicalDesc2 = this.medicalConditionForm[formId].controls.medicalDesc2.value
        }
      });
    } else {
      this.medicalConditionDetails.push({
        medicalRadiobtn: this.medicalConditionForm[formId].controls.medicalRadiobtn.value,
        medicalDesc: this.medicalConditionForm[formId].controls.medicalDesc.value,
        medicalRadiobtn2: this.medicalConditionForm[formId].controls.medicalRadiobtn2.value,
        medicalDesc2: this.medicalConditionForm[formId].controls.medicalDesc2.value,
        STU_ID: formId
      });
    }
  }

  ismedicalFormInValid(formId:any) {
    if((this.medicalConditionForm[formId].controls.medicalRadiobtn.value == '1' && this.medicalConditionForm[formId].controls.medicalDesc.value.length < 15)
      || (this.medicalConditionForm[formId].controls.medicalRadiobtn2.value == '1' && this.medicalConditionForm[formId].controls.medicalDesc2.value.length < 15)){
      this.medicalFormInValid = true;
    }else{
      this.medicalFormInValid = false;
    }
  }

  openSiblingContent(type:any,template:any)
  {
    let parentUndertaking = this.siblingParentUndertakings.filter(item => item.ttcuL_DOC_TYPE === "PUT")
    let parentpdf: any
    if (parentUndertaking.length > 1) {
      if (this.isGrade) {
        parentpdf = parentUndertaking?.find(x => { return (x?.ttcuL_TYPE?.includes('1') && !x?.ttcuL_TYPE?.includes('10') && !x?.ttcuL_TYPE?.includes('11') && !x?.ttcuL_TYPE?.includes('12')) || (x?.ttcuL_TYPE?.includes('2') && !x?.ttcuL_TYPE?.includes('12')) || x?.ttcuL_TYPE?.includes('3') || x?.ttcuL_TYPE?.includes('KG') })
      }
      else {
        parentpdf = parentUndertaking.find(x => { return !((x?.ttcuL_TYPE?.includes('1') && !x?.ttcuL_TYPE?.includes('10') && !x?.ttcuL_TYPE?.includes('11') && !x?.ttcuL_TYPE?.includes('12')) || (x?.ttcuL_TYPE?.includes('2') && !x?.ttcuL_TYPE?.includes('12')) || x?.ttcuL_TYPE?.includes('3') || x?.ttcuL_TYPE?.includes('KG')) })
      }
    } else parentpdf = parentUndertaking[0];
    this.pdfHtml = parentpdf?.ttcuL_PDF_HTML;
    this.contentTitle= "Parent Undertaking"

    this.contentViewer = this.dialog.open(template, {
      disableClose: true,
      width: '850px',
      panelClass: ['custom-modalbox', 'rm-padding']
    });
  }

  openuaeDialog(template: TemplateRef<any>, value: any) {
    this.uaePasscontentViewer= this.dialog.open(template, {
       disableClose: true,
       width: '1040px',
       panelClass: ['custom-modalbox','uae-popup']
     });
   }
   closeuaePasscontentViewer()
   {
     this.uaePasscontentViewer?.close();
   }
   updateCarousel() {
    if (this.owlElement) {
      this.carouselVisible = false;
      setTimeout(() => {
        this.carouselVisible = true;
      }, 0)
    }
  }
  signinwithuaepass()
  {
localStorage.setItem("fromReregister","true");
localStorage.setItem("reRegisterCurrentUser",this.currentUser);
    window.location.href=this.configService.uaepass_authorize_uri;
  }
  downloadsigneddocuments()
  {
    window.location.href=this.docdownloadurl;
  }
   carouselOptions: OwlOptions = {
    loop: false,
    items: 3,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    margin: 20,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      400: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      740: {
        items: 2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      940: {
        items: 3,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      }
    },
    nav: false
  };



}

