import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ServiceinfoService } from 'src/app/service';
import { areaChangeService } from 'src/app/service/areaChange.service';
import { GoogleMapsAutocompleteService } from 'src/app/service/google-maps-autocomplete-service.service';
import { ReregistrationService } from 'src/app/service/reregistration.service';

@Component({
  selector: 'app-request-area-change-info',
  templateUrl: './request-area-change-info.component.html',
  styleUrls: ['./request-area-change-info.component.scss']
})
export class RequestAreaChangeInfoComponent implements OnInit {
  digopen: any;
  changeResidentialForm: { [key: string]: FormGroup } = {};
  // currentResidentialForm=new FormGroup<any>({
  //   studentName:new FormControl<any>('',[Validators.required]),
  //   currentBus:new FormControl<any>('',[Validators.required]),
  //   currentPickup:new FormControl<any>('',[Validators.required]),
  //   currentDropoff:new FormControl<any>('',[Validators.required]),
  // })
  studentDetails: any;
  dropoffPoint: any[]=[];
  pickupPoint: any[]=[];
  dropoffPointTemp: any[]=[];
  pickupPointTemp: any[]=[];
  transportTypes: any[]=[];
  city: any[]=[];
  dropoffArea: any[]=[];
  pickupArea: any[]=[];
  dropoffAreaTemp: any[]=[];
  pickupAreaTemp: any[]=[];
  token: any;
  //isPickup: boolean=false;
  //isDropoff: boolean=false;
  minDate:Date=new Date(new Date().setDate(new Date().getDate() + 1));
  siblingList: any[]=[];
  selectedChild: any;
  //isPickupOther: boolean =false;
  //isDropoffOther: boolean=false;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  mapZoom = 12;
  mapCenter: google.maps.LatLng;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
  };
  markerInfoContent = '';
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,
  };
  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;
  zoom = 20;
  maxZoom = 20;
  minZoom = 3;
  // latitudePickup: number = 0;
  // longitudePickup: number = 0;
  // latitudeDropoff: number = 0;
  // longitudeDropoff: number = 0;
   pickCenter!: google.maps.LatLngLiteral;
   dropCenter!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'hybrid',
    maxZoom: this.maxZoom,
    minZoom: this.minZoom,
  }
  // pickupMarker:any[] = [];
  // dropoffMarker:any[] = [];
  infoContent = ''
  mapSearch: any;
  predictions: any[]=[];
  //mapOpenedFrom: string;
  //pickupLocation: string ='';
  //dropoffLocation: string='';
  geoCoder: google.maps.Geocoder;
  referenceNo: any;
  formIndex=0
  residentialForms:any[]=[];
  MapFormIndex: any;
  selectedStudent: any;
  isFormValid:boolean =false
  existingMapInfo: any;
  ishybrid: any;
  isLeased: any;
  tptFee: any[] = [];
  selectedpickuparea: string = "";
  selecteddropoffarea: string = "";
  selectedpickuparea1: any = '';
  selecteddropofarea2: any = '';
  IsCloseFee: boolean = false;
  bsuCurrency: string = "";

  constructor(private dialog: MatDialog, public serviceInfo:ServiceinfoService, public reregistrationService:ReregistrationService,
    private autocompleteService:GoogleMapsAutocompleteService,private router : Router, public areaChangeService:areaChangeService,
    public datePipe : DatePipe,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    let data:any = localStorage.getItem('leasemodelschool')
    this.isLeased = data;
    let isHybrid:any = localStorage.getItem('IsHybrid')
    this.ishybrid = isHybrid;
    this.bsuCurrency = localStorage.getItem('bsu_currency');

    let schoolDetails = JSON.parse(localStorage.getItem('schoolDetails'));
    let transportInfo =JSON.parse(localStorage.getItem("transportChildInfo"));
    this.selectedChild =JSON.parse(localStorage.getItem("parentChildrens"))[0];
     this.selectedStudent =Number(localStorage.getItem("selectedstudentid"));
     this.geoCoder = new google.maps.Geocoder;
    this.studentDetails = transportInfo.find(x=>x.studentNo == this.selectedStudent);
    this.formIndex=this.selectedStudent
    this.changeResidentialForm[this.formIndex]=this.createFormGroup();
    this.changeResidentialForm[this.formIndex].patchValue({
      studentName:this.studentDetails?.studentName,
      currentBus:this.studentDetails?.pickupBusNo,
      currentPickup:this.studentDetails?.pickupLocation+' '+this.studentDetails?.pickuppoint,
      currentDropoff:this.studentDetails?.dropOffLocation+' '+this.studentDetails?.dropoffpoint,
      photopath:this.studentDetails?.photoPath
    })
     this.setResidentialForms(this.formIndex,true)
    this.getMasterDetails();
    this.getSiblings();

    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 14);
    let schoolStartDate = new Date("2025-04-07");

    if (schoolDetails?.bsu_city?.toLowerCase() == "wkr") {
      schoolStartDate = new Date("2024-09-01");
    }

    if (currentDate < schoolStartDate) {
      this.minDate = schoolStartDate;
    }else{
      this.minDate = currentDate;
    }
  }

  createFormGroup():FormGroup
  {
   return this.formBuilder.group({
      studentName:new FormControl<any>('',[Validators.required]),
      currentBus:new FormControl<any>('',[Validators.required]),
      currentPickup:new FormControl<any>('',[Validators.required]),
      currentDropoff:new FormControl<any>('',[Validators.required]),
      photopath:new FormControl<any>('',[Validators.required]),
      city:new FormControl<any>('',[Validators.required]),
      house:new FormControl<any>('',[Validators.required]),
      street:new FormControl<any>('',[Validators.required]),
      tptType:new FormControl<any>('',[Validators.required]),
      pickupArea:new FormControl<any>(''),
      dropoffArea:new FormControl<any>(''),
      pickupPoint:new FormControl<any>(''),
      dropoffPoint:new FormControl<any>(''),
      pickupLocation:new FormControl<any>(''),
      dropoffLocation:new FormControl<any>(''),
      pickupLandmark:new FormControl<any>(''),
      dropoffLandmark:new FormControl<any>(''),
      changeLocDate:new FormControl<any>('',[Validators.required]),
      latitudePickup: new FormControl<any>(0),
      longitudePickup: new FormControl<any>(0),
      latitudeDropoff: new FormControl<any>(0),
      longitudeDropoff: new FormControl<any>(0),
      isPickup: new FormControl<any>(false),
      isDropoff: new FormControl<any>(false),
      isPickupOther: new FormControl<any>(false),
      isDropoffOther: new FormControl<any>(false),
      pickupMarker:new FormControl<any>([]),
      dropoffMarker:new FormControl<any>([]),
      pickupCenter:new FormControl<any>(this.pickCenter),
      dropoffCenter:new FormControl<any>(this.dropCenter),
      mapOpenedFrom:new FormControl<any>(''),
      pickupAreaMaster:new FormControl<any>(''),
      dropOffAreaMaster:new FormControl<any>(''),
      pickupPointMaster:new FormControl<any>(''),
      dropoffPointMaster:new FormControl<any>(''),
      pickupAreaMasterTemp:new FormControl<any>(''),
      dropOffAreaMasterTemp:new FormControl<any>(''),
      pickupPointMasterTemp:new FormControl<any>(''),
      dropoffPointMasterTemp:new FormControl<any>(''),
      pickupConfirmed: new FormControl<any>(false),
      dropoffConfirmed: new FormControl<any>(false)
    })
  }

  setFormValues(formId:any,sibling:any)
  {
    let index=this.selectedStudent
    let children = JSON.parse(localStorage.getItem("transportChildInfo"))
   let child =children.find(x=>x.studentNo == sibling.stU_NO) 
    this.changeResidentialForm[formId].patchValue({
      studentName:child?.studentName,
      currentBus:child?.pickupBusNo,
      currentPickup:child?.pickupLocation+' '+child?.pickuppoint,
      currentDropoff:child?.dropOffLocation+' '+child?.dropoffpoint,
      photopath:child?.photoPath,
      city:this.changeResidentialForm[index].controls.city.value,
      house:this.changeResidentialForm[index].controls.house.value,
      street:this.changeResidentialForm[index].controls.street.value,
      tptType:this.changeResidentialForm[index].controls.tptType.value,
      pickupArea:this.changeResidentialForm[index].controls.pickupArea.value,
      dropoffArea:this.changeResidentialForm[index].controls.dropoffArea.value,
      pickupPoint:this.changeResidentialForm[index].controls.pickupPoint.value,
      dropoffPoint:this.changeResidentialForm[index].controls.dropoffPoint.value,
      pickupLocation:this.changeResidentialForm[index].controls.pickupLocation.value,
      dropoffLocation:this.changeResidentialForm[index].controls.dropoffLocation.value,
      pickupLandmark:this.changeResidentialForm[index].controls.pickupLandmark.value,
      dropoffLandmark:this.changeResidentialForm[index].controls.dropoffLandmark.value,
      changeLocDate:this.changeResidentialForm[index].controls.changeLocDate.value,
      latitudePickup: this.changeResidentialForm[index].controls.latitudePickup.value,
      longitudePickup: this.changeResidentialForm[index].controls.longitudePickup.value,
      latitudeDropoff: this.changeResidentialForm[index].controls.latitudeDropoff.value,
      longitudeDropoff: this.changeResidentialForm[index].controls.longitudeDropoff.value,
      isPickup:this.changeResidentialForm[index].controls.isPickup.value,
      isDropoff:this.changeResidentialForm[index].controls.isDropoff.value,
      isPickupOther: this.changeResidentialForm[index].controls.isPickupOther.value,
      isDropoffOther: this.changeResidentialForm[index].controls.isDropoffOther.value,
      pickupMarker:this.changeResidentialForm[index].controls.pickupMarker.value,
      dropoffMarker:this.changeResidentialForm[index].controls.dropoffMarker.value,
      pickupCenter:this.changeResidentialForm[index].controls.pickupCenter.value,
      dropoffCenter:this.changeResidentialForm[index].controls.dropoffCenter.value,
      mapOpenedFrom:this.changeResidentialForm[index].controls.mapOpenedFrom.value,
      pickupAreaMaster: this.changeResidentialForm[index].controls.pickupAreaMaster.value,
      dropOffAreaMaster: this.changeResidentialForm[index].controls.dropOffAreaMaster.value,
      pickupPointMaster: this.changeResidentialForm[index].controls.pickupPointMaster.value,
      dropoffPointMaster: this.changeResidentialForm[index].controls.dropoffPointMaster.value,
      pickupAreaMasterTemp: this.changeResidentialForm[index].controls.pickupAreaMasterTemp.value,
      dropOffAreaMasterTemp: this.changeResidentialForm[index].controls.dropOffAreaMasterTemp.value,
      pickupPointMasterTemp: this.changeResidentialForm[index].controls.pickupPointMasterTemp.value,
      dropoffPointMasterTemp: this.changeResidentialForm[index].controls.dropoffPointMasterTemp.value,
    })
  }

  getMasterDetails()
  {
      this.token = JSON.parse(localStorage.getItem('currentToken'))?.access_token

      let TptTypeHeader: any = {
        headers: {
          "master": "TPT_TYPE",
          "bsuid": localStorage.getItem('bsU_ID').toString(),
          "Authorization": "Bearer " + this.token
        }
      }
      let cityHeader: any = {
        headers: {
          "master": "TPT_CITY_M",
          "param1": "172",
          "Authorization": "Bearer " + this.token,
          "bsuid": localStorage.getItem('bsU_ID').toString(),
        }
      }

      this.serviceInfo.getMaster(TptTypeHeader).subscribe(res => {

        this.transportTypes = res["data"]?.filter(x => x.id != -1).map(x => ({ descr: x.descr, id: x.id.toString() }));
        this.transportTypes.unshift({
          descr: 'Select',
          id: null
        })

        this.serviceInfo.getMaster(cityHeader).subscribe(res => {
          this.city = res["data"];
          this.city.unshift({
            descr: 'Select',
            id: null
          })
        })
      });
  }

  getSiblings()
  {
    this.reregistrationService.getSiblingDetails().subscribe(res=>{
      res.data.forEach(x=>{
        if(x.stU_NO == this.selectedStudent)
        x.checked=true;
        else x.checked =false;
      })
    this.siblingList =res?.data
    })
  }

  getAreaBasedOnCity(cityId: any, formId:any) {
    if (cityId != null && cityId != undefined && cityId != '') {
      let pickupAreaHeader: any = {
        headers: {
          "master": "AREA_M_TPT_RR",
          "param1": cityId.toString(),
          "bsuid": localStorage.getItem('bsU_ID'),
          "acdid": this.selectedChild?.acdId.toString() ?? '0',
          "Authorization": "Bearer " + this.token
        }
      }

      this.serviceInfo.getMaster(pickupAreaHeader).subscribe(res => {

        this.pickupArea = res["data"];
        this.dropoffArea = res["data"];
        this.pickupPoint?.unshift({
          descr: 'Select',
          id: null
        })
        this.pickupAreaTemp = [...this.pickupArea];
        this.dropoffAreaTemp = [...this.dropoffArea];

        this.changeResidentialForm[formId].patchValue({
          pickupAreaMaster: res["data"],
          dropOffAreaMaster: res["data"],
          pickupAreaMasterTemp: res["data"],
          dropOffAreaMasterTemp: res["data"],
          pickupPointMaster: this.pickupPoint,
          dropoffPointMaster: this.pickupPoint,
          pickupPointMasterTemp: this.pickupPoint,
          dropoffPointMasterTemp: this.pickupPoint
        });
      });
    }
  }

  getPickupPoints(pickupId:any, formId:any) {

    let pickupPointHeader: any = {
      headers: {
        "master": "PICKUP_TPT_RR",
        "bsuid": localStorage.getItem('bsU_ID'),
        "param1": "172",
        "param2": pickupId.toString(),
        "Authorization": "Bearer " + this.token
      }
    }
    this.serviceInfo.getMaster(pickupPointHeader).subscribe(res => {
      this.pickupPoint = res["data"];
      this.pickupPointTemp=[...this.pickupPoint]

      this.changeResidentialForm[formId].patchValue({
        pickupPointMaster: res["data"],
        pickupPointMasterTemp: res["data"]
      });
    })
  }
  getDropOffPoints(dropoffId:any, formId:any) {

    let dropoffPointHeader: any = {
      headers: {
        "master": "DROP_TPT_RR",
        "bsuid": localStorage.getItem('bsU_ID'),
        "param1": "172",
        "param2": dropoffId.toString(),
        "Authorization": "Bearer " + this.token
      }
    }
    this.serviceInfo.getMaster(dropoffPointHeader).subscribe(res => {
      this.dropoffPoint = res["data"];
      this.dropoffPointTemp=[...this.dropoffPoint]

      this.changeResidentialForm[formId].patchValue({
        dropoffPointMaster: res["data"],
        dropoffPointMasterTemp: res["data"]
      });
    })
  }

  setValidation(formId:any){
    if(this.changeResidentialForm[formId].controls.isPickup.value && !this.changeResidentialForm[formId].controls.isDropoff.value)
    {
    this.changeResidentialForm[formId].controls.pickupArea.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.pickupPoint.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.pickupLocation.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.pickupLandmark.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.pickupArea.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupPoint.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupLocation.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupLandmark.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffArea.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffArea.clearValidators();
    this.changeResidentialForm[formId].controls.dropoffArea.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.dropoffPoint.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffPoint.clearValidators();
    this.changeResidentialForm[formId].controls.dropoffPoint.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.dropoffLandmark.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffLandmark.clearValidators();
    this.changeResidentialForm[formId].controls.dropoffLandmark.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.dropoffLocation.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffLocation.clearValidators();
    this.changeResidentialForm[formId].controls.dropoffLocation.updateValueAndValidity();
    }
   else if(this.changeResidentialForm[formId].controls.isDropoff.value && !this.changeResidentialForm[formId].controls.isPickup.value){
    this.changeResidentialForm[formId].controls.dropoffArea.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.dropoffPoint.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.dropoffLandmark.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.dropoffLocation.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.dropoffArea.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffPoint.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffLandmark.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffLocation.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupArea.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupArea.clearValidators();
    this.changeResidentialForm[formId].controls.pickupArea.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.pickupPoint.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupPoint.clearValidators();
    this.changeResidentialForm[formId].controls.pickupPoint.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.pickupLandmark.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupLandmark.clearValidators();
    this.changeResidentialForm[formId].controls.pickupLandmark.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.pickupLocation.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupLocation.clearValidators();
    this.changeResidentialForm[formId].controls.pickupLocation.updateValueAndValidity();

   }
   else if(this.changeResidentialForm[formId].controls.isDropoff.value && this.changeResidentialForm[formId].controls.isPickup.value)
   {
    this.changeResidentialForm[formId].controls.dropoffArea.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.dropoffPoint.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.dropoffLandmark.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.pickupArea.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.pickupPoint.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.pickupLandmark.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.pickupLocation.setValidators([Validators.required]);
    this.changeResidentialForm[formId].controls.dropoffLocation.setValidators([Validators.required]);
    
    this.changeResidentialForm[formId].controls.dropoffArea.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffPoint.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffLandmark.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupArea.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupPoint.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupLandmark.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupLocation.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffLocation.markAsUntouched();
   }
   this.changeResidentialForm[formId].patchValue({
    dropoffArea:'',
    dropoffPoint:'',
    dropoffLandmark:'',
    pickupArea:'',
    pickupPoint:'',
    pickupLandmark:'',
    pickupLocation:'',
    dropoffLocation:''
   })
  //  this.pickupLocation='';
  //  this.dropoffLocation=''
  }

  changePickupArea(formId:any)
  {
    this.selectedpickuparea = "";
    let pickupAreaDesc = this.pickupArea.find(x=>x.id == this.changeResidentialForm[formId].get('pickupArea').value);
    if(pickupAreaDesc)
    {
      this.selectedpickuparea = pickupAreaDesc.descr;
      this.selectedpickuparea1 = pickupAreaDesc.id;
      this.getPickupPoints(pickupAreaDesc.id, formId);
      this.getFeeStructure(formId);
    }
    this.changeResidentialForm[formId].patchValue({
      pickupPoint:'',
      pickupLandmark:'',
      pickupLocation:''
     })
     //this.pickupLocation='';
     //this.editAllChildFields(formId);
     this.autoPopulateDropoff(formId)
  }

  changePickupPoint(formId:any)
  {
    let pickupAreaDesc = this.pickupPointTemp.find(x=>x.id == this.changeResidentialForm[formId].get('pickupPoint').value);
    if(pickupAreaDesc){
    this.changeResidentialForm[formId].patchValue({"isPickupOther":pickupAreaDesc?.descr == 'Other' ? true :false})
    if(this.changeResidentialForm[formId].controls.isPickupOther.value)
    {
  this.changeResidentialForm[formId].controls.pickupPoint.markAsUntouched();
  this.changeResidentialForm[formId].controls.pickupPoint.clearValidators();
  this.changeResidentialForm[formId].controls.pickupPoint.updateValueAndValidity();
  this.changeResidentialForm[formId].controls.pickupLandmark.setValidators([Validators.required]);
  this.changeResidentialForm[formId].controls.pickupLocation.setValidators([Validators.required]);
    }
    else{
  this.changeResidentialForm[formId].controls.pickupPoint.setValidators([Validators.required]);
  this.changeResidentialForm[formId].controls.pickupLandmark.markAsUntouched();
  this.changeResidentialForm[formId].controls.pickupLandmark.clearValidators();
  this.changeResidentialForm[formId].controls.pickupLandmark.updateValueAndValidity();
  this.changeResidentialForm[formId].controls.pickupLocation.markAsUntouched();
  this.changeResidentialForm[formId].controls.pickupLocation.clearValidators();
  this.changeResidentialForm[formId].controls.pickupLocation.updateValueAndValidity();
    }
    }
    else  this.changeResidentialForm[formId].patchValue({"isPickupOther":false});
    this.autoPopulateDropoffPoint(formId);
  }

  changeDropoffArea(formId:any,fromAutopopulate=false)
  {
    this.selecteddropoffarea = "";
    let dropoffAreaDesc = this.dropoffArea.find(x=>x.id == this.changeResidentialForm[formId].get('dropoffArea').value);
    if(dropoffAreaDesc)
    {
      this.selecteddropoffarea = dropoffAreaDesc.descr;
      this.selecteddropofarea2 = dropoffAreaDesc.id;
      this.getDropOffPoints(dropoffAreaDesc.id, formId)
      this.getFeeStructure(formId);
    }
    this.changeResidentialForm[formId].patchValue({
      dropoffPoint:'',
      dropoffLandmark:'',
      dropoffLocation:''
     })
     //this.dropoffLocation='';
    //  if(!fromAutopopulate)
    //  this.autoPopulateDropoff(formId);
    this.editAllChildFields(formId);
  }

  changeDropoffPoint(formId:any)
  {
    let dropoffAreaDesc = this.dropoffPointTemp.find(x=>x.id == this.changeResidentialForm[formId].get('dropoffPoint').value);
    if(dropoffAreaDesc)
    {
     this.changeResidentialForm[formId].patchValue({"isDropoffOther":dropoffAreaDesc?.descr == 'Other' ? true :false})
     if(this.changeResidentialForm[formId].controls.isDropoffOther.value)
     {
   this.changeResidentialForm[formId].controls.dropoffPoint.markAsUntouched();
   this.changeResidentialForm[formId].controls.dropoffPoint.clearValidators();
   this.changeResidentialForm[formId].controls.dropoffPoint.updateValueAndValidity();
   this.changeResidentialForm[formId].controls.dropoffLandmark.setValidators([Validators.required]);
   this.changeResidentialForm[formId].controls.dropoffLocation.setValidators([Validators.required]);
     }
     else{
   this.changeResidentialForm[formId].controls.dropoffPoint.setValidators([Validators.required]);
   this.changeResidentialForm[formId].controls.dropoffLandmark.markAsUntouched();
   this.changeResidentialForm[formId].controls.dropoffLandmark.clearValidators();
   this.changeResidentialForm[formId].controls.dropoffLandmark.updateValueAndValidity();
   this.changeResidentialForm[formId].controls.dropoffLocation.markAsUntouched();
   this.changeResidentialForm[formId].controls.dropoffLocation.clearValidators();
   this.changeResidentialForm[formId].controls.dropoffLocation.updateValueAndValidity();
     }
    }else this.changeResidentialForm[formId].patchValue({"isDropoffOther":false})
    this.editAllChildFields(formId);
  }

  changeCity(formId:any)
  {
    this.changeResidentialForm[formId].controls.dropoffArea.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffArea.clearValidators();
    this.changeResidentialForm[formId].controls.dropoffArea.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.dropoffPoint.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffPoint.clearValidators();
    this.changeResidentialForm[formId].controls.dropoffPoint.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.dropoffLandmark.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffLandmark.clearValidators();
    this.changeResidentialForm[formId].controls.dropoffLandmark.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.pickupArea.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupArea.clearValidators();
    this.changeResidentialForm[formId].controls.pickupArea.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.pickupPoint.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupPoint.clearValidators();
    this.changeResidentialForm[formId].controls.pickupPoint.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.pickupLandmark.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupLandmark.clearValidators();
    this.changeResidentialForm[formId].controls.pickupLandmark.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.dropoffLocation.markAsUntouched();
    this.changeResidentialForm[formId].controls.dropoffLocation.clearValidators();
    this.changeResidentialForm[formId].controls.dropoffLocation.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.pickupLocation.markAsUntouched();
    this.changeResidentialForm[formId].controls.pickupLocation.clearValidators();
    this.changeResidentialForm[formId].controls.pickupLocation.updateValueAndValidity();
    this.changeResidentialForm[formId].controls.house.markAsUntouched();
    this.changeResidentialForm[formId].controls.street.markAsUntouched();
    this.changeResidentialForm[formId].controls.tptType.markAsUntouched();
    this.changeResidentialForm[formId].patchValue({
      house:"",
      street:"",
      tptType:"",
      dropoffArea:'',
      dropoffPoint:'',
      dropoffLandmark:'',
      pickupArea:'',
      pickupPoint:'',
      pickupLandmark:'',
      pickupLocation:'',
      dropoffLocation:'',
      isPickup: false,
      isDropoff: false,
      isPickupOther:false,
      isDropoffOther: false,
      latitudePickup:0,
      longitudePickup: 0,
      latitudeDropoff: 0,
      longitudeDropoff: 0,
     })
    //  this.dropoffLocation=''
    //  this.pickupLocation=''
    this.editAllChildFields(formId);

    this.getAreaBasedOnCity(this.changeResidentialForm[formId].controls.city.value, formId);
  }

  autoPopulateDropoff(formId:any)
  {
    if(this.changeResidentialForm[formId].get('tptType').value == 0)
   {
     if(this.changeResidentialForm[formId].controls.pickupArea.value)
     {
      this.changeResidentialForm[formId].patchValue({
        "dropoffArea": this.changeResidentialForm[formId].controls.pickupArea.value
      })
      this.changeDropoffArea(formId, true);
     }
     if((this.changeResidentialForm[formId].controls.pickupArea.value == this.changeResidentialForm[formId].controls.dropoffArea.value) && this.changeResidentialForm[formId].controls.pickupPoint.value)
     {
       this.changeResidentialForm[formId].patchValue({
         "dropoffPoint": this.changeResidentialForm[formId].controls.pickupPoint.value
       })
     }else{
      this.changeResidentialForm[formId].patchValue({
        "dropoffPoint": ''
      })
     }
     this.changeDropoffPoint(formId);
     let dropoffAreaDesc = this.dropoffPointTemp.find(x=>x.id == this.changeResidentialForm[formId].get('dropoffPoint').value);
     if(dropoffAreaDesc)
     {
      this.changeResidentialForm[formId].patchValue({"isDropoffOther":dropoffAreaDesc?.descr == 'Other' ? true :false})
     }else this.changeResidentialForm[formId].patchValue({"isDropoffOther":false})
   }
   this.editAllChildFields(formId);
  }

  autoPopulateDropoffPoint(formId: any) {
    if (this.changeResidentialForm[formId].get('tptType').value == 0) {
      if ((this.changeResidentialForm[formId].controls.pickupArea.value == this.changeResidentialForm[formId].controls.dropoffArea.value) && this.changeResidentialForm[formId].controls.pickupPoint.value) {
        this.changeResidentialForm[formId].patchValue({
          "dropoffPoint": this.changeResidentialForm[formId].controls.pickupPoint.value
        })
      } else {
        this.changeResidentialForm[formId].patchValue({
          "dropoffPoint": ''
        })
      }

      this.changeDropoffPoint(formId);

      let dropoffAreaDesc = this.dropoffPointTemp.find(x => x.id == this.changeResidentialForm[formId].get('dropoffPoint').value);
      if (dropoffAreaDesc) {
        this.changeResidentialForm[formId].patchValue({ "isDropoffOther": dropoffAreaDesc?.descr == 'Other' ? true : false })
      } 
      else{
        this.changeResidentialForm[formId].patchValue({ "isDropoffOther": false })
      }
    }
    this.editAllChildFields(formId);
  }

  changeTptType(formId:any)
  {
    this.selectedpickuparea = "";
    this.selecteddropoffarea = "";
    this.selectedpickuparea1 = "";
    this.selecteddropofarea2 = "";
   if(this.changeResidentialForm[formId].get('tptType').value == 1)
   {
    this.changeResidentialForm[formId].patchValue({"isPickup":true})
    this.changeResidentialForm[formId].patchValue({"isDropoff":false})
  // this.isPickup=true;
  // this.isDropoff=false;
   }
   else if(this.changeResidentialForm[formId].get('tptType').value == 2){
    // this.isPickup=false;
    // this.isDropoff=true;
    this.changeResidentialForm[formId].patchValue({"isPickup":false})
    this.changeResidentialForm[formId].patchValue({"isDropoff":true})
   }else if(this.changeResidentialForm[formId].get('tptType').value == 0){
    // this.isPickup=true;
    // this.isDropoff=true;
    this.changeResidentialForm[formId].patchValue({"isPickup":true})
    this.changeResidentialForm[formId].patchValue({"isDropoff":true})
   }else{
    this.changeResidentialForm[formId].patchValue({"isPickup":false})
    this.changeResidentialForm[formId].patchValue({"isDropoff":false})
   }
   this.setValidation(formId);
   this.editAllChildFields(formId);
  }

  getSiblingList(template:any)
  {
    this.addSiblingDialog(template)
  }

  toggleSibling(event:any,sibling:any)
  {
    setTimeout(() => {
    this.digopen.close();
    }, 200);
    this.siblingList.forEach(x=>{
      if(sibling.stU_NO == x.stU_NO)
      x.checked=event.checked;
    })
    if(event.checked)
    {
      this.formIndex=Number(sibling.stU_NO)
      this.changeResidentialForm[this.formIndex]=this.createFormGroup();
      this.setFormValues(this.formIndex,sibling),
      this.setResidentialForms(this.formIndex,true)
    }
    else{
      let SpliceIndex = this.residentialForms.findIndex(x=> x.id == Number(sibling.stU_NO))
      if(SpliceIndex != -1)
      {
      this.residentialForms.splice(SpliceIndex,1)
      for (let formId of Object.keys(this.changeResidentialForm)) {
       if(formId == sibling.stU_NO)
       delete this.changeResidentialForm[Number(sibling.stU_NO)]
      }
      }
    }
  }

  editAllChildFields(formId:any)
  {
    let isFalse =false;
    if(this.selectedStudent == formId)
    {
      this.residentialForms.forEach(x=>{
        if(x.id != formId)
        {
          this.changeResidentialForm[x.id].patchValue({
      id:x.id,
      city:this.changeResidentialForm[formId].controls.city.value,
      house:this.changeResidentialForm[formId].controls.house.value,
      street:this.changeResidentialForm[formId].controls.street.value,
      tptType:this.changeResidentialForm[formId].controls.tptType.value,
      pickupArea:this.changeResidentialForm[formId].controls.pickupArea.value,
      dropoffArea:this.changeResidentialForm[formId].controls.dropoffArea.value,
      pickupPoint:this.changeResidentialForm[formId].controls.pickupPoint.value,
      dropoffPoint:this.changeResidentialForm[formId].controls.dropoffPoint.value,
      pickupLocation:this.changeResidentialForm[formId].controls.pickupLocation.value,
      dropoffLocation:this.changeResidentialForm[formId].controls.dropoffLocation.value,
      pickupLandmark:this.changeResidentialForm[formId].controls.pickupLandmark.value,
      dropoffLandmark:this.changeResidentialForm[formId].controls.dropoffLandmark.value,
      changeLocDate:this.changeResidentialForm[formId].controls.changeLocDate.value,
      latitudePickup: this.changeResidentialForm[formId].controls.latitudePickup.value,
      longitudePickup: this.changeResidentialForm[formId].controls.longitudePickup.value,
      latitudeDropoff: this.changeResidentialForm[formId].controls.latitudeDropoff.value,
      longitudeDropoff: this.changeResidentialForm[formId].controls.longitudeDropoff.value,
      isPickup:this.changeResidentialForm[formId].controls.isPickup.value,
      isDropoff:this.changeResidentialForm[formId].controls.isDropoff.value,
      isPickupOther: this.changeResidentialForm[formId].controls.isPickupOther.value,
      isDropoffOther: this.changeResidentialForm[formId].controls.isDropoffOther.value,
      pickupMarker:this.changeResidentialForm[formId].controls.pickupMarker.value,
      dropoffMarker:this.changeResidentialForm[formId].controls.dropoffMarker.value,
      pickupCenter:this.changeResidentialForm[formId].controls.pickupCenter.value,
      dropoffCenter:this.changeResidentialForm[formId].controls.dropoffCenter.value,
      mapOpenedFrom:this.changeResidentialForm[formId].controls.mapOpenedFrom.value
          })
        }
      })
    }

    

    this.residentialForms.forEach(x=>{
    let isValid =  this.changeResidentialForm[x.id].valid ? true :false;
    if(!isValid)
    isFalse=true;
    })
   this.isFormValid=isFalse?false:true;
  }

  setResidentialForms(id:any, isCreate:boolean)
  {
    if(!isCreate)
    {
      let SpliceIndex = this.residentialForms.findIndex(x=> x.id == id)
      if(SpliceIndex != -1)
      this.residentialForms.splice(SpliceIndex,1)

    let date = new Date(this.changeResidentialForm[id].get('changeLocDate').value?._d)
    let reqDate= this.datePipe.transform(date,'yyyy-MM-dd')+"T00:00:00";
    let body={
      "id":id,
      "studName":this.changeResidentialForm[id].get('studentName').value,
      "photopath":this.changeResidentialForm[id].get('photopath').value,
      "StudentNumber":id,
      "HouseNo":this.changeResidentialForm[id].get('house').value,
      "Address":this.changeResidentialForm[id].get('street').value,
      "LOC_ID_CITY":this.changeResidentialForm[id].get('city').value,
      "SBL_ID_PICKUP":this.changeResidentialForm[id].get('pickupArea').value == "" ? 0 : this.changeResidentialForm[id].get('pickupArea').value,
      "PNT_ID_PICKUPPOINT":this.changeResidentialForm[id].get('pickupPoint').value == "" ? 0 : this.changeResidentialForm[id].get('pickupPoint').value,
      "SBL_ID_DROPOFF":this.changeResidentialForm[id].get('dropoffArea').value=="" ? 0 : this.changeResidentialForm[id].get('dropoffArea').value,
      "PNT_ID_DROPOFFPOINT":this.changeResidentialForm[id].get('dropoffPoint').value == "" ? 0 : this.changeResidentialForm[id].get('dropoffPoint').value,
      "TRIPTYPE":this.changeResidentialForm[id].get('tptType').value,
      "REQSTARTDATE":reqDate,
      "PICKUPLANDMARK":this.changeResidentialForm[id].get('pickupLandmark').value,
      "DROPOFFLANDMARK":this.changeResidentialForm[id].get('dropoffLandmark').value,
      "Picklat":this.changeResidentialForm[id].get('latitudePickup').value == 0 ? null : this.changeResidentialForm[id].get('latitudePickup').value,
      "Picklog":this.changeResidentialForm[id].get('longitudePickup').value == 0 ? null : this.changeResidentialForm[id].get('longitudePickup').value,
      "Droplat":this.changeResidentialForm[id].get('latitudeDropoff').value == 0 ? null :this.changeResidentialForm[id].get('latitudeDropoff').value,
      "Droplog":this.changeResidentialForm[id].get('longitudeDropoff').value == 0 ? null : this.changeResidentialForm[id].get('longitudeDropoff').value,
      "Source":"web"
    }
    this.residentialForms.push({...body})
  }
  else{
    this.residentialForms.push({id:id,studName:this.changeResidentialForm[id].get('studentName').value,
    photopath:this.changeResidentialForm[id].get('photopath').value })
  }
  }
  searchPickupArea(value: string, formId: any) {
    let filter = value.toLowerCase();
    let tempArea = this.changeResidentialForm[formId].get('pickupAreaMaster').value;
    if (filter != '') {
      let filteredArea =tempArea.filter(option => option.descr.toLowerCase().startsWith(filter));
      this.changeResidentialForm[formId].patchValue({
        'pickupAreaMasterTemp': filteredArea
      });
    } 
    else{
      this.changeResidentialForm[formId].patchValue({
        'pickupAreaMasterTemp': tempArea
      });
    }
  }

  searchPickupPoints(value: string, formId:any) {
    let filter = value.toLowerCase();
    let tempPoints = this.changeResidentialForm[formId].get('pickupPointMaster').value;
    if (filter != '') {
      let filteredPoints = tempPoints.filter(option => option.descr.toLowerCase().startsWith(filter));
      this.changeResidentialForm[formId].patchValue({
        'pickupPointMasterTemp': filteredPoints
      });
    }
    else {
      this.changeResidentialForm[formId].patchValue({
        'pickupPointMasterTemp': tempPoints
      });
    }
  }

  searchDropoffArea(value: string, formId: any) {
    let filter = value.toLowerCase();
    let tempArea = this.changeResidentialForm[formId].get('dropOffAreaMaster').value;
    if (filter != '') {
      let filteredArea =tempArea.filter(option => option.descr.toLowerCase().startsWith(filter));
      this.changeResidentialForm[formId].patchValue({
        'dropOffAreaMasterTemp': filteredArea
      });
    } 
    else{
      this.changeResidentialForm[formId].patchValue({
        'dropOffAreaMasterTemp': tempArea
      });
    }
  }

  searchDropoffPoints(value: string, formId:any) {
    let filter = value.toLowerCase();
    let tempPoints = this.changeResidentialForm[formId].get('dropoffPointMaster').value;
    if (filter != '') {
      let filteredPoints = tempPoints.filter(option => option.descr.toLowerCase().startsWith(filter));
      this.changeResidentialForm[formId].patchValue({
        'dropoffPointMasterTemp': filteredPoints
      });
    }
    else {
      this.changeResidentialForm[formId].patchValue({
        'dropoffPointMasterTemp': tempPoints
      });
    }
  }

  mapTextSearch() {
    if (this.mapSearch.length >= 3) {
      this.autocompleteService.getPlacePredictions(this.mapSearch).subscribe(predictions => {
        this.predictions = predictions;
      });
    } else {
      this.predictions = [];
    }
  }

  eventHandler(event: any, name: string, formId:any) {
    if (name === 'mapDblclick' || name === 'mapClick') {
      this.dropMarker(event,formId)
      this.editAllChildFields(formId);
    }
  }

  dropMarker(event: any ,formId:any) {
    if (this.changeResidentialForm[formId].controls.mapOpenedFrom.value == 'pickup') {
      this.changeResidentialForm[formId].patchValue({
        "latitudePickup":event.latLng.lat(),
        "longitudePickup":event.latLng.lng(),
        "pickupConfirmed": false
      })
      // this.latitudePickup = event.latLng.lat();
      // this.longitudePickup = event.latLng.lng();
      this.PickupgetAddress(event.latLng.lat(), event.latLng.lng(),formId)
      if (this.changeResidentialForm[formId].get('tptType').value == 0) {
        // this.latitudeDropoff = this.latitudePickup;
        // this.longitudeDropoff = this.longitudePickup;
        this.changeResidentialForm[formId].patchValue({
          "latitudeDropoff": event.latLng.lat(),
          "longitudeDropoff": event.latLng.lng(),
          "dropoffConfirmed": false
        })

        this.changeResidentialForm[formId].patchValue({ "dropoffMarker": [] });
        let dropoffMarker = [];
        dropoffMarker.push({
          position: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
          label: {
            color: 'blue',
            text: 'Marker label ' + (dropoffMarker.length + 1),
          },
          title: 'Marker title ' + (dropoffMarker.length + 1),
          info: 'Marker info ' + (dropoffMarker.length + 1),
          options: {
            animation: google.maps.Animation.DROP,
          },
        })
        this.changeResidentialForm[formId].patchValue({ "dropoffMarker": dropoffMarker });
        this.changeResidentialForm[formId].patchValue({ "dropoffCenter": { lat: event.latLng.lat(), lng: event.latLng.lng() } });
        this.DropoffgetAddress(event.latLng.lat(), event.latLng.lng(), formId)
      }
      this.changeResidentialForm[formId].patchValue({"pickupMarker":[]})
      let pickupMarker=[];
      pickupMarker.push({
        position: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
        label: {
          color: 'blue',
          text: 'Marker label ' + (pickupMarker.length + 1),
        },
        title: 'Marker title ' + (pickupMarker.length + 1),
        info: 'Marker info ' + (pickupMarker.length + 1),
        options: {
          animation: google.maps.Animation.DROP,
        },
      })
      this.changeResidentialForm[formId].patchValue({"pickupMarker":pickupMarker})
      this.changeResidentialForm[formId].patchValue({"pickupCenter":{ lat: event.latLng.lat(), lng: event.latLng.lng() }});
    } else {
      // this.latitudeDropoff = event.latLng.lat();
      // this.longitudeDropoff = event.latLng.lng();
      this.changeResidentialForm[formId].patchValue({
        "latitudeDropoff":event.latLng.lat(),
        "longitudeDropoff":event.latLng.lng(),
        "dropoffConfirmed": false
      })
      this.changeResidentialForm[formId].patchValue({"dropoffMarker":[]});
      let dropoffMarker=[];
      dropoffMarker.push({
        position: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
        label: {
          color: 'blue',
          text: 'Marker label ' + (dropoffMarker.length + 1),
        },
        title: 'Marker title ' + (dropoffMarker.length + 1),
        info: 'Marker info ' + (dropoffMarker.length + 1),
        options: {
          animation: google.maps.Animation.DROP,
        },
      })
      this.changeResidentialForm[formId].patchValue({"dropoffMarker":dropoffMarker});
      this.changeResidentialForm[formId].patchValue({"dropoffCenter":{ lat: event.latLng.lat(), lng: event.latLng.lng() }});
      this.DropoffgetAddress(event.latLng.lat(), event.latLng.lng(),formId)
    }
  }

  PickupgetAddress(latitude, longitude,formId) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 20;
         // this.pickupLocation = results[0].formatted_address
          this.changeResidentialForm[formId].patchValue({
            "pickupLocation":results[0].formatted_address
          })
          this.changeResidentialForm[formId].patchValue({"pickupCenter" : {lat:latitude,lng:longitude}});
          this.changeResidentialForm[formId].patchValue({"pickupMarker" : []});
          let pickupMarker=[];
            pickupMarker.push({position: this.changeResidentialForm[formId].controls.pickupCenter.value,label: '',title: '',options: { animation: google.maps.Animation.DROP
           }
           });
           this.changeResidentialForm[formId].patchValue({"pickupMarker" : pickupMarker});
        } 
      } 
    });
  }

  DropoffgetAddress(latitude, longitude,formId:any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 20;
          //this.dropoffLocation = results[0].formatted_address
          this.changeResidentialForm[formId].patchValue({
            "dropoffLocation":results[0].formatted_address
          })
          this.changeResidentialForm[formId].patchValue({"dropoffCenter" : {lat:latitude,lng:longitude}});
          this.changeResidentialForm[formId].patchValue({"dropoffMarker" : []});
          let dropoffMarker=[];
            dropoffMarker.push({position: this.changeResidentialForm[formId].controls.dropoffCenter.value,label: '',title: '',options: { animation: google.maps.Animation.DROP
        }
      });
           this.changeResidentialForm[formId].patchValue({"dropoffMarker" : dropoffMarker});
        } 
      } 
    });
  }

  confirmLocation(type:any){

    if(type == 'pickup'){
      this.changeResidentialForm[this.MapFormIndex].patchValue({"pickupConfirmed":true})

      if (this.changeResidentialForm[this.MapFormIndex].get('tptType').value == 0){
        this.changeResidentialForm[this.MapFormIndex].patchValue({"dropoffConfirmed":true})
      }
    }else{
      this.changeResidentialForm[this.MapFormIndex].patchValue({"dropoffConfirmed":true})
    }

    this.dialog.closeAll();
    this.mapSearch = '',
    this.predictions=[];
  }

  close() {

    if (this.changeResidentialForm[this.MapFormIndex].controls.mapOpenedFrom.value == 'pickup') {
      if (!this.changeResidentialForm[this.MapFormIndex].get('pickupConfirmed').value) {

        this.changeResidentialForm[this.MapFormIndex].controls.pickupLocation.markAsUntouched();
        this.changeResidentialForm[this.MapFormIndex].controls.pickupLocation.clearValidators();
        this.changeResidentialForm[this.MapFormIndex].controls.pickupLocation.updateValueAndValidity();
        if(this.existingMapInfo != null && this.existingMapInfo != undefined){
        this.changeResidentialForm[this.MapFormIndex].patchValue({
          pickupMarker:this.existingMapInfo.pickupMarker,
          pickupLocation: this.existingMapInfo.pickupLocation,
          latitudePickup: this.existingMapInfo.latitudePickup,
          longitudePickup: this.existingMapInfo.longitudePickup,
          pickupCenter: this.existingMapInfo.pickupCenter
        });
        }else{
          this.changeResidentialForm[this.MapFormIndex].patchValue({
            pickupMarker: [],
            pickupLocation: '',
            latitudePickup: 0,
            longitudePickup: 0,
            pickupCenter: this.pickCenter
          });
        }

        if (this.changeResidentialForm[this.MapFormIndex].get('tptType').value == 0) {
          this.changeResidentialForm[this.MapFormIndex].controls.dropoffLocation.markAsUntouched();
          this.changeResidentialForm[this.MapFormIndex].controls.dropoffLocation.clearValidators();
          this.changeResidentialForm[this.MapFormIndex].controls.dropoffLocation.updateValueAndValidity();
          if (this.existingMapInfo != null && this.existingMapInfo != undefined) {
            this.changeResidentialForm[this.MapFormIndex].patchValue({
              dropoffMarker: this.existingMapInfo.dropoffMarker,
              dropoffLocation: this.existingMapInfo.dropoffLocation,
              latitudeDropoff: this.existingMapInfo.latitudeDropoff,
              longitudeDropoff: this.existingMapInfo.longitudeDropoff,
              dropoffCenter: this.existingMapInfo.dropoffCenter
            })
          }else{
            this.changeResidentialForm[this.MapFormIndex].patchValue({
              dropoffMarker: [],
              dropoffLocation: '',
              latitudeDropoff: 0,
              longitudeDropoff: 0,
              dropoffCenter: this.dropCenter
            })
          }
        }
      }
    } else {
      if (!this.changeResidentialForm[this.MapFormIndex].get('dropoffConfirmed').value) {
        this.changeResidentialForm[this.MapFormIndex].controls.dropoffLocation.markAsUntouched();
        this.changeResidentialForm[this.MapFormIndex].controls.dropoffLocation.clearValidators();
        this.changeResidentialForm[this.MapFormIndex].controls.dropoffLocation.updateValueAndValidity();
        if (this.existingMapInfo != null && this.existingMapInfo != undefined) {
          this.changeResidentialForm[this.MapFormIndex].patchValue({
            dropoffMarker: this.existingMapInfo.dropoffMarker,
            dropoffLocation: this.existingMapInfo.dropoffLocation,
            latitudeDropoff: this.existingMapInfo.latitudeDropoff,
            longitudeDropoff: this.existingMapInfo.longitudeDropoff,
            dropoffCenter: this.existingMapInfo.dropoffCenter
          })
        }else{
          this.changeResidentialForm[this.MapFormIndex].patchValue({
            dropoffMarker: [],
            dropoffLocation: '',
            latitudeDropoff: 0,
            longitudeDropoff: 0,
            dropoffCenter: this.dropCenter
          })
        }
      }
    }

    this.dialog.closeAll();
    this.mapSearch = '',
    this.predictions = [];
  }
  cancel()
  {
    this.digopen?.close();
    this.router.navigate(['/request-area-change'])
  }

  openMap(template: TemplateRef<any>, value: any,formId:any) {

    this.existingMapInfo = {
      pickupMarker: this.changeResidentialForm[formId].get('pickupMarker').value,
      dropoffMarker: this.changeResidentialForm[formId].get('dropoffMarker').value,
      pickupLocation: this.changeResidentialForm[formId].get('pickupLocation').value,
      dropoffLocation: this.changeResidentialForm[formId].get('dropoffLocation').value,
      latitudePickup: this.changeResidentialForm[formId].get('latitudePickup').value,
      longitudePickup: this.changeResidentialForm[formId].get('longitudePickup').value,
      latitudeDropoff: this.changeResidentialForm[formId].get('latitudeDropoff').value,
      longitudeDropoff: this.changeResidentialForm[formId].get('longitudeDropoff').value,
      pickupCenter: this.changeResidentialForm[formId].get('pickupCenter').value,
      dropoffCenter: this.changeResidentialForm[formId].get('dropoffCenter').value
    }
    this.changeResidentialForm[formId].patchValue({"mapOpenedFrom" : value});
    this.dialog.open(template, {
      disableClose: true,
      width: '1040px',
      panelClass: ['custom-modalbox', 'mapPopup']
    });
    this.MapFormIndex=formId
  }

  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

  onPredictionClick(prediction: google.maps.places.AutocompletePrediction) {
    const geocoder = new google.maps.Geocoder();
    if(this.changeResidentialForm[this.MapFormIndex].controls.mapOpenedFrom.value =='pickup'){
     //this.pickupLocation = prediction.description
     this.changeResidentialForm[this.MapFormIndex].patchValue({'pickupLocation':prediction.description})
    } else{
     //this.dropoffLocation = prediction.description
     this.changeResidentialForm[this.MapFormIndex].patchValue({'dropoffLocation':prediction.description})
    }
    geocoder.geocode({ placeId: prediction.place_id }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
             // Update the map center and clear existing markers
             if(this.changeResidentialForm[this.MapFormIndex].controls.mapOpenedFrom.value == 'pickup'){
              this.changeResidentialForm[this.MapFormIndex].patchValue({"pickupCenter": { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }});
              this.changeResidentialForm[this.MapFormIndex].patchValue({"pickupMarker" :[]});
               // Add a marker for the selected location
               let pickupMarker=[];
               pickupMarker.push({position: this.changeResidentialForm[this.MapFormIndex].controls.pickupCenter.value,label: '',title: prediction.description,options: { animation: google.maps.Animation.DROP
              }
              });
              this.changeResidentialForm[this.MapFormIndex].patchValue({"pickupMarker" :pickupMarker});
              // this.latitudePickup = results[0].geometry.location.lat(),
              // this.longitudePickup = results[0].geometry.location.lng()
              this.changeResidentialForm[this.MapFormIndex].patchValue({
                "latitudePickup":results[0].geometry.location.lat(),
                "longitudePickup":results[0].geometry.location.lng(),
                "pickupConfirmed": false
              })

               if (this.changeResidentialForm[this.MapFormIndex].get('tptType').value == 0) {
                this.changeResidentialForm[this.MapFormIndex].patchValue({'dropoffLocation':prediction.description})

                 this.changeResidentialForm[this.MapFormIndex].patchValue({ "dropoffCenter": { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() } });
                 this.changeResidentialForm[this.MapFormIndex].patchValue({ "dropoffMarker": [] });
                 // Add a marker for the selected location
                 let dropoffMarker = [];
                 dropoffMarker.push({
                   position: this.changeResidentialForm[this.MapFormIndex].controls.dropoffCenter.value, label: '', title: prediction.description, options: {
                     animation: google.maps.Animation.DROP
                   }
                 });
                 this.changeResidentialForm[this.MapFormIndex].patchValue({ "dropoffMarker": dropoffMarker })
                 //this.latitudeDropoff = results[0].geometry.location.lat(),
                 //this.longitudeDropoff = results[0].geometry.location.lng()
                 this.changeResidentialForm[this.MapFormIndex].patchValue({
                   "latitudeDropoff": results[0].geometry.location.lat(),
                   "longitudeDropoff": results[0].geometry.location.lng(),
                   "dropoffConfirmed": false
                 })
               }
              this.mapSearch =  prediction.description
 
              this.predictions=[];
             } else{
              this.changeResidentialForm[this.MapFormIndex].patchValue({"dropoffCenter" : { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }});
              this.changeResidentialForm[this.MapFormIndex].patchValue({"dropoffMarker" :[]}) ;
               // Add a marker for the selected location
               let dropoffMarker=[];
               dropoffMarker.push({position: this.changeResidentialForm[this.MapFormIndex].controls.dropoffCenter.value,label: '',title: prediction.description,options: { animation: google.maps.Animation.DROP
              }
              });
              this.changeResidentialForm[this.MapFormIndex].patchValue({"dropoffMarker" :dropoffMarker})
              //this.latitudeDropoff = results[0].geometry.location.lat(),
              //this.longitudeDropoff = results[0].geometry.location.lng()
              this.changeResidentialForm[this.MapFormIndex].patchValue({
                "latitudeDropoff":results[0].geometry.location.lat(),
                "longitudeDropoff":results[0].geometry.location.lng(),
                "dropoffConfirmed": false
              })
              this.mapSearch =  prediction.description
              this.predictions=[];
             }
             this.editAllChildFields(this.MapFormIndex);
         }
       });
     }

    addSiblingDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '500px',
      panelClass: 'custom-modalbox'
    });
  }
  successDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '550px',
      panelClass: 'custom-modalbox'
    });
  }
 
  submit(template:any)
  {
    let forms =[...this.residentialForms];
    forms.forEach(x=>{
      this.setResidentialForms(x.id,false) 
    })
    let body = this.residentialForms
    let token = JSON.parse(localStorage.getItem('currentToken'))?.access_token
    this.areaChangeService.postAreaChange(body,token).subscribe((res : any)=>{
      if(res?.success == "true")
      {
      this.referenceNo = res?.data;
      this.successDialog(template);
      }
    })
  }

  clearAreaSearch(type: any, formId: any){
    if(type == 'pickupArea'){
      this.changeResidentialForm[formId].patchValue({
        'pickupAreaMasterTemp': this.changeResidentialForm[formId].get('pickupAreaMaster').value
      });
    }else if(type == 'dropoffArea'){
      this.changeResidentialForm[formId].patchValue({
        'dropOffAreaMasterTemp': this.changeResidentialForm[formId].get('dropOffAreaMaster').value
      });
    }
  }
  clearPointSearch(type:any, formId:any){
    if (type == 'pickupPoint') {
      this.changeResidentialForm[formId].patchValue({
        'pickupPointMasterTemp': this.changeResidentialForm[formId].get('pickupPointMaster').value
      });
    } else if (type == 'dropoffPoint') {
      this.changeResidentialForm[formId].patchValue({
        'dropoffPointMasterTemp': this.changeResidentialForm[formId].get('dropoffPointMaster').value
      });
    }
  }
  clearSearchValue(event:any){
    event.target.value = '';
  }

  getFeeStructure(formId:any) {
    let provider: any;
    provider = JSON.parse(localStorage.getItem('schoolDetails'))
    let data: any = [];
    data.push({
      ACD_ID: this.studentDetails?.acdid ?? 0,
      TRIPTYPE: this.changeResidentialForm[formId].get('tptType').value ?? 0,
      SBLID: Number(this.changeResidentialForm[formId].get('pickupArea').value ?? 0),
      DSBLID: Number(this.changeResidentialForm[formId].get('dropoffArea').value ?? 0),
      STU_ID: 0,
      PROVIDER_BSU_ID: provider.provider,
      TRM_STARTDT: '',
      SSV_STARTDT: '',
    })
    this.areaChangeService.getFeeAmounts(this.token, data).subscribe(res => {
      this.tptFee = res.data;

      this.tptFee = this.tptFee.filter((x:any) => x.type == 2);

      if (this.tptFee.length > 0) {
        this.IsCloseFee = true
      } else {
        this.IsCloseFee = false

      }
    });
  }
  
  closeFee() {
    this.IsCloseFee = false
  }
}
