import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MessageComponent } from 'src/app/message/message.component';
import { RegistrationService } from 'src/app/service/registration.service';
import { ReregistrationService } from 'src/app/service/reregistration.service';
import { TokenService } from 'src/app/service/token.service';

import { UaepassService } from 'src/app/service/uaepass.service';
import * as html2pdf from 'html2pdf.js';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/service/config.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-uaepass-callback',
  templateUrl: './uaepass-callback.component.html',
  styleUrls: ['./uaepass-callback.component.scss']
})
export class UaepassCallbackComponent implements OnInit {

  constructor(private uaepasservice: UaepassService, private dialog: MatDialog, public router: Router,
    private tokenService: TokenService, public regService: RegistrationService,
    public reregservice:ReregistrationService,private translate: TranslateService,private configServe : ConfigService) { }
  defaultToken: any;
  pdfs: any[] = [];
  tandcfilteredDatapdf: any;
  BusBehaviourCodedatapdf: any;
  Parentundertakingapdf: any;
  files: File[] = []; //
  city :string ="";
  langTrans: any;
  currentUser: any;
from_Reregiser:boolean=false;
  ngOnInit(): void {
    debugger;
    this.translate.stream('uaepassmessages').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data.label;
      }
    });
   if(localStorage.getItem("fromReregister")=="true")
   {
     this.from_Reregiser=true;
   }
   else

   {
     this.from_Reregiser=false;
   }
   
  this.currentUser=  localStorage.getItem("reRegisterCurrentUser");
    const queryString = window.location.search;

    // Create a new URLSearchParams object with the query string
    const urlParams = new URLSearchParams(queryString);

    const errmsgfromuae=urlParams.get('error');
    if(errmsgfromuae)
    {
      if(errmsgfromuae=="cancelledOnApp" || errmsgfromuae=="invalid_request")
      {
        this.openMessageDialog(this.langTrans?.cancelledOnAppmsg,true,"");
        return;
      }
      if(errmsgfromuae=="access_denied" )
      {
        this.openMessageDialog(this.langTrans?.cancelledsigninmsg,true,"");
        return;
      }
      
    }
    // Get the value of the 'code' parameter
    const code = urlParams.get('code');

    const tokenURL = urlParams.get('tokenURL');
    const state1=urlParams.get('state');
    if(localStorage.processdocument=="success")
    {
     let ret_signed_url=tokenURL+"&code="+code;
     let signIdentityId=localStorage.signingIdentity;
     let txId=localStorage.txId;

     let primaryphone=localStorage.primaryMobileNo?.substring(4,localStorage.primaryMobileNo?.length);
     let stuschoolid=localStorage.studentDetails?JSON.parse(localStorage.studentDetails).stU_SCHOOL_ID:primaryphone;
     this.uaepasservice.DownloadSignedDoc(ret_signed_url,signIdentityId,txId,stuschoolid,localStorage.getItem("bsU_ID"),primaryphone).subscribe
     (downloadres=>
      {
       // alert(JSON.stringify(downloadres));
       const downloadresResponse: ApiResponse = JSON.parse(JSON.stringify(downloadres));
       if(downloadresResponse.success=="true")
       {
              const downloadresisSuccess = downloadresResponse.data.isSuccess;

             if(downloadresisSuccess)
             {
               localStorage.setItem("docdownloadurl",downloadresResponse.data.downloadUrl);
               this.openMessageDialog(this.langTrans?.successmsg,false,"");
             }
       }
       else
       {
        this.openMessageDialog(downloadresResponse.message, true, "DownloadSignedDoc: " + downloadresResponse.message);
       }

      })
      localStorage.removeItem("processdocument");
    }

    
    else if (tokenURL) {

      this.getToken(tokenURL, code);
    }
    else {
      this.uaepasservice.getUserDetails(code).subscribe(res => {
        // alert(JSON.parse(JSON.stringify(res)));
        const apiResponse: ApiResponse = JSON.parse(JSON.stringify(res));
        // alert(apiResponse.data.isSuccess);
        const isSuccess = apiResponse.data?.isSuccess;
        if (isSuccess) {
          let uaepassusermobile=apiResponse.data?.uaepassUserDetails?.mobile.replace(/-/g, '').slice(-8);
          let primaryfnumber="";
          let primarymnumber="";
          if(localStorage.getItem("fromReregister")=="true")
          {
            primaryfnumber=localStorage.getItem("primaryMobileNo").replace(/-/g, '').slice(-8);
            primarymnumber=localStorage.getItem("secondaryMobileNo").replace(/-/g, '').slice(-8);

          }
          else
          {
            primaryfnumber= JSON.parse( localStorage.parentDetails)[0]?.stS_FMOBILE.replace(/-/g, '').slice(-8);
          
            primarymnumber=JSON.parse( localStorage.parentDetails)[0]?.stS_MMOBILE.replace(/-/g, '').slice(-8);
          }

          if(uaepassusermobile !=primaryfnumber && uaepassusermobile != primarymnumber)
          {
            this.openMessageDialog(this.langTrans?.mobilenotmatchmsg, true, "");
            return;
          }
          else
          {

          if (apiResponse.data.uaepassUserDetails.userType == "SOP3") {

            this.uaepasservice.initateSigning().subscribe(signingresult => {
              const signingresultapiResponse: ApiResponse = JSON.parse(JSON.stringify(signingresult));
              if(signingresultapiResponse.success=="false")
              {
                this.openMessageDialog(signingresultapiResponse.message, true, "INITIATE_SIGNING: " + signingresultapiResponse.message);
              }
              const signingresultisSuccess = signingresultapiResponse.data?.isSuccess;
              if (signingresultisSuccess) {
                // alert(signingresultapiResponse.data.insitiateSigningURL);
                window.location.href = signingresultapiResponse.data.insitiateSigningURL;

              }
              else {
                this.openMessageDialog(signingresultapiResponse.data.uaepassErrorMessage.error, true, "INITIATE_SIGNING: " + apiResponse.data.uaepassErrorMessage.error_description);
              }

            })

          }
          else {
            if( apiResponse.data.uaepassUserDetails.userType == "SOP1")
            {
            this.openMessageDialog(this.langTrans?.sop1error, true, "");
            }
            if( apiResponse.data.uaepassUserDetails.userType == "SOP2")
            {
            this.openMessageDialog(this.langTrans?.sop2error, true, "");
            }
          }
        }
        }
        else {
          //alert(JSON.stringify( apiResponse.data.uaepassErrorMessage));
          this.openMessageDialog(apiResponse.data.uaepassErrorMessage.error, true, apiResponse.data.uaepassErrorMessage.error_description);

        }
      });
    }

  }

  getToken(tokenURL: any, code: any) {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
        if(this.from_Reregiser)
        {
          this.getTermsConditions_fromReReg(tokenURL,code);

        }
        else
        {
        this.getTermsConditions(tokenURL, code);
        }
      }
    )
  }

  getTermsConditions(tokenURL: any, code: any) {

    this.regService.getTermsandConditions(this.defaultToken).subscribe(res => {

      this.pdfs = res?.data
      // alert(JSON.stringify(this.pdfs));
      this.pdfs.forEach(x => x.isAgree = false);
      // Specify the value to filter by


      // Filter the array based on the specified property and value
      var vtermsac = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "TNC");
      // alert(vtermsac.length);
      if (vtermsac.length > 0) {
        this.tandcfilteredDatapdf = vtermsac.map(item => item.ttcuL_PDF_HTML);

      this.convertHtmlToPdf("<div style='margin: 20px 0px 10px 0px;font-size: 16px;font-weight: 500;color: #111111;'> "+this.tandcfilteredDatapdf+"</div>", "TnC.pdf");

        //  const tncBlob = new Blob(this.tandcfilteredDatapdf, { type: 'application/pdf' });
        // const tncFile = this.convertBlobToFile(tncBlob, 'TnC.pdf');
      //  this.files.push(tncFile);
        // alert(this.tandcfilteredDatapdf);
      }

      var vbusb = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "BBC");
      if (vbusb.length > 0) {
        this.BusBehaviourCodedatapdf = vbusb.map(item => item.ttcuL_PDF_HTML);
        this.convertHtmlToPdf("<div style='margin: 20px 0px 10px 0px;font-size: 16px;font-weight: 500;color: #111111;'> "+this.BusBehaviourCodedatapdf+"</div>", "BBC.pdf");
        // const bbcBlob = new Blob(this.BusBehaviourCodedatapdf, { type: 'application/pdf' });
        // const bbcfile = this.convertBlobToFile(bbcBlob, 'BBC.pdf');

        // this.files.push(bbcfile);
      }
      let primaryphone=localStorage.primaryMobileNo?.substring(4,localStorage.primaryMobileNo?.length);
      var parentUndertaking = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "PUT");
      if (parentUndertaking.length > 0) {
        this.Parentundertakingapdf = parentUndertaking.map(item => item.ttcuL_PDF_HTML);
        //const putBlob = new Blob(this.BusBehaviourCodedatapdf, { type: 'application/pdf' });
        this.convertHtmlToPdf(this.Parentundertakingapdf, "PUT.pdf");
        // const putFile = this.convertBlobToFile(putBlob, 'PUT_.pdf');
        //this.files.push(putFile);
      }
      setTimeout(() => {
       
      if (this.files.length > 0) {
        let signingurl: any = tokenURL + "&code=" + code;
        this.uaepasservice.processDocumnetSigning(signingurl, this.files).subscribe(
          signres => {
            const signresapiResponse: ApiResponse = JSON.parse(JSON.stringify(signres));
          //  alert(JSON.stringify(signres));
         if( signresapiResponse.success=="false")
         {
          this.openMessageDialog(signresapiResponse.message, true, signresapiResponse.message);

         }
         else
         {

              const signresSuccess = signresapiResponse.data.isSuccess;
              if(signresSuccess)
              {
              let signingIdentity=signresapiResponse.data.signingIdentity;
              let txId=signresapiResponse.data.txId;
              localStorage.setItem("txId",txId);
              localStorage.setItem("signingIdentity",signingIdentity);
             // alert(signresapiResponse.data.processSigningURL);
              window.location.href=signresapiResponse.data.processSigningURL+"&fromdoc=docs";
              localStorage.setItem("processdocument","success");
              }
              else
              {
                this.openMessageDialog(signresapiResponse.message, true, signresapiResponse.message);
              }
            }

          }
        );
      }
      else {
        this.openMessageDialog("No files found", true, "No files found");
      }
      
    }, 3000);
    
    });
  }

  //if from re-registration
  getTermsConditions_fromReReg(tokenURL: any, code: any) {

    this.reregservice.getTermsAndConditions().subscribe((res:any) => {

      this.pdfs = res?.data
      // alert(JSON.stringify(this.pdfs));
      this.pdfs.forEach(x => x.isAgree = false);
      // Specify the value to filter by


      // Filter the array based on the specified property and value
      var vtermsac = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "TNC");
      // alert(vtermsac.length);
      if (vtermsac.length > 0) {
        this.tandcfilteredDatapdf = vtermsac.map(item => item.ttcuL_PDF_HTML);

      this.convertHtmlToPdf("<div style='margin: 20px 0px 10px 0px;font-size: 16px;font-weight: 500;color: #111111;'> "+this.tandcfilteredDatapdf+"</div>", "TnC.pdf");

        //  const tncBlob = new Blob(this.tandcfilteredDatapdf, { type: 'application/pdf' });
        // const tncFile = this.convertBlobToFile(tncBlob, 'TnC.pdf');
      //  this.files.push(tncFile);
        // alert(this.tandcfilteredDatapdf);
      }

      var vbusb = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "BBC");
      if (vbusb.length > 0) {
        this.BusBehaviourCodedatapdf = vbusb.map(item => item.ttcuL_PDF_HTML);
        this.convertHtmlToPdf("<div style='margin: 20px 0px 10px 0px;font-size: 16px;font-weight: 500;color: #111111;'> "+this.BusBehaviourCodedatapdf+"</div>", "BBC.pdf");
        // const bbcBlob = new Blob(this.BusBehaviourCodedatapdf, { type: 'application/pdf' });
        // const bbcfile = this.convertBlobToFile(bbcBlob, 'BBC.pdf');

        // this.files.push(bbcfile);
      }
      let primaryphone=localStorage.primaryMobileNo?.substring(4,localStorage.primaryMobileNo?.length);
      var parentUndertaking = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "PUT");
      if (parentUndertaking.length > 0) {
        this.Parentundertakingapdf = parentUndertaking.map(item => item.ttcuL_PDF_HTML);
        //const putBlob = new Blob(this.BusBehaviourCodedatapdf, { type: 'application/pdf' });
        this.convertHtmlToPdf(this.Parentundertakingapdf, "PUT.pdf");
        // const putFile = this.convertBlobToFile(putBlob, 'PUT_.pdf');
        //this.files.push(putFile);
      }
      setTimeout(() => {
       
      if (this.files.length > 0) {
        let signingurl: any = tokenURL + "&code=" + code;
        this.uaepasservice.processDocumnetSigning(signingurl, this.files).subscribe(
          signres => {
            const signresapiResponse: ApiResponse = JSON.parse(JSON.stringify(signres));
          //  alert(JSON.stringify(signres));
         if( signresapiResponse.success=="false")
         {
          this.openMessageDialog(signresapiResponse.message, true, signresapiResponse.message);

         }
         else
         {

              const signresSuccess = signresapiResponse.data.isSuccess;
              if(signresSuccess)
              {
              let signingIdentity=signresapiResponse.data.signingIdentity;
              let txId=signresapiResponse.data.txId;
              localStorage.setItem("txId",txId);
              localStorage.setItem("signingIdentity",signingIdentity);
             // alert(signresapiResponse.data.processSigningURL);
              window.location.href=signresapiResponse.data.processSigningURL+"&fromdoc=docs";
              localStorage.setItem("processdocument","success");
              }
              else
              {
                this.openMessageDialog(signresapiResponse.message, true, signresapiResponse.message);
              }
            }

          }
        );
      }
      else {
        this.openMessageDialog("No files found", true, "No files found");
      }
      
    }, 3000);
    
    });
  }
  //end
  convertHtmlToPdf(htmlContent: string, filename: string) {
    this.city = JSON.parse(localStorage.getItem("schoolDetails"))?.city;
    const element = document.createElement('div');
    const fragment = document.createDocumentFragment();
    var domain = this.regService.appUrl;
    var pdfLogo = (this.city == "Dubai" || this.city == "Qatar")
      ? domain + "/assets/images/sts-logo.png"
      : domain + "/assets/images/bbt-logo.png";
  
    let doctitle = filename.includes('BBC') ? "Bus Behaviour Code" 
                  : filename.includes('PUT') ? "Parent Undertaking" 
                  : "Terms and Conditions";
  
    var pdftitle = (this.city == "Dubai" || this.city == "Qatar") ? "School Transport Services LLC" : "Bright Bus Transport";
    var headerContent = `<table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
      <tr style="border: 1px solid black;">
          <td style="width:80px;height:40px;text-align:center; border: 1px solid black;">
              <img style="width:40px;height:40px" src=${pdfLogo}>
          </td>
          <td style="font-weight:bold;font-size: 18px;text-align:center; border: 1px solid black;">
              ${pdftitle}
          </td>
      </tr>
      <tr style="border: 1px solid black;">
          <td></td>
          <td style="font-weight:bold;font-size: 18px;text-align:center; margin-top:5px; margin-bottom:5px;">
              ${doctitle}
          </td>
      </tr>
      <tr style="border: 1px solid black;">
          <td style="border: 1px solid black;font-weight:bold;font-size: 18px;">Signature:</td>
          <td style="font-weight:bold;font-size: 18px;text-align:center; border: 1px solid black; margin-top:5px; margin-bottom:5px;"></td>
      </tr>
    </table>`;
  
    element.innerHTML = this.removeMetadata(headerContent + htmlContent);
    // document.body.appendChild(element);
    fragment.appendChild(element);
    // Define optimized options
    const options = {
      margin: [0.3, 0.3, 0.6, 0.3],  // Optimized margins
      filename: filename,
      image: { type: 'jpeg', quality: 0.75 },  // Reduced image quality
      html2canvas: { scale: 1.5 },  // Lower scale for smaller file size
      jsPDF: { unit: 'in', format: [8.268, 11.693], orientation: 'portrait', compress: true } // Compress PDF
    };
  
    //document.body.style.opacity = "0"
    // Convert HTML to PDF
    html2pdf().from(element).set(options).outputPdf('blob').then((blob: Blob) => {
      
      const bbcfile = this.convertBlobToFile(blob, filename);
      this.files.push(bbcfile);
    })
    .finally(() => {
      document.body.removeChild(element);
    //  document.body.style.opacity = "1";  // Restore visibility
    })
    ;
  }
  
  // convertHtmlToPdf(htmlContent: string, filename: string) {
  //   // Create a new element to hold the HTML content
  //   this.city = JSON.parse(localStorage.getItem("schoolDetails"))?.city;
  //   const element = document.createElement('div');
    
  //   var domain=this.regService.appUrl;
  //   var pdfLogo = (this.city == "Dubai" || this.city == "Qatar")? domain+"/assets/images/sts-logo.png": domain+"/assets/images/bbt-logo.png";
  //   //this.convertToBase64(pdfLogo);
  //     var content:any;
  //     var headerOption= {
  //       tableAutoSize:true,
  //       imagesByReference:true
  //     }
  //     let doctitle=filename;
  //     if(filename.indexOf('BBC')!=-1)
  //     {
  //       doctitle="Bus Behaviour Code";
  //     }
  //     else if(filename.indexOf('PUT')!=-1)
  //     {
  //       doctitle='Parent Undertaking';
  //     }
  //     else
  //     {
  //       doctitle="Terms and Conditions";
  //     }
      
  //     var pdftitle = (this.city == "Dubai" || this.city == "Qatar") ? "School Transport Services LLC":"Bright Bus Transport"
  //     var headerContent= `<table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
  //     <tr style="border: 1px solid black;">
  //         <td style="width:80px;height:40px;text-align:center; border: 1px solid black;">
  //             <img style="width:40px;height:40px" src=${pdfLogo}>
  //         </td>
  //         <td style="font-weight:bold;font-size: 18px;text-align:center; border: 1px solid black;">
  //             ${pdftitle}
  //         </td>
  //     </tr>
  //     <tr style="border: 1px solid black;">
  //         <td></td>
  //         <td style="font-weight:bold;font-size: 18px;text-align:center; margin-top:5px; margin-bottom:5px;">
  //             ${doctitle}
  //         </td>
  //     </tr>
  //     <tr style="border: 1px solid black;">
  //         <td style="border: 1px solid black;font-weight:bold;font-size: 18px;">Signature:</td>
  //         <td style="font-weight:bold;font-size: 18px;text-align:center; border: 1px solid black; margin-top:5px; margin-bottom:5px;">
  
  //         </td>
  //     </tr>
  // </table>`

  //   // Append the element to the document body
  //   // alert(htmlContent);
  //   // alert(headerContent);
  //   // alert(headerContent+htmlContent);
  //  // element.innerHTML = headerContent+htmlContent;
     
  //   element.innerHTML=this.removeMetadata(headerContent+htmlContent);

  //   // Define options for html2pdf
  //   const options = {
  //     margin: [0.5, 0.5, 0.10, 0.5], // margins: top, right, bottom, left
  //     filename: 'document.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format:  [8.268, 11.693], orientation: 'portrait' }
  //   };
  //   document.body.style.opacity="0";
  //   document.body.appendChild(element);
  //   // Convert HTML to PDF
  //   html2pdf().from(element).set(options).outputPdf('blob').then((blob: Blob) => {
  //     // Remove the element from the document
  //     //document.body.style.opacity="1"
  //     document.body.removeChild(element);

  //     // Handle the Blob, for example, save it or upload it
  //     // Save the Blob as a file
  //     const bbcfile = this.convertBlobToFile(blob, filename);
  //     this.files.push(bbcfile);
      
  //   });
  // }
  
  removeMetadata(html: string): string {
    const metadataRegex = /<\s*(meta|title|link|style|script)[^>]*>.*?<\/\s*\1\s*>/gi;
    const nonContentRegex = /<\s*(head|script|style)[^>]*>.*?<\/\s*\1\s*>/gi;

    html = html.replace(metadataRegex, '');
    html = html.replace(nonContentRegex, '');
    return html;
  }
  private convertBlobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: 'application/pdf' });
  }

  downloadBlob(blob: Blob, filename: string): void {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url); // Clean up
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });
    let redirecturi=this.configServe.uaepass_signoutUrl;
    let appuri=this.configServe.appUrl;
    let naviurl=redirecturi+appuri;

    //const baseUrl = "https://qa.phoenixsystem.ae/stsconnect/re-registration";
const reregistrationqueryParams = {
    isAgreed: "true",
    checkTerms: "true"
};

const registrationqueryParams = {
  tab:"3",
  isAgreed: "true",
  isReactivate: "false"
};
const encodedQueryreregistration = Object.keys(reregistrationqueryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(reregistrationqueryParams[key])}`)
    .join("&");

    const encodedQueryregistration = Object.keys(registrationqueryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(registrationqueryParams[key])}`)
    .join("&");

//const fullUrl = `${baseUrl}?${encodedQuery}`;


    dialogRef.afterClosed().subscribe(result => {
     // alert(naviurl+"/schoolbus-registration?tab=3&isAgreed=true&isReactivate=false");
     if(this.from_Reregiser)
     {
       //https://stg-id.uaepass.ae/idshub/logout?redirect_uri=https%3A%2F%2Fqa.phoenixsystem.ae%2Fstsconnect%2Fschoolbus-registration%3Ftab%3D3%26isAgreed%3Dtrue%26isReactivate%3Dfalse

       //isAgreed=true&checkTerms=true
       const naviurl1= naviurl+"/re-registration";
       const fullUrl = `${naviurl1}?${encodedQueryreregistration}`;
       //window.location.href = naviurl+"/re-registration?isAgreed=true&checkTerms=true";
       window.location.href = naviurl+"/re-registration?uaesignoutr=true";
     // this.router.navigate([naviurl+"/re-registration"],{queryParams:{isAgreed:true,checkTerms:true}});

     }
     else
     {
      const naviurl2= naviurl+"/schoolbus-registration";
      const fullUrl1 = `${naviurl2}?${encodedQueryregistration}`;
     // const parms=naviurl+"/schoolbus-registration?tab=3&isAgreed=true&isReactivate=false";
      const parms=naviurl+"/schoolbus-registration?uaesignout=true";
     // window.location.href = fullUrl1; 
     //%3Ftab%3D3%26isAgreed%3Dtrue%26isReactivate%3Dfalse
   //  alert( naviurl+"/schoolbus-registration%3Ftab%3D3%26isAgreed%3Dtrue%26isReactivate%3Dfalse");
   
     window.location.href=parms;
     //"https://stg-id.uaepass.ae/idshub/logout?redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fschoolbus-registration%3Ftab%3D3%26isAgreed%3Dtrue%26isReactivate%3Dfalse";

     // naviurl+"/schoolbus-registration&tab=3&isAgreed=true&isReactivate=false";
     // this.router.navigate([naviurl+"/schoolbus-registration"],{queryParams:{tab:3,isReactivate:false,isAgreed:true}});
     }
    });
  }


}
// Define interfaces to match the JSON structure
interface UaepassUserDetails {
  sub: string;
  fullnameAR: string;
  idType: string;
  gender: string;
  mobile: string;
  lastnameEN: string;
  fullnameEN: string;
  uuid: string;
  lastnameAR: string;
  idn: string;
  nationalityEN: string;
  firstnameEN: string;
  userType: string;
  nationalityAR: string;
  firstnameAR: string;
  email: string;

}

interface UaepassResponse {
  uaepassUserDetails: UaepassUserDetails;
  uaepassErrorMessage: Errorresponse | null;
  isSuccess: boolean;
  insitiateSigningURL: string;
  processSigningURL:string;
  signingIdentity:string;
  txId:string;
  downloadUrl:string;
}

interface ApiResponse {
  cmd: string;
  success: string;
  responseCode: string;
  message: string;
  data: UaepassResponse;
}
interface Errorresponse {
  error_description: string;
  error: string
}


