import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../models/app-config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends AppConfig {

  constructor(private http: HttpClient) { super(); }

  loadConfig() {
   
    return this.http.get('assets/environmentValues/environmentValues.json?v='+new Date().getMilliseconds())
      .pipe(map(
        (config: any) => {
         
          //console.log(config);
          this.AppId = config.AppId;
          this.grantType = config.grantType;
          this.baseUrl = config.baseUrl;
          this.localapi = config.localapi;
          this.client = config.client;
          this.key = config.key;
          this.source = config.source;
          this.deviceId = config.deviceId;
          this.applicationId = config.applicationId;
          this.applicationSource = config.applicationSource;
          this.language = config.language;
          this.receiptHeaderImage = config.receiptHeaderImage;
          this.logoimageUrl = config.logoimageUrl;
          this.paymentTo = config.paymentTo;
          this.paymentOrigin = config.paymentOrigin;
          this.paymentredirectURL = config.paymentredirectURL;
          this.portalTitle = config.portalTitle;
          this.portalShortCode = config.portalShortCode;
          this.paymentReceptHeader = config.paymentReceptHeader;
          this.idleTimeout = config.idleTimeout;
          this.timeoutPeriod = config.timeoutPeriod;
          this.redirectionURL=config.redirectionURL;
          this.appUrl=config.applicationUrl;
          this.captchaAPIKey = config.captchaAPIKey;
          this.otpTimerExpiry = config.otpTimerExpiry;
          this.forgotPasswordOTPExpiry = config.forgotPasswordOTPExpiry;
          this.paymentreenrollOrigin=config.paymentreenrollOrigin;
          this.chatbotSetting=config.chatbotSettings;
          this.baseUIUrl=config.baseUIUrl;
          this.IDCardSourcePortal = config.IDCardSourcePortal,
          this.uaepasscallbackurl=config.uaepasscallbackurl;
          this.uaepass_source=config.uaepass_source;
          this.uaepass_authorize_uri=config.uaepass_authorize_uri;
          this.uaepass_signoutUrl=config.uaepass_signoutUrl;

        }
      ));

  }
}