import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FormControl, FormGroup } from 'ngx-typesafe-forms';
import { MessageComponent } from 'src/app/message/message.component';
import { studentDetail } from 'src/app/models/registration.model';
import { RegistrationService } from 'src/app/service/registration.service';
import { TokenService } from 'src/app/service/token.service';
import { FileService } from 'src/app/service/file.service';
import { ConfigService } from 'src/app/service/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-student-information',
  templateUrl: './student-information.component.html',
  styleUrls: ['./student-information.component.scss']
})
export class StudentInformationComponent implements OnInit {
  studentForm = new FormGroup<any>({
    schoolId : new FormControl<any>('',[Validators.required]),
    firstName:new FormControl<any>('',[Validators.required]),
    middleName:new FormControl<any>(''),
    lastName:new FormControl<any>('',[Validators.required]),
    gender:new FormControl<any>('',[Validators.required]),
    grade:new FormControl<any>('',[Validators.required]),
    dob:new FormControl<any>('',[Validators.required]),
    academicYr:new FormControl<any>('',[Validators.required]),
    studentId:new FormControl<any>(''),
    serviceDate:new FormControl<any>('',[Validators.required])
  })
  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   window.scrollTo(0, 0);
  // }
  medicalRadiobtn:any="2";
  medicalDesc:any;
  medicalRadiobtn2:any="2";
  medicalDesc2:any;
  defaultToken: any;
  gradeList:any[]=[];
  selectedgrades:any[]=this.gradeList;
  count: number=0;
  count2:number=0;
  minDescErr:boolean=false;
  minDescErr2:boolean=false;
  selectedFile: File | null = null;
  imageUrl: string  | null = "";
  imgErr:boolean=false;
  promptEnable:boolean=false;
  studentDetails:studentDetail;
  selectedStudent:any;
  studentList:any[]=[];
  studentDialog: MatDialogRef<any, any>;
  @ViewChild('editStudentInfo')editStudent:TemplateRef<any>;
  studentSchIds:any[]=[];
  infoCount:number=0;
  msg: string;
  studentIdDialog:any
  file: File
  schoolList:any[]=[];
  academicYrList:any[]=[];
  currentDate: Date = new Date();
  minDate:Date= new Date("2025-04-07");//new Date(new Date().setDate(new Date().getDate() + 1));
  maxDate:Date=new Date(new Date().getFullYear()-1, null,null);
  @ViewChild("StudentInfo")StudentInfo:TemplateRef<any>;
  @Output() onTabChange:EventEmitter<any>=new EventEmitter();

  academicYr: any;
  serviceDate: any;
  disableStudents:any;
  academicYrId: any;
  imgSaveUrl:any;
  isAddSibling: boolean=false;
  enableSchool: boolean=false;
  disableAddSibling: boolean=false;
  fileType: string;
  fileFormatErr: boolean=false;
  msgForId:any
  hasLoginUser:boolean=false;
  studentStatus: any;
  statusDialog: any;
  @ViewChild('statusMsg')statusMsg:TemplateRef<any>;
  isAddSiblingFromParent: boolean=false;
  isHybrid:string="false";
  isLeased:string="false";
  langTrans: any;
  constructor(private dialog: MatDialog,private tokenService: TokenService,
    public regService:RegistrationService,private router: Router,private datePipe: DatePipe
    ,private fileService: FileService,private configService:ConfigService, private activatedRoute: ActivatedRoute, private translate: TranslateService) {
  }

  ngOnInit(): void {

    let schoolDetails = JSON.parse(localStorage.getItem('schoolDetails'));

    if (schoolDetails?.bsu_city?.toLowerCase() == "wkr") {
      this.minDate = new Date("2024-09-01");
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (params?.isFromDashboard) {
        this.isHybrid = JSON.parse(localStorage.getItem('IsHybrid')) == 0 ? 'false' : 'true';
        this.isLeased = JSON.parse(localStorage.getItem('leasemodelschool')) == 0 ? 'false' : 'true';
      }else{
        this.isHybrid = localStorage.getItem('ishybrid');
        this.isLeased = localStorage.getItem('isLeased');
      }
    });

    this.updateStudentIdValidator();

    if (this.currentDate > this.minDate) {
      this.minDate = new Date(new Date().setDate(new Date().getDate() + 1));
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  let loginUser = localStorage.getItem("loginuser");
  this.hasLoginUser=loginUser?true:false
    this.getToken();
 // this.assignSavedFile();

    this.translate.stream('registration').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data.label;
        this.msgForId = data.label.pleaseProvideStudentId;
      }
    });
  }

  updateStudentIdValidator(){
    const validators = (this.isLeased == 'false' && this.isHybrid == 'false') ? [Validators.required] : [];
    this.studentForm.controls['studentId'].setValidators(validators);
    this.studentForm.controls['studentId'].updateValueAndValidity();
  }

assignSavedFile()
{
  let savedFile: File = this.fileService.getFile();

if (savedFile !== null && savedFile !== undefined) {
  const reader = new FileReader();
  reader.readAsDataURL(savedFile);
  reader.onload = (event:any) => {
    // 'result' contains the binary data

    this.imageUrl=event.target.result;

  };

  // Read the content of the file as binary data
  //reader.readAsBinaryString(savedFile);
}

}
handleBinaryData(binaryData: string) {
  // Do something with the binary data
  console.log(binaryData);
  // You can use the binary data as needed, for example, sending it to a server or processing it further
}
  getSchoolList() {
    let schooldata:any;
    schooldata = JSON.parse(localStorage.getItem('schoolDetails'));
    this.regService.getSchoolList(this.defaultToken,schooldata?.country).subscribe(res=>{
      this.schoolList=res.filter(a=>a.isReregisterEnable==true);
      let bsuId = localStorage.getItem('bsU_ID');
      let school=this.schoolList.find(x=>x.bsU_ID == bsuId);
      this.studentForm.patchValue({schoolId:school.schoolID})
      let data = JSON.parse(localStorage.getItem('studentDetails'));
     let proceed = localStorage.getItem('proceed')
     let showSchool =localStorage.getItem("showSchool")
     this.enableSchool=showSchool== "true" ? true : false
     if(data != "" && data != null && proceed == "false")
       {
         let value=localStorage.getItem("addSiblingFromParent")
         this.isAddSiblingFromParent=value == "true"?true:false;
        data.imgPath=localStorage.getItem("photopath");
        if(data.imgPath.includes(environment.photopath))
        {
          data.imgPath=data.imgPath.replace(environment.photopath, '');
        }
        data.stU_PHOTOPATH =environment.photopath+data.imgPath;
        this.selectedStudent=data;
        this.mapStudent();
       }
      else this.getStudentDetails();
    })
  }
  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
     // this.getAcademicYrs();
      this.getSchoolList();
      }
    )
  }

  getAcademicYrs()
  {

    if(this.serviceDate)
    {
    this.regService.getAcademicYrs(this.defaultToken,this.serviceDate).subscribe(res=>{
     this.academicYrList=res?.data;
    this.studentForm.patchValue({'academicYr':this.academicYrList[0].descr})
    this.ChangeAcademicYrs();
    });
  }
  }

  getStudentDetails()
  {
    let dataFetched=true;
    this.regService.sendStudentDetails.subscribe(res=>{
      if(dataFetched)
      {
      dataFetched=false;
      this.studentList=res;
    //let disableStudent=localStorage.getItem("disableIds");
    if(this.studentList)
    {
      this.studentList.forEach(x=>{
        x.isClicked = false;
        x.isDisable=false;
        x.transportStatus= x.transportStatus? '('+x.transportStatus+')':""
        x.imgPath=x.stU_PHOTOPATH;
        if(x.imgPath.includes(environment.photopath))
        {
          x.imgPath=x.imgPath.replace(environment.photopath, '');
        }
        x.stU_PHOTOPATH =environment.photopath+x.imgPath;
        // if(disableStudent != "" && disableStudent != null)
        // {
        //    if(Number(disableStudent) == x.stU_ID)
        //    x.isDisable=true;
        // }
      });
      if(this.studentList.length <= 1)
      {
         if(this.studentList[0]?.stU_SCHOOL_ID || this.studentList[0]?.stU_ID)
         this.isAddSibling=true;
         else this.isAddSibling=false;
      }
      else this.isAddSibling=true;
      localStorage.setItem("proceed","false");
      if(this.isAddSibling)
      this.editInfoDialog(this.editStudent);
    }
    else {
    this.isAddSibling=false;
    this.resetForm();
    this.selectedStudent=null;
    let bsuId = localStorage.getItem('bsU_ID');
    let school=this.schoolList.find(x=>x.bsU_ID == bsuId);
    this.studentForm.patchValue({schoolId:school.schoolID})
    }
  }
    })
  }
  getGrades()
  {
    this.regService.getGrades(this.defaultToken).subscribe(res=>{
    this.gradeList=res?.data;
    this.selectedgrades=this.gradeList;
    if(this.selectedStudent)
    {
      let grade = this.gradeList.find(x=>x.id == Number(this.selectedStudent.stU_GRM_ID))
      if(grade)
      this.studentForm.patchValue({ grade:Number(this.selectedStudent.stU_GRM_ID)})
    }
    })
  }
  ChangeAcademicYrs()
  {
    localStorage.removeItem('currentAcademicYear')
    localStorage.removeItem('currentAcademicYearId')
    if(this.studentForm.controls.academicYr.value)
    {
      this.academicYr=this.studentForm.controls.academicYr.value;
      this.academicYrId=this.academicYrList.find(x=>x.descr == this.academicYr)?.id;
      localStorage.setItem('currentAcademicYear', this.academicYr)
      localStorage.setItem('currentAcademicYearId', this.academicYrId)
      this.getGrades();
    }
  }
  changeDOB(event){
    let dob = event.value._d;
    this.studentForm.controls['dob'].setValue(this.datePipe.transform(dob, 'yyyy-MM-dd'))
  }
  changeServiceDate(event){
    let serviceDate = event.value._d;
   this.studentForm.controls['serviceDate'].setValue(this.datePipe.transform(serviceDate, 'yyyy-MM-dd'));
   this.serviceDate=this.studentForm.get('serviceDate').value
   this.getAcademicYrs();
  }
  changeSchoolId()
  {
    let isID:any;
    if(this.studentForm.controls.studentId.value && this.studentForm.controls.studentId.touched)
    {
       isID =  this.studentForm.controls.studentId.value;
       this.regService.studentAvailabilityCheck(this.defaultToken,isID).subscribe(res=>{
        if(res && this.infoCount==0)
        {
          this.msg="The student ID has been previously registered as per our records, please resubmit the student information."
          this.infoCount=1;
          this.studentForm.patchValue({studentId:""});
          this.studentInfoDialog(this.StudentInfo)
        }
        else if(res && this.infoCount>0)
        {
          this.infoCount=2;
          this.msg="Please get in touch with STS/BBT office at your school for support."
          this.studentForm.patchValue({studentId:""});
          this.studentInfoDialog(this.StudentInfo)
        }
        else if(!res)
        {
          this.infoCount=0;
        }
       })
    }
  }
  // selectSchool(event:any)
  // {
  //   this.schoolName=event.value
  //   if(this.schoolName == "" || this.schoolName==null)
  //   this.SchNameErr=true;
  //   else this.SchNameErr=false;
  // }
  addSibling()
  {
    this.studentDialog.close();
    this.isAddSibling=true;
    this.enableSchool=true;
    this.resetForm();
    this.selectedStudent=null;
    let bsuId = localStorage.getItem('bsU_ID');
    let school=this.schoolList.find(x=>x.bsU_ID == bsuId);
    this.studentForm.patchValue({schoolId:school.schoolID})
  }
  ok()
  {
    this.studentIdDialog.close();
    if(this.infoCount==2)
    this.router.navigate(['/']);
  }
 editInfoDialog(template: TemplateRef<any>) {
   let index=this.studentList.findIndex(x=>x.isPartial == 1);
   if(index != -1)
   {
   this.disableAddSibling=true;
   //this.enableSchool=false
   }
   else {this.disableAddSibling=false;
   // this.enableSchool=true
   }
   this.enableSchool=false
   this.studentDialog= this.dialog.open(template, {
      disableClose: true,
      width: '575px',
      panelClass: 'custom-modalbox'
    });
    // this.studentDialog.backdropClick().subscribe(() => {
    //   this.studentDialog.close();
    //   this.addSibling();
    // })
  }
  selectStudent(student:any)
  {
   

    if(student.transportStatus=="(Request in Draft Mode)")
    {
      this.enableSchool=true;
    }
    let studentList:any[]=[];
    this.isAddSibling=false;
   // this.studentForm.controls.schoolId.clearValidators();
   this.fileFormatErr=false;
    studentList=this.studentList;
    studentList.forEach(x=>{
      let id= x.stU_ID ? x.stU_ID:x.stU_SCHOOL_ID
      let compareId= x.stU_ID ?student.stU_ID : student.stU_SCHOOL_ID
      if(id==compareId)
      x.isClicked=true;
      else x.isClicked=false;
    })
    this.studentList=[...studentList];
    setTimeout(() => {
      this.studentDialog.close();
    }, 400);
    if(student.disableRegistration == 0 && student.transportMessage)
    {
       this.studentStatus=student.transportMessage;
       this.msgDialog(this.statusMsg);
    }else{
    this.selectedStudent=student;
    if(this.selectedStudent)
    {
     this.mapStudent();
    }
  }
  }

  msgDialog(template: TemplateRef<any>) {
    this.statusDialog= this.dialog.open(template, {
       disableClose: true,
       width: '575px',
       panelClass: 'custom-modalbox'
     });
   }

   okMsg()
  {
    this.statusDialog.close();
    this.router.navigate(['/home']);
  }
  mapStudent()
  {
    let serviceFormattedDate:any;
    let formattedDate:any
  //  if(!fromStorage)
  //  {
  //   const parsedDate = moment(this.selectedStudent.stU_DOB, 'MMM DD YYYY h:mmA').toDate();
  //   formattedDate = moment(parsedDate).format('YYYY-MM-DD HH:mm:ss');

  //   const serviceParseDate=moment(this.selectedStudent.busServiceRequestedDate, 'MMM DD YYYY h:mmA').toDate();
  //    serviceFormattedDate = moment(serviceParseDate).format('YYYY-MM-DD HH:mm:ss');
  //  }
  //  else{
    if(this.selectedStudent.stU_DOB)
    {
    const stringDate = new Date(this.selectedStudent.stU_DOB);
     formattedDate = this.datePipe.transform(stringDate, 'yyyy-MM-dd')
    }

     if(this.selectedStudent.busServiceRequestedDate)
     {
     const serviceParseDate=new Date(this.selectedStudent.busServiceRequestedDate);
     if(serviceParseDate)
     {
     serviceFormattedDate = this.datePipe.transform(serviceParseDate, 'yyyy-MM-dd');
     }
     }
  // }
   // this.selectedStudent.stU_PHOTOPATH=this.configService.photopath+this.selectedStudent.stU_PHOTOPATH;
   let bsuId = this.selectedStudent.stU_BSU_ID ?this.selectedStudent.stU_BSU_ID : localStorage.getItem('bsU_ID');
   let school=this.schoolList.find(x=>x.bsU_ID == bsuId);
   if(school)
   localStorage.setItem("schoolName",school?.schoolName)
   localStorage.setItem('bsU_ID', school?.bsU_ID);
   let schoolDetails ={
    city:school?.city,
    bsu_city:school?.citycode,
    provider:school?.provider,
    country:school?.country,
    currency:school?.bsU_CURRENCY
  }
  localStorage.setItem('schoolDetails', JSON.stringify(schoolDetails));
    this.studentForm.patchValue({
      //schoolName : this.selectedStudent.stU_FIRSTNAME,
  firstName:this.selectedStudent.stU_FIRSTNAME,
  middleName:this.selectedStudent.stU_MIDNAME,
  lastName:this.selectedStudent.stU_LASTNAME,
  gender:this.selectedStudent.stU_GENDER,
  //grade:this.selectedStudent.stU_GRD_ID,
  dob:new Date(formattedDate),
  studentId:this.selectedStudent.stU_SCHOOL_ID,
  schoolId:school?.schoolID,
  serviceDate:serviceFormattedDate
    })
    this.serviceDate=this.studentForm.get('serviceDate').value;
    this.getAcademicYrs();
    this.imageUrl=this.selectedStudent.stU_PHOTOPATH;

    this.assignSavedFile();
    this.imgSaveUrl=this.selectedStudent.imgPath;
    this.medicalDesc=this.selectedStudent.stU_MED_CONDITION;
    this.onDescChange();
    this.medicalRadiobtn = this.selectedStudent.stS_MED == 1 ? "1":"2";
    this.medicalDesc2=this.selectedStudent.stS_MED_STAFF_REQUIRED_DESC;
    this.onDescChange2();
    this.medicalRadiobtn2 = this.selectedStudent.stS_MED_STAFF_REQUIRED  == 1 ? "1":"2";

   // this.studentForm.patchValue({ grade:Number(this.selectedStudent.stU_GRD_ID)})

  //   let storageData = JSON.parse(localStorage.getItem('parentDetails'))
  //   let parent = storageData[0];
  //   let formData={
  //     "stU_ID": this.selectedStudent.stU_ID,
  //     "stU_FIRSTNAME": this.selectedStudent.stU_FIRSTNAME,
  //     "stU_MIDNAME": this.selectedStudent.stU_MIDNAME,
  //     "stU_LASTNAME": this.selectedStudent.stU_LASTNAME,
  //     "stU_GENDER": this.selectedStudent.stU_GENDER,
  //     "stU_GRD_ID": this.selectedStudent.stU_GRD_ID,
  //     "stU_DOB": formattedDate,
  //     "stU_SCHOOL_ID": this.selectedStudent.stU_SCHOOL_ID,
  //     "stU_PHOTOPATH": this.selectedStudent.stU_PHOTOPATH,
  //     "stU_SPMEDICATION":this.selectedStudent.stU_SPMEDICATION,
  //     "stU_PRIMARYCONTACT": parent?.stU_PRIMARYCONTACT ?? '',
  //     "stS_FFIRSTNAME": parent?.stS_FFIRSTNAME ?? '',
  //     "stS_FMIDNAME": parent?.stS_FMIDNAME ?? '',
  //     "stS_FLASTNAME": parent?.stS_FLASTNAME ?? '',
  //     "stS_FMOBILE": parent?.stS_FMOBILE ?? '',
  //     "stS_FEMAIL": parent?.stS_FEMAIL ?? '',
  //     "stS_MFIRSTNAME": parent?.stS_MFIRSTNAME ?? '',
  //     "stS_MMIDNAME": parent?.stS_MMIDNAME ?? '',
  //     "stS_MLASTNAME": parent?.stS_MLASTNAME ?? '',
  //     "stS_MMOBILE": parent?.stS_MMOBILE ?? '',
  //     "stS_MEMAIL": parent?.stS_MEMAIL ?? '',
  //     "stS_GFULLNAME":parent?.stS_GFULLNAME ?? '',
  //     "stS_GMOBILE": parent?.stS_GMOBILE ?? '',
  //     "stS_GEMAIL": parent?.stS_GEMAIL ?? '',
  //     "disableRegistration": parent?.disableRegistration,
  //     "city": parent?.city ?? '',
  //   "house": parent?.house ?? '',
  //   "street": parent?.street ?? '',
  //   "transportType": parent?.transportType ?? '',
  //   "pickupArea": parent?.pickupArea ?? '',
  //   "pickupPoint": parent?.pickupPoint ?? '',
  //   "pickupPointLandMark": parent?.pickupPointLandMark ?? '',
  //   "doopoffArea": parent?.doopoffArea ?? '',
  //   "dropoffPoint": parent?.dropoffPoint ?? '',
  //   "dropoffPointLandMark": parent?.dropoffPointLandMark ?? '',
  //   "busServiceRequestedDate": serviceFormattedDate,
  //   "picklat":parent?.picklat ?? 0,
  //   "picklog":parent?.picklog ?? 0,
  //   "droplat":parent?.droplog ?? 0,
  //   "droplog":parent?.droplog ?? 0,
  // }
  // let jsonString=JSON.stringify(formData);
  // localStorage.setItem("studentDetails",jsonString);
  }
  onDescChange()
  {
    if(this.medicalDesc && this.medicalDesc.trim() != "")
    {
      this.count=this.medicalDesc.length;
      if(this.count <=14)
      //this.promptEnable=false;
     // if(this.count <=14)
       this.minDescErr=true;
       else this.minDescErr=false;
    }
    else{
      this.count=0;
      this.minDescErr=false;
     // this.promptEnable=false;
      this.medicalDesc=this.medicalDesc?.trim() == "" ? "" : this.medicalDesc
    }
  }
  onDescChange2()
  {
    if(this.medicalDesc2 && this.medicalDesc2.trim() != "")
    {
      this.count2=this.medicalDesc2.length;
      if(this.count2 <=14)
      {
        this.promptEnable=false;
        this.minDescErr2=true;
      }
       else {this.minDescErr2=false;
        this.promptEnable=true;
       }
    }
    else{
      this.count2=0;
      this.minDescErr2=false;
      this.promptEnable=false;
      this.medicalDesc2=this.medicalDesc2?.trim() == "" ? "" : this.medicalDesc2
    }
  }
  selectedImgFile(event:any)
  {
     this.file= event.target.files[0];

    // localStorage.setItem('sprofileimg', JSON.stringify(this.file));

    if (this.file) {
      let splits=this.file.name.split(".");
     this.fileType=splits[1];
     if( this.fileType == "png" || this.fileType=="jpeg" || this.fileType=="jpg")
     {
      this.fileFormatErr=false;
      this.selectedFile = this.file;
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      this.fileService.setFile(this.file);
      reader.onload = (e: any) => {


        this.imageUrl = e.target.result;
      };
    }
    else {this.fileFormatErr=true;
      this.imageUrl =""}
  }
  }
  proceed()
  {
   this.promptEnable=true;
  }
  onChangeRadio()
  {
    this.medicalDesc="";
    this.minDescErr=false;
    this.medicalDesc2="";
    this.minDescErr2=false;
    this.medicalRadiobtn2='2'
    if(this.medicalRadiobtn =='2')
    {
      this.medicalRadiobtn2="";
    }
  }

  onChangeRadio2()
  {
    this.medicalDesc2="";
    this.minDescErr2=false;
    // if(this.medicalRadiobtn2 =='2')
    // {
    //   this.medicalPhotoUpload();
    // }
  }

  changeSchoolName()
  {
   let data= this.schoolList.find(x=>x.schoolID == this.studentForm.value.schoolId);
   if(data)
   localStorage.setItem("schoolName",data?.schoolName)
   localStorage.setItem('bsU_ID', data.bsU_ID);
   let schoolDetails ={
    city:data.city,
    bsu_city:data.citycode,
    provider:data.provider,
    country:data.country,
    currency:data?.bsU_CURRENCY
  }
  localStorage.setItem('schoolDetails', JSON.stringify(schoolDetails));
  }
  previous()
  {
    this.onTabChange.emit(0);
  }
  next()
  {
    localStorage.removeItem("studentDetails");
    let formattedDate:any;
    let serviceFormattedDate:any
    let data:any
    if(this.studentForm.controls.dob.value != null)
    {
    const stringDate = new Date(this.studentForm.controls.dob.value);
     formattedDate = this.datePipe.transform(stringDate, 'yyyy-MM-dd')
    }

     if(this.studentForm.controls.serviceDate.value != null)
     {
     const serviceParseDate=new Date(this.studentForm.controls.serviceDate.value);
     if(serviceParseDate)
     {
     serviceFormattedDate = this.datePipe.transform(serviceParseDate, 'yyyy-MM-dd');
     }
     }
     let storageData = JSON.parse(localStorage.getItem('parentDetails'))
     let bsuId= localStorage.getItem('bsU_ID')
     data = storageData.findIndex(x=>x.stU_SCHOOL_ID == this.studentForm.controls.studentId.value);
     let photopath=this.selectedFile?.name ?? this.imgSaveUrl
     localStorage.setItem('photopath',photopath)
     const imgname = this.imgSaveUrl?.substring(this.imgSaveUrl?.lastIndexOf('/') + 1);
     if(data != -1 && !this.isAddSibling){

         storageData[data].stU_ID =this.selectedStudent?.stU_ID?this.selectedStudent?.stU_ID:0,
         storageData[data].stU_FIRSTNAME= this.studentForm.controls.firstName.value,
         storageData[data].stU_MIDNAME=this.studentForm.controls.middleName.value,
         storageData[data].stU_LASTNAME=this.studentForm.controls.lastName.value,
         storageData[data].stU_GENDER=this.studentForm.controls.gender.value,
         storageData[data].stU_GRD_ID= this.studentForm.controls.grade.value,
         storageData[data].stU_GRM_ID= this.studentForm.controls.grade.value,
         storageData[data].stU_DOB= this.studentForm.controls.dob.value,
         storageData[data].stU_SCHOOL_ID= this.studentForm.controls.studentId.value,
         storageData[data].stU_PHOTOPATH= this.selectedFile?.name ?? imgname,
         storageData[data].stS_MED=Number(this.medicalRadiobtn)== 1 ? 1:0,
         storageData[data].stU_MED_CONDITION=this.medicalDesc,
         storageData[data].stS_MED_STAFF_REQUIRED=Number(this.medicalRadiobtn2)== 1 ? 1:0,
         storageData[data].stS_MED_STAFF_REQUIRED_DESC=this.medicalDesc2,
         storageData[data].busServiceRequestedDate= this.studentForm.controls.serviceDate.value
         storageData[data].stU_BSU_ID=bsuId
         let jsonString=JSON.stringify(storageData[data]);
         localStorage.setItem("studentDetails",jsonString);
         storageData=[storageData[data]];
     }
       else if(data == -1 && !this.isAddSibling && !this.isAddSiblingFromParent){
       storageData[0].stU_ID = 0,
       storageData[0].stU_FIRSTNAME= this.studentForm.controls.firstName.value,
       storageData[0].stU_MIDNAME=this.studentForm.controls.middleName.value,
       storageData[0].stU_LASTNAME=this.studentForm.controls.lastName.value,
       storageData[0].stU_GENDER=this.studentForm.controls.gender.value,
       storageData[0].stU_GRD_ID= this.studentForm.controls.grade.value,
       storageData[0].stU_GRM_ID= this.studentForm.controls.grade.value,
       storageData[0].stU_DOB= this.studentForm.controls.dob.value,
       storageData[0].stU_SCHOOL_ID= this.studentForm.controls.studentId.value,
       storageData[0].stU_PHOTOPATH=this.selectedFile?.name ?? imgname,
       storageData[0].stS_MED=Number(this.medicalRadiobtn)== 1 ? 1:0,
       storageData[0].stU_MED_CONDITION=this.medicalDesc,
       storageData[0].stS_MED_STAFF_REQUIRED=Number(this.medicalRadiobtn2)== 1 ? 1:0,
       storageData[0].stS_MED_STAFF_REQUIRED_DESC=this.medicalDesc2,
       storageData[0].busServiceRequestedDate= this.studentForm.controls.serviceDate.value
       storageData[0].stU_BSU_ID=bsuId

       let jsonString=JSON.stringify(storageData[0]);
         localStorage.setItem("studentDetails",jsonString);
         storageData=[storageData[0]];
       }
  else if(this.isAddSibling || this.isAddSiblingFromParent)
  {
    let index:any
    if(!this.hasLoginUser)
    {
      index= storageData?.length == 1 ? 0 : storageData.findIndex((x:any)=>x.disableRegistration == 0)
      index = index == -1? 0:index;
    }
    else{
      index= storageData?.length == 1 ? 0 : storageData.findIndex((x:any)=>x.transportStatus == "Service is already Active")
      index = index == -1? 0:index;
    }
    let parent = storageData[index];
    let formData={
      "stU_ID": 0,
      "stU_FIRSTNAME": this.studentForm.controls.firstName.value,
      "stU_MIDNAME": this.studentForm.controls.middleName.value,
      "stU_LASTNAME": this.studentForm.controls.lastName.value,
      "stU_GENDER": this.studentForm.controls.gender.value,
      "stU_GRD_ID": this.studentForm.controls.grade.value,
      "stU_GRM_ID": this.studentForm.controls.grade.value,
      "stU_DOB": formattedDate,
      "stU_SCHOOL_ID": this.studentForm.controls.studentId.value,
      "stU_PHOTOPATH": this.selectedFile?.name ?? imgname,
      "stS_MED":Number(this.medicalRadiobtn)== 1 ? 1:0,
      "stU_MED_CONDITION":this.medicalDesc,
      "stS_MED_STAFF_REQUIRED":Number(this.medicalRadiobtn2)== 1 ? 1:0,
      "stS_MED_STAFF_REQUIRED_DESC":this.medicalDesc2,
      "stU_PRIMARYCONTACT":parent?.stU_PRIMARYCONTACT ?? '',
      "stS_FFIRSTNAME": parent?.stS_FFIRSTNAME ?? '',
      "stS_FMIDNAME": parent?.stS_FMIDNAME ?? '',
      "stS_FLASTNAME": parent?.stS_FLASTNAME ?? '',
      "stS_FMOBILE": parent?.stS_FMOBILE ?? '',
      "stS_FEMAIL": parent?.stS_FEMAIL ?? '',
      "stS_MFIRSTNAME": parent?.stS_MFIRSTNAME ?? '',
      "stS_MMIDNAME": parent?.stS_MMIDNAME ?? '',
      "stS_MLASTNAME": parent?.stS_MLASTNAME ?? '',
      "stS_MMOBILE": parent?.stS_MMOBILE ?? '',
      "stS_MEMAIL": parent?.stS_MEMAIL ?? '',
      "stS_GFULLNAME":parent?.stS_GFULLNAME ?? '',
      "stS_GMOBILE": parent?.stS_GMOBILE ?? '',
      "stS_GEMAIL": parent?.stS_GEMAIL ?? '',
      "stU_SECONDARY_CONTACT":parent?.stU_SECONDARY_CONTACT ?? '',
      "disableRegistration": 0,
      "city": parent?.city ?? '',
    "house": parent?.house ?? '',
    "street": parent?.street ?? '',
    "transportType": parent?.transportType ?? '',
    "pickupArea": parent?.pickupArea ?? '',
    "pickupPoint": parent?.pickupPoint ?? '',
    "pickupPointLandMark": parent?.pickupPointLandMark ?? '',
    "dropoffArea": parent?.dropoffArea ?? '',
    "dropoffPoint": parent?.dropoffPoint ?? '',
    "dropoffPointLandMark": parent?.dropoffPointLandMark ?? '',
    "busServiceRequestedDate": serviceFormattedDate,
    "picklat":parent?.picklat ?? 0,
    "picklog":parent?.picklog ?? 0,
    "droplat":parent?.droplog ?? 0,
    "droplog":parent?.droplog ?? 0,
    "stU_BSU_ID":bsuId,
    "SBL_ID_DROPOFF":parent?.DROPOFFAREA ?? 0,
          "PNT_ID_DROPOFFPOINT":parent?.DROPOFFPOINT?? 0,
  }
  let jsonString=JSON.stringify(formData);
  localStorage.setItem("studentDetails",jsonString);
  //storageData.push(formData);
  storageData=[formData]
}
let studentGrade=this.gradeList.find(x=>x.id == this.studentForm.controls.grade.value)?.descr;
localStorage.setItem('studentGrade',studentGrade)
let jsonString=JSON.stringify(storageData);
    localStorage.setItem("parentDetails",jsonString);
    this.onTabChange.emit(2);
  }
  saveAsDraft()
  {
    this.studentForm.markAllAsTouched();
    if(!this.studentForm.invalid && this.imageUrl && this.imageUrl != '' && !this.minDescErr && !this.minDescErr2 && !this.fileFormatErr &&
    !(this.medicalRadiobtn2 == '1' && this.medicalDesc2 =='') && !(this.medicalRadiobtn == '1' && this.medicalDesc == ''))
    {
      this.imgErr=false;
      let storageData:any[]=[];
      let data: any;
      let formData:any;
      storageData = JSON.parse(localStorage.getItem('parentDetails'))
      let photopath=this.selectedFile?.name ?? this.imgSaveUrl
      localStorage.setItem('photopath',photopath)
      const imgname = this.imgSaveUrl?.substring(this.imgSaveUrl?.lastIndexOf('/') + 1);
      this.studentForm.controls['dob'].setValue(this.datePipe.transform(this.studentForm.controls['dob'].value, 'yyyy-MM-dd'));
      this.studentDetails={
        firstName:this.studentForm.controls.firstName.value,
        lastName:this.studentForm.controls.lastName.value,
        middleName:this.studentForm.controls.middleName.value,
        medicalDesc:this.medicalDesc,
        medicalRadiobtn:this.medicalRadiobtn,
        medicalRadiobtn2:this.medicalRadiobtn2,
        medicalDesc2:this.medicalDesc2,
        gender:this.studentForm.controls.gender.value,
        grade:this.studentForm.controls.grade.value,
        photopath:this.selectedFile?.name ?? imgname,
        dob:this.studentForm.controls.dob.value,
        schoolId:this.studentForm.controls.schoolId.value,
        studentId:this.studentForm.controls.studentId.value,
        academicYr:this.studentForm.controls.academicYr.value,
        serviceDate:this.serviceDate
      }

      data = storageData.findIndex(x=>x.stU_SCHOOL_ID == this.studentDetails.studentId);
      let bsuId= localStorage.getItem('bsU_ID')
      if(data != -1 && !this.isAddSibling){

          storageData[data].stU_ID = this.selectedStudent?.stU_ID?this.selectedStudent?.stU_ID:0,
          storageData[data].stU_FIRSTNAME= this.studentDetails.firstName,
          storageData[data].stU_MIDNAME=this.studentDetails.middleName,
          storageData[data].stU_LASTNAME=this.studentDetails.lastName,
          storageData[data].stU_GENDER=this.studentDetails.gender,
          storageData[data].stU_GRD_ID= this.studentDetails.grade,
          storageData[data].stU_GRM_ID= this.studentDetails.grade,
          storageData[data].stU_DOB= this.studentDetails.dob,
          storageData[data].stU_SCHOOL_ID= this.studentDetails.studentId,
          storageData[data].stU_PHOTOPATH= this.studentDetails.photopath,
          storageData[data].stS_MED=Number(this.studentDetails.medicalRadiobtn) == 1 ? 1:0,
          storageData[data].stU_MED_CONDITION=this.studentDetails.medicalDesc,
          storageData[data].stS_MED_STAFF_REQUIRED=Number(this.studentDetails.medicalRadiobtn2) == 1 ? 1:0,
          storageData[data].stS_MED_STAFF_REQUIRED_DESC=this.studentDetails.medicalDesc2,
          storageData[data].busServiceRequestedDate= this.studentDetails.serviceDate
           storageData[data].stU_BSU_ID=bsuId
          let jsonString=JSON.stringify(storageData[data]);
          localStorage.setItem("studentDetails",jsonString);
          storageData=[storageData[data]];
      }
        else if(data == -1 && !this.isAddSibling && !this.isAddSiblingFromParent){
        storageData[0].stU_ID = 0,
        storageData[0].stU_FIRSTNAME= this.studentDetails.firstName,
        storageData[0].stU_MIDNAME=this.studentDetails.middleName,
        storageData[0].stU_LASTNAME=this.studentDetails.lastName,
        storageData[0].stU_GENDER=this.studentDetails.gender,
        storageData[0].stU_GRD_ID= this.studentDetails.grade,
        storageData[0].stU_GRM_ID= this.studentDetails.grade,
        storageData[0].stU_DOB= this.studentDetails.dob,
        storageData[0].stU_SCHOOL_ID= this.studentDetails.studentId,
        storageData[0].stU_PHOTOPATH= this.studentDetails.photopath,
        storageData[0].stS_MED=Number(this.studentDetails.medicalRadiobtn)== 1 ? 1:0,
        storageData[0].stU_MED_CONDITION=this.studentDetails.medicalDesc,
        storageData[0].stS_MED_STAFF_REQUIRED=Number(this.studentDetails.medicalRadiobtn2)== 1 ? 1:0,
        storageData[0].stS_MED_STAFF_REQUIRED_DESC=this.studentDetails.medicalDesc2,
        storageData[0].busServiceRequestedDate= this.studentDetails.serviceDate
         storageData[0].stU_BSU_ID=bsuId
        let jsonString=JSON.stringify(storageData[0]);
          localStorage.setItem("studentDetails",jsonString);
          storageData=[storageData[0]];
        }
       else if(this.isAddSibling || this.isAddSiblingFromParent){
        let index:any
        if(!this.hasLoginUser)
        {
          index= storageData?.length == 1 ? 0 : storageData.findIndex((x:any)=>x.disableRegistration == 0)
          index = index == -1? 0:index;
        }
        else{
          index= storageData?.length == 1 ? 0 : storageData.findIndex((x:any)=>x.transportStatus == "Service is already Active")
          index = index == -1? 0:index;
        }
         let parent = storageData[index];
        let dupData = storageData.findIndex(x=>x.stU_SCHOOL_ID == this.studentDetails.studentId);
         if(dupData == -1)
         {
         formData={
          "stU_ID": 0,
          "stU_FIRSTNAME": this.studentDetails.firstName,
          "stU_MIDNAME": this.studentDetails.middleName,
          "stU_LASTNAME": this.studentDetails.lastName,
          "stU_GENDER": this.studentDetails.gender,
          "stU_GRD_ID": this.studentDetails.grade,
          "stU_GRM_ID": this.studentDetails.grade,
          "stU_DOB": this.studentDetails.dob,
          "stU_SCHOOL_ID": this.studentDetails.studentId,
          "stU_PHOTOPATH": this.studentDetails.photopath,
          "stS_MED":Number(this.studentDetails.medicalRadiobtn)== 1 ? 1:0,
          "stU_MED_CONDITION":this.studentDetails.medicalDesc,
          "stS_MED_STAFF_REQUIRED":Number(this.studentDetails.medicalRadiobtn2)== 1 ? 1:0,
          "stS_MED_STAFF_REQUIRED_DESC":this.studentDetails.medicalDesc2,
          "stU_PRIMARYCONTACT": parent?.stU_PRIMARYCONTACT ?? '',
          "stS_FFIRSTNAME": parent?.stS_FFIRSTNAME ?? '',
          "stS_FMIDNAME": parent?.stS_FMIDNAME ?? '',
          "stS_FLASTNAME": parent?.stS_FLASTNAME ?? '',
          "stS_FMOBILE": parent?.stS_FMOBILE ?? '',
          "stS_FEMAIL": parent?.stS_FEMAIL ?? '',
          "stS_MFIRSTNAME": parent?.stS_MFIRSTNAME ?? '',
          "stS_MMIDNAME": parent?.stS_MMIDNAME ?? '',
          "stS_MLASTNAME": parent?.stS_MLASTNAME ?? '',
          "stS_MMOBILE": parent?.stS_MMOBILE ?? '',
          "stS_MEMAIL": parent?.stS_MEMAIL ?? '',
          "stS_GFULLNAME":parent?.stS_GFULLNAME ?? '',
          "stS_GMOBILE": parent?.stS_GMOBILE ?? '',
          "stS_GEMAIL": parent?.stS_GEMAIL ?? '',
          "stU_SECONDARY_CONTACT":parent?.stU_SECONDARY_CONTACT ?? '',
          "disableRegistration": 0,
          "city": parent?.city ?? '',
        "house": parent?.house ?? '',
        "street": parent?.street ?? '',
        "transportType": parent?.transportType ?? '',
        "pickupArea": parent?.pickupArea ?? '',
        "pickupPoint": parent?.pickupPoint ?? '',
        "pickupPointLandMark": parent?.pickupPointLandMark ?? '',
        "dropoffArea": parent?.dropoffArea ?? '',
        "dropoffPoint": parent?.dropoffPoint ?? '',
        "dropoffPointLandMark": parent?.dropoffPointLandMark ?? '',
        "busServiceRequestedDate": this.studentDetails.serviceDate,
        "picklat":parent?.picklat ?? 0,
        "picklog":parent?.picklog ?? 0,
        "droplat":parent?.droplog ?? 0,
        "droplog":parent?.droplog ?? 0,
        "stU_BSU_ID":bsuId,
        "SBL_ID_DROPOFF":parent?.DROPOFFAREA ?? 0,
          "PNT_ID_DROPOFFPOINT":parent?.DROPOFFPOINT?? 0,
      }
      localStorage.removeItem("studentDetails");
      let jsonString=JSON.stringify(formData);
      localStorage.setItem("studentDetails",jsonString);
      //storageData.push(formData);
      storageData=[formData]
    }
    }
    let studentGrade=this.gradeList.find(x=>x.id == this.studentDetails.grade)?.descr;
    localStorage.setItem('studentGrade',studentGrade)
    let jsonString=JSON.stringify(storageData);
    localStorage.setItem("parentDetails",jsonString);
    let senddata :any={};
    let index:any
    if(!this.hasLoginUser)
    {
      index= storageData?.length == 1 ? 0 : storageData.findIndex((x:any)=>x.disableRegistration == 0)
      index = index == -1? 0:index;
    }
    else{
      index= storageData?.length == 1 ? 0 : storageData.findIndex((x:any)=>x.transportStatus == "Service is already Active")
      index = index == -1? 0:index;
    }
    senddata = {
      "MobileNumber":storageData[index]?.stU_PRIMARYCONTACT=="F"? storageData[index]?.stS_FMOBILE :storageData[index]?.stS_MMOBILE,
      "StuDetails": jsonString
        }
        this.disableStudents=this.studentDetails.studentId
        //localStorage.setItem("disableIds",this.disableStudents);
        if(senddata.MobileNumber!="" && senddata.MobileNumber!= null )
        {

      this.regService.studentSaveAsDraft(senddata,this.defaultToken,this.selectedFile).subscribe(res=>{
      if(res)
      {
        //this.resetForm();
        this.isAddSibling=false;
        this.openMessageDialog(this.langTrans?.requestSavedSuccessfully, false, "");
      }
      })
    }
    else this.openMessageDialog(this.langTrans?.pleaseProvideMobileNumber,true,"");
    }else {
      if(!this.imageUrl || this.imageUrl == '')
      this.imgErr=true;
    }
  }
  resetForm()
  {
  this.studentForm.reset();
  this. medicalRadiobtn="2";
  this.medicalDesc="";
  this. medicalRadiobtn2="2";
  this.medicalDesc2="";
  this.count=0;
  this.count2=0;
  this.infoCount=0;
  this.minDescErr=false;
  this.minDescErr2=false;
  this.imageUrl= "";
  this.imgErr=false;
  this.promptEnable=false;
  //this.SchNameErr=false;
  this.studentDetails=new studentDetail();
  }
  studentInfoDialog(template: TemplateRef<any>) {
   this.studentIdDialog= this.dialog.open(template, {
      disableClose: true,
      width: '550px',
      panelClass: 'custom-modalbox'
    });
  }
  search(value: string) {


    let filter = value.toLowerCase();
    return this.gradeList.filter((option) =>
      option.descr.toLowerCase() .includes(filter)
    );

  }
  onKey(eventTarget: any) {

    this.selectedgrades = this.search(eventTarget.value);

  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goBack()
  {
    this.studentDialog.close();
    this.router.navigate(['/home'])
  }
}
