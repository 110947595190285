export abstract class AppConfig {

  AppId: number;
  grantType: string;
  baseUrl: string;
  localapi: string;
  client: string;
  key: string;
  source: string;
  deviceId: string;
  applicationId: string;
  applicationSource: string;
  language: string;
  receiptHeaderImage: string;
  logoimageUrl: string;
  paymentTo: string;
  paymentOrigin: string;
  paymentredirectURL: string;
  portalTitle: string;
  portalShortCode: string;
  paymentReceptHeader: string;
  idleTimeout: number;
  timeoutPeriod: number;
  redirectionURL:string;
  appUrl:any;
  captchaAPIKey: string;
  otpTimerExpiry: number;
  forgotPasswordOTPExpiry: number;
  paymentreenrollOrigin:string;
  chatbotSetting:Chatbot;
  baseUIUrl:string;
  IDCardSourcePortal: string;
  uaepasscallbackurl:string;
  uaepass_source:string;
  uaepass_signIdentityId:string;
  uaepass_authorize_uri:string;
  uaepass_signoutUrl:string;
}

export class Chatbot{
  tokenUrl:string;
  apiUrl:string;
  key:string;
  name:string;
  secret:string;
  grantType:string;
  appName:string;
  channel:string;
  environmentCode:string;
}